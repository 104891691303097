<template>

    <v-form v-model="valid" ref="form" validate-on="input" @update:modelValue="$emit('changeValidity', $event)">
        <v-row>
            <v-col cols="4">
                <v-text-field label="AHV-Nr." variant="underlined"
                              v-model="formValues.ahvnr"
                              v-mask="'###.####.####.##'"
                              :hint="rules.ruleAhvNumber(formValues.ahvnr, true)"
                              :persistent-hint="true"></v-text-field>
            </v-col>
            <v-col cols="4">
                <v-text-field label="Versichertenkarte-Nr. (VEKA)" variant="underlined"
                              :hint="rules.ruleExactLength(formValues.krankenkassen_kartennummer,20, true, true, true)"
                              placeholder="8075…" :persistent-hint="true"
                              v-model="formValues.krankenkassen_kartennummer"></v-text-field>
            </v-col>
            <v-col cols="4" class="pt-6">
                <!--      <input type="date" v-model="formValues.ablaufdatum">-->
                <CustomDatePicker label="Versichertenkarte Ablaufdatum" :formValues="formValues"
                                  name="krankenkassen_ablaufdatum"></CustomDatePicker>
            </v-col>
            <v-col cols="4" class="pt-6">
                <!--    <input type="date" v-model="formValues.geburtsdatum">-->
                <CustomDatePicker label="Geburtsdatum" :formValues="formValues" name="geburtsdatum" type="birthday"
                                  :rules="[rules.ruleBefore(formValues.geburtsdatum, asMoment().format('YYYY-MM-DD'), 'Das Geburtsdatum muss in der Vergangenheit liegen')]"
                                  :max-date="asMoment().format('YYYY-MM-DD')"
                                  :start-date="defaultBirthdayStartDate"></CustomDatePicker>
            </v-col>
            <v-col cols="4">
                <v-text-field label="Versicherten-Nummer" variant="underlined"
                              v-model="formValues.krankenkassen_versichertennr"></v-text-field>
            </v-col>
            <v-col cols="4">
                <KrankenkasseSelectDialog :bewohner='formValues' :selected='formValues.krankenkassen_id'
                                          @choose="setKrankenkasse($event)">
                    <template #button="slotProps">
                        <v-autocomplete
                                label="Adresse Krankenkasse"
                                class="custom-disabled overflow-select"
                                v-model="formValues.krankenkassen_id"
                                :items="krankenkassen"
                                :readonly="true"
                                clearable
                                clear-icon="mdi-close"
                                append-icon=""
                                item-text="Versicherer_Name"
                                item-value="ID"
                                @click="slotProps.open()"
                                variant="underlined"
                        >
                            <template v-slot:selection="data">
                                {{ data.item.Versicherer_Name + ', ' + data.item.Ort }}
                            </template>
                            <template v-slot:append>
                                <img width="20" height="20" :src="require('@/assets/img/ico/suche.svg')" style="position:relative; top: 1px"
                                     class="cursor-pointer" @click="slotProps.open()">
                            </template>
                        </v-autocomplete>
                    </template>
                </KrankenkasseSelectDialog>
            </v-col>
            <v-col cols="4">
                <ArztSelectDialog :bewohner='formValues' :selected='formValues.arzt_id' @choose="setArzt($event)">
                    <template #button="slotProps">
                        <v-autocomplete
                                label="Zuweisender Arzt"
                                class="custom-disabled overflow-select"
                                v-model="formValues.arzt_id"
                                :items="aerzte"
                                :readonly="true"
                                clearable
                                clear-icon="mdi-close"
                                item-text="name"
                                item-value="ID"
                                variant="underlined"
                                @click="slotProps.open()"
                        >
                            <template v-slot:selection="data">
                                {{ data.item.name + ', ' + data.item.ort }}
                            </template>
                            <template v-slot:append>
                                <img width="20" height="20" :src="require('@/assets/img/ico/suche.svg')" style="position:relative; top: 1px"
                                     class="cursor-pointer" @click="slotProps.open()">
                            </template>
                        </v-autocomplete>
                    </template>
                </ArztSelectDialog>
                <!--      <KrankenkasseDialog>-->
                <!--        <template #button="slotProps">-->
                <!--          <p class="open-dialog-btn text-primary mt-2" @click="slotProps.open()">Neue Krankenkasse erfassen</p>-->
                <!--        </template>-->
                <!--      </KrankenkasseDialog>-->
            </v-col>
        </v-row>
    </v-form>
</template>
<script>
import {globalRules} from "@/composables/rules";
import moment from "moment"
import ArztSelectDialog from "../dialogs/ArztSelectDialog";
import KrankenkasseSelectDialog from "../dialogs/KrankenkasseSelectDialog";
import CustomDatePicker from "@/components/form/CustomDatePicker.vue";
import {aerzteGetters} from "@/components/observables/aerzte";
import {krankenkassenGetters} from "@/components/observables/krankenkassen";

export default {
    name: "Krankenkasse",
    components: {ArztSelectDialog, KrankenkasseSelectDialog, CustomDatePicker},
    props: {
        formValues: {
            required: true
        },
    },
    data() {
        return {
            valid: false,
            form: null,
            rules: globalRules,
            defaultBirthdayStartDate: moment().month(1).day(1).subtract(moment().year() % 10, 'year').subtract(80, 'year'),
        }
    },
    computed: {
        ...aerzteGetters,
        ...krankenkassenGetters,
    },
    methods: {
        setKrankenkasse(krankenkassenID) {
            this.formValues.krankenkassen_id = krankenkassenID;
        },

        setArzt(arztId) {
            this.formValues.arzt_id = arztId;
        },

        asMoment(date = null, locale = 'de') {
            return date ? moment(date).locale(locale) : moment().locale(locale);
        }
    },
    mounted() {
        // this.$refs.form.validate();
    }
}
</script>

<style scoped lang="scss">
.additional-actions {
  position: absolute;
  bottom: 31px;
  left: 50px;
  font-family: "Avenir LT Std Heavy";
  font-size: 16px;
}

.open-dialog-btn {
  text-decoration: underline;
  cursor: pointer;
  font-family: "Avenir LT Std Heavy";
  font-size: 16px;
}

::v-deep {
  .custom-disabled {
    .v-field--disabled {
      opacity: 1;
      cursor: pointer !important;
    }

    .v-input__details {
      opacity: 1;
    }

    .v-field__append-inner {
      display: none;
    }
  }
}
</style>

