export const bilder = {
  namespaced: true,
  state: {
    produktbilder: []
  },
  actions: {
    setProduktBilder({ commit },value) {
      commit("setProduktBilder",value);
    }
  },
  mutations: {
    setProduktBilder(state,val){
      state.produktbilder = val;
    }
  }
};