import moment from 'moment/min/moment-with-locales'
import {bewohnerGetters} from "@/components/observables/bewohner";
import {inkoGradGetters} from "@/components/observables/inkograd";

export const useBewohnerHelper = {
  bewohnerGetters: {
    ...bewohnerGetters
  },
  getCurrentInkogradFromHistory: (bewohnerId) => {
    if (bewohnerId) {
      let history = bewohnerGetters.bewohner().find(b => b.bewohnerID === +bewohnerId).inkogradHistory;
      if (history && history.length > 0) {
        const inkograds = history.filter(inkograd => {
          return moment(inkograd.start).isBefore(moment())
        })
        const inkograd = inkograds[inkograds.length - 1];
        return inkograd ? inkograd.inkograd : null;
      }
    }
    return null;
  },
  getCurrentInkogradFromInkogradStore: (bewohnerId) => {
    if (bewohnerId == inkoGradGetters.bewohnerId()) {
      let history = inkoGradGetters.bewohnerInkoGradHistory();
      if (history && history.length > 0) {
        const inkograds = history.filter(inkograd => {
          return moment(inkograd.start).isBefore(moment())
        })
        const inkograd = inkograds[inkograds.length - 1];
        return inkograd ? inkograd.inkograd : null;
      }
    }
    return null;
  },
  hasTotalInkogradStore: (bewohnerId) => {
    if (bewohnerId == inkoGradGetters.bewohnerId()) {
      let history = inkoGradGetters.bewohnerInkoGradHistory();
      if (history && history.length > 0) {
        const inkograds = history.filter(inkograd => {
          return moment(inkograd.start).isBefore(moment())
        })
        const inkograd = inkograds[inkograds.length - 1];
        if(inkograd && inkograd.inkograd == 5) {
          return true;
        }
        else {
          const inkograds = history.find(inkograd => {
            return moment(inkograd.start).isAfter(moment()) && inkograd.inkograd == 5;
          })
          return inkograds ? true : false
        }
      }
    }
    return false;
  },
  getNextTotalInkogradFromStore: (bewohnerId) => {
    if (bewohnerId == inkoGradGetters.bewohnerId()) {
      let history = inkoGradGetters.bewohnerInkoGradHistory();
      if (history && history.length > 0) {
        const inkograds = history.filter(inkograd => {
          return moment(inkograd.start).isBefore(moment())
        })
        const inkograd = inkograds[inkograds.length - 1];
        if(inkograd && inkograd == 5) {
          return inkograd;
        }
        else {
          const inkograd = history.find(inkograd => {
            return moment(inkograd.start).isAfter(moment()) && inkograd.inkograd == 5;
          })
          return inkograd ? inkograd : null
        }
      }
    }
    return null;
  },
}
