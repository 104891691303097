<template>
  <v-progress-circular
      v-else
      color="primary"
      indeterminate
  />
</template>
<script>
import httpClient from '@/utils/httpClient'

export default {
  name: 'AssetDownloader',
  emits: ['done'],
  props: {
    asset: {
      type: Object,
      required: true
    }
  },

  created () {
    return httpClient.get(`${process.env.VUE_APP_API_SHARED}asset/${this.asset.id}`, {
      responseType: 'arraybuffer',
    })
        .then((response) => {
          var filename = this.asset.id + '.' + this.asset.ext;

          const blob = new Blob([response], {type: this.asset.mime_type});
          if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, filename);
          } else {
            const link = document.createElement("a");
            if (link.download !== undefined) {
              const url = URL.createObjectURL(blob);
              link.setAttribute("href", url);
              link.setAttribute("download", filename);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        })
        .finally(() => {
          this.$emit('done')
        })
  }
}
</script>