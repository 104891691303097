<template>
  <svg width="100%" height="100%" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-miterlimit:10;">
    <g>
        <circle cx="8" cy="8" r="8" fill="currentColor" />
    </g>
    <g>
      <path id="Pfad_1079" d="M9.63,3.75C10.35,3.03 11.53,3.03 12.25,3.75C12.97,4.47 12.97,5.65 12.25,6.37L6.36,12.26C5.64,12.98 4.46,12.98 3.74,12.26C3.02,11.54 3.02,10.36 3.74,9.64L9.63,3.75Z" style="fill:none;fill-rule:nonzero;stroke:white;stroke-width:0.43px;"/>
      <path id="Linie_199" d="M11.31,7.31L8.69,4.69" style="fill:none;fill-rule:nonzero;stroke:white;stroke-width:0.43px;"/>
      <path id="Linie_200" d="M8.69,4.69L11.31,7.31" style="fill:none;fill-rule:nonzero;stroke:white;stroke-width:0.43px;"/>
      <path id="Linie_201" d="M7.52,11.1L4.9,8.48" style="fill:none;fill-rule:nonzero;stroke:white;stroke-width:0.43px;"/>
      <path id="Linie_202" d="M4.9,8.48L7.52,11.1" style="fill:none;fill-rule:nonzero;stroke:white;stroke-width:0.43px;"/>
      <path id="Pfad_1080" d="M7.96,6.26C8.1,6.12 8.33,6.12 8.48,6.26C8.62,6.4 8.62,6.63 8.48,6.78C8.34,6.93 8.11,6.92 7.96,6.78C7.81,6.64 7.81,6.4 7.96,6.26Z" style="fill:white;fill-rule:nonzero;"/>
      <path id="Pfad_1081" d="M9.23,7.54C9.37,7.4 9.6,7.4 9.75,7.54C9.89,7.68 9.89,7.91 9.75,8.06C9.6,8.2 9.37,8.2 9.23,8.05C9.09,7.91 9.09,7.68 9.23,7.54Z" style="fill:white;fill-rule:nonzero;"/>
      <path id="Pfad_1082" d="M7.84,7.66C7.98,7.52 8.21,7.52 8.36,7.66C8.5,7.8 8.5,8.03 8.36,8.18C8.22,8.32 7.99,8.32 7.84,8.18C7.7,8.03 7.7,7.8 7.84,7.66Z" style="fill:white;fill-rule:nonzero;"/>
      <path id="Pfad_1083" d="M6.96,8.29C6.82,8.43 6.59,8.43 6.44,8.29C6.3,8.15 6.3,7.92 6.44,7.77C6.58,7.63 6.81,7.63 6.96,7.77C7.11,7.91 7.11,8.14 6.96,8.29Z" style="fill:white;fill-rule:nonzero;"/>
      <path id="Pfad_1084" d="M7.72,9.04C7.86,8.9 8.09,8.9 8.24,9.04C8.38,9.18 8.38,9.41 8.24,9.56C8.1,9.71 7.87,9.7 7.72,9.56C7.58,9.42 7.58,9.19 7.72,9.04Z" style="fill:white;fill-rule:nonzero;"/>
    </g>
</svg>
</template>

<script>
export default {
  name: "LogoWunde"
}
</script>

<style scoped>
svg {
  color: #D13840FF;
}
</style>