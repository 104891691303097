<template>
  <div style="position:relative" id="export-modal">
    <div class="op-hover" style="position:absolute;right: 10px; top: 10px;z-index:999">
      <v-icon x-large color="primary" @click="clearCheckInterval();search='';$emit('closeExportModal')">
        mdi-close
      </v-icon>
    </div>
    <v-card flat class="px-4 pb-16">
      <v-card-title class="py-8 pb-0 d-flex">
        <div>
          Export-Vorschau - {{  moment(this.month).locale('de').format('MMMM YYYY') }}
          <v-chip
              v-if="errorCount > 0"
              color="red"
              dark
              class="ml-3"
              small
          >
            {{ errorCount }} Erfassungs-Fehler
          </v-chip>
        </div>
        <div>
          <span v-if="errorCount > 0">
                <v-switch style="margin-top: 1px" :ripple="false" dense x-small light flat label="nur Erfassungs-Fehler" @change="applyErrorFilter" v-model="onlyErrors" class="ml-8">
                </v-switch>
            </span>
        </div>
        <div>
          <v-chip
              v-if="submitFailCount > 0"
              color="red"
              dark
              class="ml-3"
              small
          >
            {{ submitFailCount }} Übermittlungs-Fehler
          </v-chip>
        </div>
        <div>
          <span v-if="submitFailCount > 0">
                <v-switch style="margin-top: 1px" :ripple="false" dense x-small light flat label="nur Übermittlungs-Fehler" @change="applySubmitFailFilter" v-model="onlySubmitFails" class="ml-8">
                </v-switch>
            </span>
        </div>

        <div class="pl-10" v-if="$store.state.benutzer.settings.erp_sync === 'active'">
          <v-avatar
              :color="erpConnectionStatus == '0' ? 'primary' : 'red'"
              size="12"
          ></v-avatar>
          <span class="caption pl-2" v-if="erpConnectionStatus == '0'">
            mit {{ $store.state.benutzer.settings.erp.toUpperCase() }} verbunden
          </span>
          <span class="caption pl-2" v-if="erpConnectionStatus != '0'">
             keine Verbindung zu {{ $store.state.benutzer.settings.erp.toUpperCase() }} ({{ erpConnectionMessage }})
          </span>
        </div>
      </v-card-title>
      <v-card-text class="body-1">
        <v-data-table
            v-if="dataTable.length > 0"
            :items="dataTable"
            :headers="headers"
            :items-per-page="10"
            :search="search"
            :footer-props="{
              'items-per-page-text': 'Einträge pro Seite',
              'items-per-page-all-text': 'Alle',
              'items-per-page-options': [10,20,30,50,-1]
            }"
        >
          <template v-slot:top>
            <v-row>
              <v-col cols="4">
                <v-text-field
                    v-model="search"
                    label="Suche "
                    clearable
                    style="max-width: 400px;"
                    class="mx-4"
                ></v-text-field>
              </v-col>
              <v-col cols="2" v-if="$store.state.benutzer.settings.erp_sync === 'active'">
                <div class="caption mt-6">
                  {{ erpSyncedCount }} von {{ dataTable.length }} (davon {{ erpErrorCount }} mit Fehlern)<br> Einträgen
                  in {{ $store.state.benutzer.settings.erp.toUpperCase() }} übertragen
                </div>
              </v-col>
              <v-col cols="5" v-if="$store.state.benutzer.settings.erp_sync === 'active'">
                <v-progress-linear height="7" class="mt-9" :value="erpSyncedCount/dataTable.length * 100"></v-progress-linear>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.Leistungstext="{ item }">
            {{ item.Leistungstext }}
            <v-tooltip v-if="item.Nachricht" top class="body-2 py-1 px-2 ">
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }" open-delay="0">
                  <v-icon color="'orange'" v-bind="attrs" size="22" v-on="on" style="margin-top: -2px;">
                    mdi-information-outline
                  </v-icon>
                </v-hover>
              </template>
              <div>
                <span style="font-size: 13px;">{{ item.Nachricht.titel }}<br>{{ item.Nachricht.text }}</span>
              </div>
            </v-tooltip>
          </template>

          <template v-slot:item.BewohnerID="{ item }">
            <v-chip
                v-if="!item.BewohnerID || item.BewohnerID == ''"
                color="red"
                dark
                small
            >
              fehlt
            </v-chip>
            <span v-else>
              {{ item.BewohnerID }}
            </span>
          </template>
          <template v-slot:item.LeistungsNummer="{ item }">
            <div v-if="!item.LeistungsNummer || item.LeistungsNummer == ''">
              <div>
                <v-btn
                    color="red"
                    outlined
                    @click="clickedEdit = item; addProductInfoModal = true"
                    depressed
                    class="mt-2 mb-2"
                    small
                >
                    Nr Fehlt
                  <v-icon small right>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </div>
            <div v-else-if="availableLeistungsnummern.indexOf(item.LeistungsNummer) == -1 && inactiveLeistungsnummern.indexOf(item.LeistungsNummer) == -1 && $store.state.benutzer.settings.erp === 'nexus'">
                <v-btn
                    color="red"
                    outlined
                    @click="clickedEdit = item; addProductInfoModal = true"
                    depressed
                    class="mt-2 mb-2"
                    small
                >
                  {{ item.LeistungsNummer }} {{ item.Zusatztext }} in NEXUS nicht vorhanden
                  <v-icon small right>mdi-pencil</v-icon>
                </v-btn>
            </div>
            <div v-else-if="inactiveLeistungsnummern.indexOf(item.LeistungsNummer) != -1 && $store.state.benutzer.settings.erp === 'nexus'">
              <v-btn
                  color="red"
                  outlined
                  @click="clickedEdit = item; addProductInfoModal = true"
                  depressed
                  class="mt-2 mb-2"
                  small
              >
                {{ item.LeistungsNummer }} in NEXUS inaktiv
                <v-icon small right>mdi-pencil</v-icon>
              </v-btn>
            </div>
            <span v-else>
                <v-btn
                    color="transparent"
                    @click="clickedEdit = item; addProductInfoModal = true"
                    depressed
                    class="mt-2 mb-2"
                    small
                >
                  {{ item.LeistungsNummer }}
                  <v-icon
                      color="grey"
                      small
                      right
                  >mdi-pencil</v-icon>
                </v-btn>
            </span>
          </template>
          <template v-slot:item.status="{ item }">
            <v-icon color="primary" v-if="item.status == '0'">
              mdi-check-circle-outline
            </v-icon>
            <v-icon color="red" v-if="item.status < 0">
              mdi-alert-circle-outline
            </v-icon>
          </template>
          <template v-slot:item.superadmin="{ item }">
            <v-btn x-small :disabled="firstCheck" color="primary" @click="deleteBenefit(item.recordID)" dense depressed v-if="$store.state.benutzer.info.superadmin === 1 && item.status === '0'">
              Status löschen
            </v-btn>
          </template>
          <template v-slot:item.accountant="{ item }">
            <v-btn x-small :disabled="firstCheck" color="primary" @click="deleteBenefit(item.recordID)" dense depressed v-if="$store.state.benutzer.info.accountant != undefined && $store.state.benutzer.info.accountant === 1 && $store.state.benutzer.info.superadmin != 1 && item.status === '0'">
              Status löschen
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions style="display: block">
        <v-spacer></v-spacer>
        <v-row>
          <v-col cols="5">
            <div v-if="$store.state.benutzer.settings.erp === 'nexus'" style="font-size: 13px;">
              *Preis: Betrag pro Abrechnungseinheit inkl. MwSt
              <span v-if="$store.state.benutzer.settings.zuschlag && $store.state.benutzer.settings.zuschlag > 0">und {{
                  $store.state.benutzer.settings.zuschlag
                }}% Admin-Zuschlag</span>
            </div>
            <div v-if="$store.state.benutzer.settings.erp === 'lobos'" style="font-size: 13px;">
              *Preis: Betrag pro Abrechnungseinheit inkl MwSt - Allfälliger Admin-Zuschlag wird im LOBOS berechnet
            </div>
            <div class="pl-6" v-if="(erpSyncedCount === dataTable.length)">
              <v-icon color="primary">
                mdi-check-circle-outline
              </v-icon>
              <span class="pl-8">
                In dieser Ansicht sind alle Daten übermittelt worden
              </span>
            </div>
            <div class="pl-6" v-if="(!allDone && isRunningErpSync && !firstCheck && run)">
              <v-progress-circular
                  indeterminate
                  color="primary"
              ></v-progress-circular>
              <span class="pl-8">
                Daten werden übertragen...
              </span>
            </div>
          </v-col>
          <v-col cols="7">
            <div style="float:right;">
              <v-btn depressed class="mr-4" @click="clearCheckInterval();search='';$emit('closeExportModal')">Fenster
                schliessen
              </v-btn>
              <v-btn :disabled="errorCount > 0" v-if="$store.state.benutzer.settings.erp_sync !== 'active' && $store.state.benutzer.settings.erp !== 'abacus'" class="primary mr-4" depressed @click="$emit('downloadERP')">
                {{ (report.length - 1) }} Daten als CSV exportieren
              </v-btn>
              <v-btn v-if="$store.state.benutzer.settings.erp_sync === 'active'" class="primary" :disabled="(erpConnectionStatus != '0') || allSuccessfulDone || firstCheck || isRunningErpSync || errorCount > 0" depressed @click="isRunningErpSync = true;$emit('transferERP')">
                ({{ (dataTable.length - erpSyncedCount) }}) Daten an {{
                  $store.state.benutzer.settings.erp.toUpperCase()
                }} übermitteln
              </v-btn>
              <v-btn @click="$emit('generateXMLForERP')" depressed class="primary" v-if="$store.state.benutzer.settings.erp === 'abacus'">
                XML-Datei erstellen
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="addProductInfoModal" persistent max-width="490">
      <product-lp-type-hint
          :key="'hint'+clickedEdit.LeistungsNummer+'_'+clickedEdit.ArtikelNrLAG"
          :curProdNameProp="clickedEdit.Leistungstext"
          :curProdId="clickedEdit.ArtikelNrLAG"
          :ownProdNoProp="clickedEdit.LeistungsNummer"
          @addProductInfo="addProductInfo"
          :showRemove="false"
          @abort="addProductInfoModal = false"
      />
    </v-dialog>
  </div>
</template>
<script>

import moment from 'moment'
import httpClient from "../../utils/httpClient";
import ProductLpTypeHint from "../products/ProductLpTypeHint";

export default {

  components: {
    ProductLpTypeHint
  },

  name: 'ExportPreview',

  props: ['report', 'bewohner', 'month', 'run', 'availableLeistungsnummern', 'inactiveLeistungsnummern'],

  data() {
    return {
      checkInterval: false,
      clickedEdit: {
        'LeistungsNummer': '',
        'Leistungstext': '',
        'ArtikelNrLAG': '',
      },
      benefitStatus: [],
      isRunningErpSync: true,
      allDone: false,
      allSuccessfulDone: false,
      firstCheck: true,
      onlyErrors: false,
      onlySubmitFails: false,
      addProductInfoModal: false,
      erpConnectionStatus: 0,
      erpConnectionMessage: '',
      dataTable: [],
      moment: moment,
      search: '',
      headers: [
        {text: 'BewohnerID', value: 'BewohnerID'},
        {text: 'Vorname', value: 'vorname'},
        {text: 'Name', value: 'name'},
        {text: 'LeistungsNummer', value: 'LeistungsNummer'},
        {text: 'Leistungstext', value: 'Leistungstext'},
        {text: 'Von', value: 'ErbringungsZeitraumDatumVon'},
        {text: 'Bis', value: 'ErbringungsZeitraumDatumBis'},
        {text: 'Menge', value: 'Menge'},
        {text: 'Einheit', value: 'Mengeneinheit'},
        {text: 'Preis*', value: 'Preis'},
        {text: 'MiGel-Code', value: 'MiGel-Code'},
        {text: 'GTIN', value: 'GTIN'},
        {text: 'Pharma-Code', value: 'Pharma-Code'}
      ]
    }
  },

  methods: {

    async addProductInfo(optionsObj) {
      this.addProductInfoModal = false;

      const res = await httpClient({
        url: process.env.VUE_APP_API_SHARED + 'save/prodinfo',
        method: 'post',
        data: {artnr: optionsObj.objectID, key: 'ownartno', value: optionsObj.ownProdNo}
      })

      this.$emit('exportERP');

      if (res.type == 'success') {
        this.addProductInfoModal = false;
      }
    },

    clearCheckInterval() {
      clearInterval(this.checkInterval);
    },


    applyErrorFilter() {
      this.reportTableEntries();
    },

    applySubmitFailFilter(){
      this.reportTableEntries();
    },

    bewohnerName(externalID) {

      var bew = this.bewohner.filter(function (item) {
        return item.externalID == externalID
      })

      if (bew.length > 0) {
        return {
          'name': bew[0].name,
          'vorname': bew[0].vorname,
        }
      } else {
        return {
          'name': '',
          'vorname': '',
        }
      }
    },

    async deleteBenefit(id) {

      this.allSuccessfulDone = false;

      const response = await httpClient({
        url: process.env.VUE_APP_API_CHECKLIST + 'erp/delete-benefit',
        method: 'post',
        data: {
          id: id
        }
      });
    },

    getErrorMsg(status) {
      if (status == '') {
        return ''
      } else if (status == '0') {
        return 'übermittelt'
      } else if (status == '-1') {
        return 'allgemeiner Fehler'
      } else if (status == '-2') {
        return 'Benutzer nicht gültig';
      } else if (status == '-3') {
        return 'Ungültiger Account / CostCenter';
      } else if (status == '-4') {
        return 'Ungültiger Bewohner';
      } else if (status == '-5') {
        return 'Bewohner bereits archiviert';
      } else if (status == '-6') {
        return 'Eintrag kann direkt gebucht werden';
      } else if (status == '-7') {
        return 'Katalogeintrag inaktiv';
      } else if (status == '-8') {
        return 'Kataloteintrag nicht gefunden';
      } else if (status == '-9') {
        return 'Katalogeintrag nicht abgeschlossen';
      } else if (status == '-10') {
        return 'Katalogeintrag kann nicht gelöscht werden';
      } else if (status == '-11') {
        return 'Katalogeintrag gehört nicht dem Mandant';
      } else {
        return 'Fehler';
      }

    },

    reportTableEntries() {

      //Erste Zeile entfernen
      var rep = JSON.parse(JSON.stringify(this.report));
      const keys = rep.shift();

      var self = this;

      var tdata = rep.map(function (item) {

        const data = Object.assign.apply({}, keys.map( (v, i) => ( {[v]: item[i]} ) ) );

        var bew = self.bewohnerName(data.BewohnerID)

        var status = self.benefitStatus[data.LeistungsNummer + '_' + data.BewohnerID] != undefined ? self.benefitStatus[data.LeistungsNummer + '_' + data.BewohnerID].status : '';
        var recordID = self.benefitStatus[data.LeistungsNummer + '_' + data.BewohnerID] != undefined ? self.benefitStatus[data.LeistungsNummer + '_' + data.BewohnerID].id : -1;

        data.recordID = recordID
        data.name = bew.name
        data.vorname = bew.vorname
        data.status = status
        data.message = self.getErrorMsg(status)

        return data
      });

      if (this.onlyErrors) {

        var self = this;

        tdata = tdata.filter(function (item) {
          return ((!item.BewohnerID || item.BewohnerID == '') ||
              (((!item.LeistungsNummer || item.LeistungsNummer == '')) &&  self.$store.state.benutzer.settings.erp !== 'abacus') ||
              (
                  self.$store.state.benutzer.settings.erp === 'nexus' &&
                  (item.LeistungsNummer && item.LeistungsNummer != '') &&
                  (self.availableLeistungsnummern.indexOf(item.LeistungsNummer) == -1 && self.inactiveLeistungsnummern.indexOf(item.LeistungsNummer) == -1)
              ) ||
              (
                  self.$store.state.benutzer.settings.erp === 'nexus' &&
                  (item.LeistungsNummer && item.LeistungsNummer != '') &&
                  (self.inactiveLeistungsnummern.indexOf(item.LeistungsNummer) != -1)
              ))
        })
      }


      if (this.onlySubmitFails) {

        tdata = tdata.filter(function (item) {
          return item.status != '' && item.status < 0;
        });
      }

      this.dataTable = tdata
    },
  },

  computed: {

    submitFailCount(){

      return  this.dataTable.filter(function (item) {
        return item.status != '' && item.status < 0;
      }).length;

    },

    errorCount() {

      var self = this;

      let missingBewohnerID = this.dataTable.filter(function (item) {
        return !item.BewohnerID || item.BewohnerID == ''
      }).length;

      let missingLP = this.dataTable.filter(function (item) {
        return !item.LeistungsNummer || item.LeistungsNummer == ''
      }).length;

      let wrongLP = this.dataTable.filter(function (item) {
        return ((item.LeistungsNummer && item.LeistungsNummer != '') && self.$store.state.benutzer.settings.erp === 'nexus' &&
            (self.availableLeistungsnummern.indexOf(item.LeistungsNummer) == -1 && self.inactiveLeistungsnummern.indexOf(item.LeistungsNummer) == -1))
      }).length;

      let inactiveLP = this.dataTable.filter(function (item) {
        return ((item.LeistungsNummer && item.LeistungsNummer !== '') && self.$store.state.benutzer.settings.erp === 'nexus' &&
            (self.inactiveLeistungsnummern.indexOf(item.LeistungsNummer) !== -1))
      }).length;

      if(self.$store.state.benutzer.settings.erp === 'abacus'){
        wrongLP = 0;
        inactiveLP = 0;
        missingLP = 0;
      }


      return missingLP + missingBewohnerID + inactiveLP + wrongLP;
    },


    erpSyncedCount() {

      return this.dataTable.filter(function (item) {
        return item.status === '0'
      }).length;

    },

    erpErrorCount() {
      return this.dataTable.filter(function (item) {
        return item.status < 0
      }).length;
    }

  },


  async mounted() {

    if (this.$store.state.benutzer.settings.erp_sync === 'active') {
      this.headers.unshift(
          {text: this.$store.state.benutzer.settings.erp.toUpperCase() + '-Status', value: 'status'},
          {text: this.$store.state.benutzer.settings.erp.toUpperCase() + '-Mitteilung', value: 'message'},
      )
    }

    if (this.$store.state.benutzer.info.superadmin === 1 && this.$store.state.benutzer.settings.erp === 'nexus') {
      this.headers.splice(5, 0,
          {text: 'Heim-Admin', value: 'superadmin'},
      )
    }

    if (this.$store.state.benutzer.info.accountant != undefined && this.$store.state.benutzer.info.accountant === 1 && this.$store.state.benutzer.info.superadmin != 1 && this.$store.state.benutzer.settings.erp === 'nexus') {
      this.headers.splice(5, 0,
          {text: 'Heim-Admin', value: 'accountant'},
      )
    }

    if(this.$store.state.benutzer.settings.erp === 'abacus'){
      this.headers.splice(3, 1);
    }

    this.reportTableEntries();

    if (this.$store.state.benutzer.settings.erp_sync === 'active') {


      //check ERP Connection
      const connResponse = await httpClient({
        url: process.env.VUE_APP_API_CHECKLIST + 'erp/check-connection',
        method: 'get',
      });

      this.erpConnectionStatus = connResponse.m_ErrorCode;
      this.erpConnectionMessage = connResponse.m_ErrorMsg;

      //check Interval
      this.checkInterval = setInterval(async () => {

        const response = await httpClient({
          url: process.env.VUE_APP_API_CHECKLIST + 'erp/check-monthly-benefits',
          method: 'post',
          data: {
            month: moment(this.month).startOf('month').format('YYYY-MM-DD'),
          }
        });

        if (response.type == 'success') {
          this.benefitStatus = response.benefits;
          this.reportTableEntries();
          if (this.firstCheck) {
            this.isRunningErpSync = false;
            this.firstCheck = false;
          }

          if (this.erpSyncedCount + this.erpErrorCount === this.dataTable.length) {
            this.allDone = true;
            this.isRunningErpSync = false;
          }

          if (this.erpSyncedCount === this.dataTable.length) {
            this.allSuccessfulDone = true;
          }
        }

      }, 2500);
    }
  },

  beforeDestroy() {
    clearInterval(this.checkInterval);
  }

}
</script>
<style lang="scss">
#export-modal {
  .v-data-table-header th {
    white-space: nowrap;
  }

  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
  }
}

</style>