<template>
  <v-menu
      ref="menu"
      v-model="show"
      :close-on-content-click="false"
      transition="scale-transition"
      content-class="compact-calendar-menu"
      offset-y
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-model="formatedDate"
          :label="label"
          readonly
          v-bind="attrs"
          v-on="on"
      />
    </template>

    <v-date-picker
        v-model="date"
        :active-picker.sync="activePicker"
        :max="max"
        :min="min"
        first-day-of-week="1"
        locale="ch-de"
        flat
        no-title
        width="270"
        :show-week="false"
        color="primary"
        :show-current="true"
        @input="pickDate"
    />
  </v-menu>
</template>

<script>
export default {
  name: "FieldDatePicker",

  props: {
    value: {
      default: null
    },
    label: {
      type: String,
      required: true
    },
    max: {
      type: String,
      default: null
    },
    min: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      show: false,
      activePicker: null,
      date: this.$moment(this.value).isValid()? this.value: null
    }
  },

  computed: {
    formatedDate() {
      if(this.date) {
        const [year, month, day] = this.date.split('-')
        return `${day}.${month}.${year}`
      }

      return null
    }
  },

  methods: {
    pickDate() {
      this.show = false
      this.$emit('input', this.date)
    }
  }
}
</script>