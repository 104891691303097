import Vue from 'vue'
import httpClient from "@/utils/httpClient";

const krankenkassenState = Vue.observable({
    loadingKrankenkassen: false,
    krankenkassen: []
});


export const krankenkassenGetters = {
    krankenkassen: () => {
        return krankenkassenState.krankenkassen
    },
    byBewohnerId: (state) => (id) => krankenkassenState.krankenkassen?.find(an => an.id === +id),
}

export const krankenkassenMutations = {
    setKrankenkassen(krankenkassen) {
        krankenkassenState.krankenkassen = krankenkassen;
    }
}

export const krankenkassenActions = {
    fetchKrankenkassen(bewohnerId) {
        if (!this.loadingKrankenkassen) {
            this.loadingKrankenkassen = true
            httpClient({
                url: process.env.VUE_APP_API_SHARED + 'get/krankenkassen',
                method: 'get',
            })
                .then(response => {
                    if (response.type === "success") {
                        krankenkassenMutations.setKrankenkassen(response.krankenkassen);
                    }
                })
                .finally(() => {
                    this.loadingKrankenkassen = false
                })
        }
    }
}

export default {
    krankenkassenState: krankenkassenState,
    krankenkassenGetters,
    krankenkassenMutations,
    krankenkassenActions
}
