import httpClient
    from "../../utils/httpClient"
import moment
    from "moment";

export const direktabrechnung = {
    namespaced: true,


    state: {
        searchTerm: '',
        filters: {
            status: null,
            errorsOnly: false
        },
        carts: [],
        bewohnerCarts: [],
        bewohnerPauschalenStatus: [],
        selectedBewohner: [],
        pauschalen: [],
        year: moment().date(1).subtract(1, 'month').format('YYYY'),
        month: moment().date(1).subtract(1, 'month').format('MM'),
        bewohnerInEditmode: null
    },

    getters: {
        carts: (state) => state.carts,


        bewohnerInEditmode: (state) => {
            return state.bewohnerInEditmode
        },
        searchTerm: (state) => state.searchTerm,
        isSearchActive: (state) => {
            return state.searchTerm !== undefined && state.searchTerm && state.searchTerm.length > 0
        },
        bewohnerPauschalenStatus: (state) => state.bewohnerPauschalenStatus,
        filters: (state) => state.filters
    },

    actions: {
        async show({commit, state}, bewohnerID) {
            state.bewohnerCarts = false;
            let res = await httpClient({
                url: process.env.VUE_APP_API_CHECKLIST + 'insurance/' + state.year + '/' + state.month + '/' + bewohnerID,
                method: 'get'
            });

            state.bewohnerCarts = res.carts

            state.bewohnerCarts[0].cart_krankenkasse.sort((a, b) => {
                return a.jahrespauschale ? 1 : b.jahrespauschale ? -1 : // sort by "jahrespauschale" first
                    parseInt(a.migelnr.replace(/\./g, '')) - parseInt(b.migelnr.replace(/\./g, '')) || // then sort by "migelnr"
                    a.artikelnr.localeCompare(b.artikelnr, 'de', {numeric: true}) // then sort by "artikelnr"
            })

            state.bewohnerCarts[0].cart_bewohner.sort((a, b) => {
                return a.artikelnr.localeCompare(b.artikelnr, 'de', {numeric: true})
            })

            state.bewohnerPauschalenStatus = res.pauschalen
        },

        async getBewohnerPauschalenStatus({state}, bewohnerID) {
            httpClient.get(process.env.VUE_APP_API_CHECKLIST + 'insurance/pauschalen/status/' + bewohnerID)
                .then(response => {
                    state.bewohnerPauschalenStatus = response.data
                })
        },

        async getPauschalen({commit, state}, options) {
            state.pauschalen = await httpClient({
                url: process.env.VUE_APP_API_CHECKLIST + 'insurance/pauschalen/' + options.year + '/' + options.month + '/' + options.bewohnerID + '/' + options.type,
                method: 'get'
            });
        },

        async changePauschaleAufgebraucht({commit, state, dispatch}, options) {

            const res = await httpClient({
                url: process.env.VUE_APP_API_CHECKLIST + 'insurance/pauschalen/aufgebraucht',
                method: 'post',
                data: {
                    id: options.id,
                    aufgebraucht: options.aufgebraucht,
                    bewohnerID: options.bewohnerID,
                    year: options.year
                }
            })

            dispatch('getPauschalen', {
                year: options.year,
                month: state.month,
                bewohnerID: options.bewohnerID,
                type: 'yearly'
            });

            dispatch('getBewohnerPauschalenStatus', options.bewohnerID)
        },

        async saveComment({commit, state, dispatch}, options) {

            const res = await httpClient({
                url: process.env.VUE_APP_API_CHECKLIST + 'insurance/pauschalen/comment',
                method: 'post',
                data: {
                    comment: options.comment,
                    bewohnerID: options.bewohnerID,
                    year: options.year
                }
            })

            dispatch('getPauschalen', {
                year: options.year,
                month: state.month,
                bewohnerID: options.bewohnerID,
                type: 'yearly'
            });
        },


        async createCart({commit, state}, bewohnerID) {
            return httpClient.get(process.env.VUE_APP_API_CHECKLIST + 'insurance/' + state.year + '/' + state.month + '/' + bewohnerID + '/new')
                .then(response => {
                    state.bewohnerCarts = response.carts
                })
        },

        async resetCart({commit, state}, bewohnerID) {
            return httpClient.delete(process.env.VUE_APP_API_CHECKLIST + 'insurance/' + state.year + '/' + state.month + '/' + bewohnerID)
                .then(response => {
                    state.bewohnerCarts = response.carts
                })
        },

        async index({commit, state}, initCall) {

            if (!state.year) {
                state.year = moment().subtract(1, 'month').format('YYYY')
                state.month = moment().subtract(1, 'month').format('MM')
            }

            let res = await httpClient({
                url: process.env.VUE_APP_API_CHECKLIST + 'insurance/' + state.year + '/' + state.month + (initCall ? '?init' : ''),
                method: 'get'
            })

            state.carts = res.carts
        },

        async saveCart({commit, dispatch}, cartRecord) {

            const res = await httpClient({
                url: process.env.VUE_APP_API_CHECKLIST + 'insurance/cart',
                method: 'post',
                data: cartRecord
            })

            dispatch('index');
        },

        async submit({commit, state, dispatch}) {

            const res = await httpClient({
                url: process.env.VUE_APP_API_CHECKLIST + 'insurance/' + state.year + '/' + state.month,
                method: 'post',
                data: {bewohner: state.selectedBewohner}
            })

            dispatch('index');
        },

        async requestPDF({commit, state, dispatch}, id) {

            return httpClient({
                url: process.env.VUE_APP_API_CHECKLIST + 'insurance/pdf/' + id,
                responseType: 'blob',
                method: 'get'
            });

        }

    },

    mutations: {
        setBewohnerInEditmode(state, bewohnerId) {
            state.bewohnerInEditmode = bewohnerId
        },

        setSearchTerm(state, value) {
            state.searchTerm = value
        },

        setFilterStatus(state, value) {
            state.filters.status = value
        },

        setFilterErrorsOnly(state, value) {
            state.filters.errorsOnly = value
        }
    }
}
