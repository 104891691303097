<template>
    <div>
        <DatePicker ref="dp" type="year" range format="YYYY-MM" valueType="format" @pick="pick($event)" @open="open()"
                    range-separator="-" :disabled-date="getDisabledDate"
                    @change="selectDate($event[0], $event[1])"
                    :show-second="false">
            <template v-slot:input="{input}">
                <v-hover v-slot="{ hover }">
                    <v-img :src="hover ? require('@/assets/img/ico2023/kalender-hover.svg') : require('@/assets/img/ico2023/kalender.svg')"
                           width="28"
                           class="icon"></v-img>
                </v-hover>
            </template>
            <template v-slot:icon-next>
                <i></i>
            </template>
            <template v-slot:icon-calendar>
                <i></i>
            </template>
            <template v-slot:footer="{ emit }">
                <div style="text-align: left">
                    <button class="openEndButton" color="primary" variant="flat" rounded @click="selectDate(start_date, null, true)" small
                           :disabled="!start_date"
                           v-if="!start_date || (start_date)">{{ openEndLabel }}
                    </button>
                </div>
            </template>
        </DatePicker>
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from "moment";

export default {
    name: 'YearDateRangePicker',
    components: {DatePicker},
    emits: ['update:modelValue'],
    props: {
        minDate: {
            required: false,
            default: null
        },
        startDate: {
            required: false,
            default: moment()
        },
        locale: {
            required: false,
            default: "ch-de"
        },
        yearRange: {
            required: false,
            default() {
                return [1900, 2100]
            }
        },
        tooltip: {
            required: false,
            default: null
        },
        openEndLabel: {
            required: false,
            default: 'Ende offen'
        }
    },
    data() {
        return {
            date: null,
            dp: null,
            modelType: null,
            picker: false,
            activePicker: null,
            start_date: null,
        }
    },
    methods: {
        selectDate(start_date, end_date = null, close = false) {
            this.picker = false;
            this.$emit('update:modelValue', {
                start_date: moment(start_date).startOf(this.yearPicker ? 'year' : 'month').toDate(),
                end_date: end_date ? moment(end_date).endOf(this.yearPicker ? 'year' : 'month').toDate() : null
            });
            this.date = null;
            if (close) {
                this.$refs.dp.closePopup();
            }
        },

        getDisabledDate(date, current) {
            if (this.minDate) {
                if (moment(this.minDate).isAfter(moment(date))) {
                    return true;
                }
            }
            if (this.start_date) {
                if (moment(this.start_date).isAfter(moment(date))) {
                    return true;
                }
            }
            return false;
        },

        pick(date) {
            this.start_date = moment(date).toDate()
        },

        open() {
            this.start_date = null;
            this.end_date = null;
        },
    },
    mounted() {
        this.modelType = this.yearPicker ? "yyyy-01-01" : "yyyy-MM-01";
    },
    watch: {
        picker(val) {
            if (this.yearPicker) {
                val && setTimeout(() => (this.activePicker = 'YEAR'))
            }
        },
    },
}

</script>

<style>
.v-btn--rounded {
    border-radius: 10px;
}

.icon {
    cursor: pointer;
}

.mx-datepicker-range {
    width: auto;
}

.mx-range-wrapper {
    .mx-calendar-panel-year:nth-child(2) {
        display: none;
    }
}

.mx-datepicker-footer {
    .v-btn.primary {
        background-color: #CE20A8 !important;
    }
}

.mx-calendar-header-label {
    font-size: 16px;
    font-weight: 700;
    color: black;
}

.mx-datepicker-main {
    font-size: 16px;
    color: black;
}

.mx-table {
    border-spacing: 4px;
}

.mx-calendar-content .cell {
    border-radius: 10px;
    height: 30px;

    &:hover {
        background: rgba(0, 0, 0, 0.08);
        color: black;
    }

    &.disabled {
        color: rgba(0, 0, 0, 0.26);
        background: transparent;

        &:hover {
            background: transparent;
        }
    }

    &.active {
        background: #CE20A8 !important;
        color: white;
    }
}

.openEndButton {
    font-weight: 700;
    color: #CE20A8;
    text-decoration: underline;
    padding-bottom: 3px;
    margin-bottom: 2px;
}
</style>
