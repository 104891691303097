<template>
    <div>
        <div>
            <v-row>
                <v-col cols="12">
                    <template v-if="loading">
                        <div class="loading-wrapper">
                            <v-progress-circular color="primary" indeterminate></v-progress-circular>
                        </div>
                    </template>
                    <template>
                        <div class="custom-box inkolisting px-12">
                            <v-row v-if="history.length > 0">
                                <v-col cols="5" class="font-weight-bold pl-0">
                                    Inkograd
                                </v-col>
                                <v-col cols="3" class="font-weight-bold">
                                    von
                                </v-col>
                                <v-col cols="3" class="font-weight-bold">
                                    bis
                                </v-col>
                            </v-row>
                            <template v-for="item in history">
                                <v-row v-if="history.length > 0"
                                       :class="item.inkograd === 5 ? 'bg-white pb-0 custom-box pl-0 pt-0 pr-0 b-bottom' : 'b-top'"
                                       :style="item.inkograd === 5 ? 'border-radius:  15px 15px 0 0; margin-left: -18px; width: calc(100% + 34px) !important; padding-right: 5px !important; margin-right: -5px !important;' : ''">
                                    <v-col cols="5" class="pl-0"
                                           :style="item.inkograd === 5 ? 'margin-left: 8px;' : ''">
                                        <span class="inko-text">{{ getByGrade(item.inkograd).name }}</span>
                                        <span class="inko-icon">
                    <InkoGradIndicator :inkograd="item.inkograd" :tooltip="false"></InkoGradIndicator></span>
                                    </v-col>
                                    <v-col cols="3" :class="item.inkograd === 5 ? 'pl-2' : ''">
                                        {{ asMoment(item.start).format('MMM YYYY') }}
                                    </v-col>
                                    <v-col cols="3" :class="item.inkograd === 5 ? 'pl-2' : ''">
                                        {{ item.end ? asMoment(item.end).format('MMM YYYY') : "–" }}
                                    </v-col>
                                    <div class="flex-grow-1" :class="item.inkograd === 5 ? 'pl-0' : ''">
                                        <div class="float-right pt-3 pr-0">
                                            <ConfirmDialog class="float-right" title="Inkograd löschen"
                                                           @confirm="del(item)">
                                                <template #button>
                                                    <v-hover v-slot="{ hover }">
                                                        <v-img
                                                                :src="hover ? require('@/assets/img/ico/loeschen-hover.svg') : require('@/assets/img/ico/loeschen.svg')"
                                                                width="28" class="icon"></v-img>
                                                    </v-hover>
                                                </template>
                                                Möchten Sie diesen Inkograd wirklich löschen? Sollte der Inkograd
                                                wechseln, bitte
                                                fügen Sie einen neuen Eintrag mit dem Start-Monat hinzu.
                                                <div class="mt-3" v-if="item.inkograd === 5">
                                                    Bei der Löschung einer "totalen Inkontinenz" werden <b>alle
                                                    Kostengutsprachen</b> des Bewohners gelöscht.
                                                </div>
                                            </ConfirmDialog>
                                        </div>
                                    </div>
                                </v-row>
                                <v-row v-if="item.inkograd === 5" class="bg-white custom-box pb-0 pl-0 pt-0 pr-0 mb-4"
                                       style="border-radius: 0 0  15px 15px"
                                       :style="item.inkograd === 5 ? 'margin-left: -18px; width: calc(100% + 34px) !important; padding-right: 5px !important; margin-right: -5px !important;' : ''">
                                    <v-col>
                                        <Kostengutsprache :allow-kostengutsprache="true"
                                                          :min-date="asMoment(item.start)" :inkograd="5"
                                                          :form-values="formValues"/>
                                    </v-col>
                                </v-row>
                            </template>
                            <div v-if="history.length === 0">
                                <p class="no-data font-weight-bold">Kein Inkograd erfasst</p>
                            </div>
                            <InkogradDialog @save="add($event)" :bewohner="formValues"
                                            :last-data="history.length > 0 ? {grade: history[history.length-1].inkograd, start:history[history.length-1].start} : null"
                                            :minDate="history.length > 0 ? asMoment(history[history.length-1].start).add(1,'Month') : false">
                                <template #button="slotProps">
                                    <div class="add-icon-container" @click="slotProps.open()">
                                        <v-btn small fab light depressed color="primary" class="addbtn" style="margin-left: -18px;">
                                            <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                        <span class="text">Inkograd Änderung erfassen</span>
                                    </div>
                                </template>
                            </InkogradDialog>
                        </div>
                        <v-row class="mt-4 mb-8">
                           <!-- <v-col cols="8">
                                <div class="custom-box">
                                    <Fixierhose :form-values="formValues"/>
                                </div>
                            </v-col>-->
                            <v-col cols="4">
                                <ul class="downloads">
                                    <li>
                                        <a href="./pdf/2022-08_Puras_Gutsprache_Vorlage.pdf" target="_blank">
                                            <v-hover v-slot="{hover}">
                <span class="link"
                        :class="{'text-primary': hover}">
                 <v-img
                         :src="hover ? require('@/assets/img/ico2023/download-hover.svg') : require('@/assets/img/ico2023/download.svg')"
                         width="26"
                         class="cursor-pointer"></v-img>
                    <span class="text-small">
                        Download Gesuch Kostengutsprache
                    </span>
                  </span>
                                            </v-hover>
                                        </a>
                                    </li>
                                </ul>
                            </v-col>
                        </v-row>

                    </template>
                </v-col>
            </v-row>
        </div>
        <InfoDialog title="Kostengutsprache überprüfen" @confirm="del(item)" ref="infoDialog">
            Die gespeicherte Änderung könnte Auswirkungen auf die Kostengutsprache haben. Bitte überprüfen Sie die
            Kostengutsprache auf ihre Korrektheit und passen Sie diese falls nötig an.
        </InfoDialog>
    </div>
</template>
<script>
import {inkoGradActions, inkoGradGetters} from "@/components/observables/inkograd";
import InkogradDialog from "@/components/Bewohner/Bewohnerverwaltung/dialogs/InkogradDialog";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import InkoGradIndicator from "@/components/inkograd/InkoGradIndicator";
import moment from "moment";
import InfoDialog from "@/components/shared/InfoDialog.vue";
import Fixierhose from "./Fixierhose.vue";
import Kostengutsprache from "./Kostengutsprache.vue";
import KostengutspracheDialog from "../dialogs/KostengutspracheDialog.vue";

export default {
    name: "Inkograd",
    components: {
        KostengutspracheDialog,
        Kostengutsprache, Fixierhose, InfoDialog, ConfirmDialog, InkogradDialog, InkoGradIndicator
    },
    props: {
        formValues: {
            required: true
        },
        edit: {
            required: false,
            default: false,
        }
    },
    emits: ['updateInkograd'],
    data() {
        return {}
    },
    computed: {
        ...inkoGradGetters,
        loading: {
            get() {
                return (this.bewohnerInkoGradHistory === null) ? true : false;
            }
        },
        history: {
            get() {
                let history = []
                if (this.bewohnerInkoGradHistory) {
                    for (let i = 0; i < this.bewohnerInkoGradHistory.length; i++) {
                        let month = {
                            id: this.bewohnerInkoGradHistory[i].id,
                            start: moment(this.bewohnerInkoGradHistory[i].start),
                            end: this.bewohnerInkoGradHistory[i].end ? this.bewohnerInkoGradHistory[i].end : null,
                            inkograd: this.bewohnerInkoGradHistory[i].inkograd
                        }

                        if (this.bewohnerInkoGradHistory[i].end) {
                            month.end = moment(this.bewohnerInkoGradHistory[i].end)
                        } else if (this.bewohnerInkoGradHistory[i + 1]) {
                            month.end = moment(this.bewohnerInkoGradHistory[i + 1].start).subtract(1, 'months')
                        }

                        history.push(month)
                    }
                }
                if (history.length > 0) {
                    this.formValues.inkograd_start = null;
                }
                return history
            }
        }

    },
    methods: {
        ...inkoGradActions,

        add(data) {
            this.addInkoGrad(data).then((res) => {
                if (res.checkKostengutsprache) {
                    this.$refs.infoDialog.open();
                }
                this.$emit('updateInkograd');
            });
        },

        del(data) {
            this.deleteInkoGrad(data.id).then((res) => {
                if (res.checkKostengutsprache) {
                    this.$refs.infoDialog.open();
                }
                this.$emit('updateInkograd');
            });
        },

        getByGrade(inkograd) {
            return this.grades.find(grade => grade.grade == inkograd)
        },

        asMoment(date = null, locale = "de") {
            return date ? moment(date).locale(locale) : moment().locale(locale);
        },

        setDate(date) {
            this.formValues.inkograd_start = moment(date, "YYYY-MM", false).format("YYYY-MM-DD");
        }
    }
}
</script>

<style scoped lang="scss">
.v-radio {
  .name {
    width: 180px;
    font-size: 16px;
    font-weight: 300;
    font-family: "Avenir LT Std";
  }
}

.downloads {
  list-style: none;

  &.absolute {
    position: absolute;
    left: 50px;
    bottom: 15px;
    padding: 0;
  }

  li {
    a {
      display: block;
      text-decoration: none;
      font-family: "Avenir LT Std Heavy";
      font-size: 16px;
      color: #6e6e6e;
      margin-bottom: 8px;

      .v-image {
        position: relative;
        top: 4px;
        display: inline-block;
        margin-right: 8px;
      }
    }
  }
}

.loading-wrapper {
  min-height: 150px;
  position: relative;

  .v-progress-circular {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.no-data {
  padding: 8px 16px;
  padding-left: 0;
  margin-left: -18px;
}

.inko-text {
  display: inline-block;
  margin-right: 15px;
  min-width: 145px;
}

.inko-icon {
  display: inline-block;
}

.add-icon-container {
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 60px;

  .v-btn {
    display: block;
    float: left;
  }

  .text {
    display: block;
    padding: 2px 0px;
    float: left;
    width: 150px;
    font-size: 14px;
    font-weight: bold;
    margin-left: 8px;
  }
}

.link {
  .v-image {
    diaply: inline-block;
  }
}

.text-small {
  display: inline-block;
  padding: 2px 0px;
  font-size: 14px;
  font-weight: bold;
  color: black;
}

.inkolisting {
  .theme--light.v-data-table {
    background: transparent !important;
  }
}
</style>
