const rowConfig = {
    krankenkasse: [
        {
            title: 'Artikel',
            attr: 'artikelnr',
            width: '90px',
            text: 'left',
        }, {
            title: '',
            attr: 'bezeichnung',
            width: '200px',
            text: 'left',
        },{
            title: 'MiGel-Nr',
            attr: 'migelnr',
            width: '100px',
            text: 'left',
        }, {
            title: 'Menge',
            attr: 'menge',
            width: '80px',
            text: 'center',
        }, {
            title: 'Einheit',
            attr: 'einheit',
            width: '90px',
            text: 'left',
        }, {
            title: 'HVB-Pflege',
            attr: 'hvb_pflege',
            width: '90px',
            text: 'right',
        }, {
            title: 'Preis*',
            attr: 'preis',
            width: '90px',
            text: 'right',
        }, {
            title: 'Nicht verrechenbar',
            function: 'total_nicht_verrechenbar',
            width: '120px',
            text: 'right',
        }, {
            title: 'Verrechenbarer Betrag',
            function: 'total_verrechenbar',
            width: '140px',
            text: 'right',
        }
    ],

    bewohner: [
        {
            title: 'Persönliches Material',
            attrs: ['artikelnr', 'bezeichnung'],
            width: '310px',
            text: 'left',
        }, {
            title: '',
            width: '100px',
            text: 'left',
        }, {
            title: 'Menge',
            attr: 'menge',
            width: '80px',
            text: 'center',
        }, {
            title: 'Einheit',
            attr: 'einheit',
            width: '90px',
            text: 'left',
        }, {
            title: '',
            width: '90px',
            text: 'right',
        }, {
            title: 'Preis',
            attr: 'preis',
            width: '90px',
            text: 'right',
        }, {
            title: '',
            width: '120px',
            text: 'right',
        }, {
            title: 'Bewohneranteil',
            function: 'total_nicht_verrechenbar',
            width: '140px',
            text: 'right',
        }
    ],

    bewohner_migel: [
        {
            title: 'MiGeL Produkte Bewohneranteil',
            width: '310px',
            attrs: ['artikelnr', 'bezeichnung'],
            text: 'left',
        }, {
            title: 'MiGel-Nr',
            width: '100px',
            attr: 'migelnr',
            text: 'left',
        }, {
            title: 'Menge',
            attr: 'menge',
            width: '80px',
            text: 'center',
        }, {
            title: 'Einheit',
            attr: 'einheit',
            width: '90px',
            text: 'left',
        }, {
            title: 'HVB-Pflege',
            attr: 'hvb_pflege',
            width: '90px',
            text: 'right',
        }, {
            title: 'Preis',
            attr: 'preis',
            width: '90px',
            text: 'right',
        }, {
            title: '',
            width: '120px',
            text: 'right',
        }, {
            title: 'Bewohneranteil',
            function: 'total_nicht_verrechenbar',
            width: '140px',
            text: 'right',
        }
    ]
}

export {rowConfig}