<template>
  <svg id="Gruppe_4566" data-name="Gruppe 4566" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10.031" height="15.282" viewBox="0 0 10.031 15.282">
    <defs>
      <clipPath id="clip-path">
        <path id="Pfad_2568" data-name="Pfad 2568" d="M0-15.351H10.031V-30.633H0Z" transform="translate(0 30.633)" fill="#f38b1c"/>
      </clipPath>
    </defs>
    <g id="Gruppe_4564" data-name="Gruppe 4564" transform="translate(0 0)" clip-path="url(#clip-path)">
      <g id="Gruppe_4563" data-name="Gruppe 4563" transform="translate(0.249 0.41)">
        <path id="Pfad_2567" data-name="Pfad 2567" d="M-4.788-14.689A4.681,4.681,0,0,0-.021-19.28c0-3.741-4.325-10.032-4.325-10.032S-9.554-23.191-9.554-19.28A4.681,4.681,0,0,0-4.788-14.689Z" transform="translate(9.554 29.312)" fill="#f38b1c"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'DripIcon'
}
</script>

<style scoped>

</style>