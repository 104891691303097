import Vue from 'vue'
import VueI18n from 'vue-i18n'

const messages = {
    de: {
        direktabrechnung: {
            nonmigel: 'Persönliches Material',
            regular: 'MiGeL-Material',
            yearly: 'MiGeL-Material Pauschalen',
            status: {
                flawed: 'fehlerhaft',
                prepared: 'bereit',
                sent: 'eingereicht',
                declined: 'zurückgewiesen'
            }
        },
    }
}

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'de', // set locale
    messages, // set locale messages
})

export default i18n