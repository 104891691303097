<template>
    <div>
    <v-tooltip bottom v-if="tooltip">
        <template v-slot:activator="{ on, attrs }">
            <div
                    v-bind="attrs"
                    v-on="on">
                <DripUnknownIcon v-if="inkograd===0" class=""/>
                <DripNotSetIcon v-else-if="isNaN(inkograd)" class=""/>
                <DripCancledIcon v-else-if="inkograd===1" class=""/>
                <DripOutlinedIcon v-else-if="inkograd===2" class=""/>
                <DripIcon v-else v-for="i in (inkograd - 2)" :key="'drip-'+i" :class="{'ml-1': i != 1}"/>
            </div>
        </template>
        {{ grade.name }}
    </v-tooltip>
        <template v-if="!tooltip">
            <DripUnknownIcon v-if="inkograd===0" class=""/>
            <DripNotSetIcon v-else-if="isNaN(inkograd)" class=""/>
            <DripCancledIcon v-else-if="inkograd===1" class=""/>
            <DripOutlinedIcon v-else-if="inkograd===2" class=""/>
            <DripIcon v-else v-for="i in (inkograd - 2)" :key="'drip-'+i" :class="{'ml-1': i != 1}"/>
        </template>
    </div>
</template>

<script>

import DripIcon from "./Icons/DripIcon";
import DripOutlinedIcon from "./Icons/DripOutlinedIcon";
import DripCancledIcon from "./Icons/DripCancledIcon";
import DripUnknownIcon from "./Icons/DripUnknownIcon";
import DripNotSetIcon from "./Icons/DripNotSetIcon";
import {inkoGradGetters} from "@/components/observables/inkograd";

export default {
    name: 'InkoGradIndicator',
    components: {DripUnknownIcon, DripCancledIcon, DripOutlinedIcon, DripIcon, DripNotSetIcon},
    props: {
        inkograd: {
            type: Number,
            required: true
        },
        tooltip: {
            required: false,
            default: true,
        }
    },

    computed: {
        ...inkoGradGetters,

        hasInkograd() {
            return this.inkograd > 1
        },

        grade() {
            return this.grades.find(g => g.grade === this.inkograd)
        }
    }
}
</script>

<style scoped>

</style>
