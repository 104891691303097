<template>
  <div>
    <span class="body-2 heavy">{{ artnr }}</span> <span v-if="ownartno">|</span>
    <span class="body-2 heavy" v-if="ownartno"> {{ ownartno }}</span>
    <span v-if="verpackung">|</span>
    <span class="body-2 heavy" v-if="verpackung"> {{ verpackung }}</span><br>

    <div class="body-1 pt-0" style="font-size: 14px !important;">
      <div style=" max-height: 40px;">{{ name }}</div>
      <div class="body-2 caption mt-1" style="font-size: 11px !important;" v-if="zaehleinheit">pro {{ zaehleinheit }}</div>

      <div v-if="migelflag" style="background:#ccc;margin-top: 5px;display: inline-block; color: #fff; font-size: 12px;line-height:17px;padding: 2px 5px 0px 5px;font-weight: bold">
        MiGeL
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ProductCaption',
    props:['artnr','ownartno','name','verpackung','migelflag','zaehleinheit'],
  }
</script>