<template>
  <svg version="1.1" id="Gruppe_4566" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
       y="0px" viewBox="0 0 10.03 15.28" width="11px" height="16px" style="enable-background:new 0 0 10.03 15.28;" xml:space="preserve">
    <rect x="0.36" y="7.9" class="st0" width="9.3" height="1.06"/>
</svg>


</template>

<script>
export default {
  name: 'DripUnknownIcon'
}
</script>

<style type="text/css">
.st0{fill:#6A6868;}
</style>
