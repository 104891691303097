<template>
  <svg width="100%" height="100%" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-miterlimit:10;">
    <g>
        <circle cx="8" cy="8" r="8" fill="currentColor"/>
    </g>
    <g transform="matrix(1,0,0,1,0.249,0.41)">
        <g>
            <g id="Gruppe_4564_00000042715124704464073890000002711070734714667683_">
                <g id="Gruppe_4563_00000042010585990585840280000002878523257915202700_">
                    <path id="Pfad_2567_00000127040705964319785240000008090705335067716767_" d="M6.02,4.41C7.06,5.93 8.76,8.7 8.71,10.18C8.71,11.67 7.52,12.7 6.13,12.7L5.99,12.7C4.55,12.7 3.41,11.6 3.41,10.19C3.41,8.52 4.98,5.9 6.02,4.41Z" style="fill:none;fill-rule:nonzero;stroke:white;stroke-width:0.45px;"/>
                </g>
            </g>
        </g>
    </g>
    <g transform="matrix(1,0,0,1,0.249,0.41)">
        <g>
            <g id="Gruppe_4564_00000114064719996898558880000012984475484400755898_">
                <g id="Gruppe_4563_00000049933179715919202880000010995468698816036742_">
                    <path id="Pfad_2567_00000116217302626551487910000015772972532107526034_" d="M10.51,2.47C11.12,3.36 12.12,4.99 12.09,5.86C12.09,6.74 11.39,7.34 10.57,7.34L10.49,7.34C9.64,7.34 8.97,6.69 8.97,5.86C8.98,4.88 9.9,3.35 10.51,2.47Z" style="fill:none;fill-rule:nonzero;stroke:white;stroke-width:0.45px;"/>
                </g>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {
  name: "LogoIP"
}
</script>

<style scoped>
svg {
  color: #F38B1CFF;
}
</style>