<template>
  <svg version="1.1" id="Gruppe_4566" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
       y="0px" viewBox="0 0 10 15.3" style="enable-background:new 0 0 10 15.3;" xml:space="preserve" width="10px" height="16px">
    <g>
	<g>
		<g id="Gruppe_4564" transform="translate(0 0)">
			<g id="Gruppe_4563" transform="translate(0.249 0.41)">
				<path id="Pfad_2567_00000158021199242926604890000012796445746198210455_" class="st0" d="M4.74,1.71
					C6.23,3.89,8.67,7.86,8.6,9.99c0,2.14-1.71,3.61-3.7,3.61H4.8H4.7C2.63,13.6,1,12.02,1,10C1,7.59,3.25,3.84,4.74,1.71
					 M4.75,0.01C4.75,0.01,0,6.1,0,10c0,2.6,2.1,4.6,4.7,4.6h0.1h0.1c2.5,0,4.7-1.98,4.7-4.6C9.5,6.3,4.75,0.01,4.75,0.01L4.75,0.01
					z"/>
			</g>
		</g>
	</g>
</g>
    <text transform="matrix(1 0 0 1 2.5694 12.9879)" class="st0 st1 st2">?</text>
</svg>

</template>

<script>
export default {
  name: 'DripUnknownIcon'
}
</script>

<style type="text/css">
.st0{fill:#6A6868;}
.st1{font-family:'Avenir-Medium';}
.st2{font-size:9.5px;}
</style>