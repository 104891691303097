<template>
  <div
      class="loader w-full overflow-hidden"
      :class="{ 'loader--visible': visible }"
    >
    <div class='pre-loader-linear'>
      <div class='progress'></div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "loader",
    props: ["visible"]
  };
</script>



<style lang="scss">
  .loader {
    z-index: 999;
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    pointer-events: none;
    opacity: 0;
    &--visible {
      opacity: 1;
      pointer-events: all;
    }
  }

  .pre-loader-linear {
    position:relative;
    height:5px;
    background:#CE20A8;
    overflow:hidden;
  }
  .progress {
    position:absolute;
    left:0;
    top:0;
    bottom:0;
    right:50%;
    background: #d67cc2;
    transform:translate(-100%, 0);
    animation:1.5s loading infinite ease-in;
  }
  @keyframes loading {
    0% {
      transform:translate(-100%, 0);
    }
    100% {
      transform:translate(200%, 0);
    }
  }
</style>
