<template>
  <div>
    <div class="custom-box">
      <template v-if="prescriptions.length > 0">
        <div class="prescription-row -header">
          <div>
            Dauerrezept
          </div>
          <div>
            von
          </div>
          <div>
            bis
          </div>
          <div class="validity">
            gültig
          </div>
          <div>

          </div>
          <div>

          </div>
        </div>

        <div v-for="prescription in prescriptions" :key="prescription.id" class="prescription-row">
          <div>
            {{ prescription.name }}
          </div>
          <div>
            {{ moment.unix(prescription.from).format('DD.MM.YYYY') }}
          </div>
          <div>
            {{ moment.unix(prescription.till).format('DD.MM.YYYY') }}
          </div>
          <div class="validity">
            <v-checkbox
                v-if="!prescription.expired"
                v-model="prescription.valid"
                color="primary"
                hide-details
                class="ma-0"
                @change="toggleValidity(prescription.id, !!prescription.valid)"
            />
          </div>

          <div>
            <AssetPreviewDialog
                :asset="prescription.asset"
                :title="prescription.name"
            >
              <template #button="slotProps">
                <v-hover v-slot="{ hover }">
                  <div class="icon-auge cursor-pointer" @click="slotProps.open()"></div>
                </v-hover>
              </template>
            </AssetPreviewDialog>
          </div>

          <div>
            <ConfirmDialog title="Rezept löschen" @confirm="deletePrescription(prescription.id)">
              <template #button>
                <v-hover v-slot="{ hover }">
                  <v-img
                      :src="hover ? require('@/assets/img/ico/loeschen-hover.svg') : require('@/assets/img/ico/loeschen.svg')"
                      width="28"
                      class="icon"
                  ></v-img>
                </v-hover>
              </template>

              Möchten Sie das Rezept <span class="text--primary">{{ prescription.name }}</span> inklusive Datei wirklich löschen?
            </ConfirmDialog>
          </div>
        </div>
      </template>

      <div v-else>
        <b>Rezepte</b>
      </div>

      <MedicalPrescriptionUploadDialog :bewohnerData="formValues">
        <template #button="slotProps">
          <div class="add-icon-container" @click="slotProps.open()">
            <v-btn small fab light depressed color="primary" class="addbtn" style="margin-left: -18px;">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <span class="text">Signiertes Rezept hinzufügen</span>
          </div>
        </template>
      </MedicalPrescriptionUploadDialog>
    </div>

    <div class="prescription-actions">
      <MedicalPrescriptionGeneratorDialog :bewohnerData="formValues">
        <template #button="slotProps">
          <div class="add-icon-container" @click="slotProps.open()">
            <v-btn small fab light depressed color="primary" class="addbtn" style="margin-left: -18px;">
              <v-icon>mdi-file-document-outline</v-icon>
            </v-btn>
            <span class="text">Ärztliche Verordnung generieren</span>
          </div>
        </template>
      </MedicalPrescriptionGeneratorDialog>
    </div>
  </div>
</template>

<script>
import MedicalPrescriptionUploadDialog from '@/components/Bewohner/Bewohnerverwaltung/dialogs/MedicalPrescriptionUploadDialog.vue'
import moment from 'moment'
import ConfirmDialog from '@/components/shared/ConfirmDialog.vue'
import AssetPreviewDialog from '@/components/assets/AssetPreviewDialog.vue'
import MedicalPrescriptionGeneratorDialog from '@/components/Bewohner/Bewohnerverwaltung/dialogs/MedicalPrescriptionGeneratorDialog.vue'

export default {
  name: 'MedicalPrescription',
  components: { MedicalPrescriptionGeneratorDialog, AssetPreviewDialog, ConfirmDialog, MedicalPrescriptionUploadDialog },

  /**
   * component props
   */
  props: {
    formValues: {
      required: true
    },
    edit: {
      required: false,
      default: false,
    }
  },

  /**
   * component data
   * @returns {{moment}}
   */
  data: () => {
    return {
      moment: moment,
    }
  },

  /**
   *
   */
  methods: {
    deletePrescription (id) {
      this.$store.dispatch('prescription/deletePrescription', { bewohnerId: this.formValues.bewohnerID, id: id })
    },

    toggleValidity (id, validity) {
      this.$store.dispatch('prescription/togglePrescriptionValidity', { bewohnerId: this.formValues.bewohnerID, id: id, valid: validity })
    }
  },

  /**
   * component computed properties
   */
  computed: {
    prescriptions () {
      const bewohner = this.$store.getters['prescription/bewohnerPrescriptions'](this.formValues.bewohnerID)

      if (bewohner) {
        return bewohner.prescriptions.sort((a, b) => a.from < b.from ? 1 : -1)
      }

      return []
    }
  },

  /**
   * component lifecycle hooks
   */
  created () {
    if (this.formValues.bewohnerID) {
      this.$store.dispatch('prescription/fetchBewohnerPrescriptions', this.formValues.bewohnerID)
    }
  }
}
</script>

<style scoped lang="scss">

.prescription-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 80px;
}

.prescription-row {
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr 100px 100px 80px 30px 30px;
  align-items: center;
  padding-block: 15px;
  border-bottom: 1px solid #ACACAC;

  &.-header {
    font-weight: 700;
  }

  .validity {
    display: flex;
    justify-content: center;
  }
}

.add-icon-container {
  display: flex;
  gap: 1rem;
  margin-block-start: 40px;
  margin-inline-start: 18px;
  cursor: pointer;

  .text {
    display: block;
    padding: 2px 0;
    float: left;
    width: 150px;
    font-size: 14px;
    font-weight: bold;
  }
}

.icon-auge {

  &:hover {
    color: #ce20a8;
  }
}
</style>