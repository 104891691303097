<template>
  <div id="reports">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="primary--text mb-0">
            Verbrauchsliste drucken
          </h1>
        </v-col>
      </v-row>
      <!-- <v-row>
         <v-col cols="12">
           Bitte wählen Sie Ihren Report & die gewünschten Optionen.
         </v-col>
       </v-row>-->
      <v-row>
        <v-col cols="4">
          Bitte wählen Sie Ihren Report und die gewünschten Optionen.
          <!--<v-text-field clearable light placeholder="Suche nach Name, Vorname, Zimmer" autocomplete="off"></v-text-field>-->
        </v-col>
        <v-col class="pb-12">
          <v-btn depressed color="primary" class="float-right mt-4 mb-0 ml-4" @click="printKvg">
            Bewohnerliste drucken
          </v-btn>
          <v-btn depressed color="primary" class="float-right mt-4  ml-4" @click="getKVGXls">Excel Bewohnerliste
            öffnen
          </v-btn>
        </v-col>
      </v-row>
      <v-divider class="mb-12"></v-divider>
      <v-row v-if="reportData" id="bew-select">
        <v-col cols="4">
          <v-card>
            <v-card-title>
              <b>Wohneinheit / Bewohner</b>
            </v-card-title>
            <v-card-text>
              <v-checkbox v-model="showDeleted" :true-value="'on'" :false-value="'off'" class="pl-1" dense light>
                <template v-slot:label>
                  <span class="body-1 normal">gelöschte Bewohner berücksichtigen</span>
                </template>
              </v-checkbox>
              <v-divider class="mt-2 mb-8"></v-divider>
              <v-checkbox v-model="reportData.checkbox" @click="selectAllWH()" class="pl-1" dense light>
                <template v-slot:label>
                  <span class="body-1 normal">Alle Bewohner</span>
                </template>
              </v-checkbox>
              <v-divider class="my-2"></v-divider>
              <v-expansion-panels multiple accordion flat class="default">
                <v-expansion-panel v-for="(wh,i) in reportData.children" :key="'acc'+i">
                  <v-expansion-panel-header class="py-0 pl-0 pt-0 body-1">
                    <v-checkbox @click="selectAllWH(wh.internalID)" v-model="wh.checkbox" @click.stop class="pl-1" dense
                                light>
                      <template v-slot:label>
                      <span class="body-1 normal">
                        <span class="body-1 normal">{{ wh.name }}</span> <span class="pl-1" style="color:#ccc">{{
                          wh.children.length
                        }}</span>
                      </span>
                      </template>
                    </v-checkbox>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="pl-8 pb-5">
                    <v-row v-for="(b,i) in wh.children" :key="'acc'+i" class="py-0 my-0">
                      <v-col class="py-0 my-0">
                        <v-checkbox @click="checkboxState" v-model="b.checkbox" :true-value="true" :false-value="false"
                                    class="pl-1" dense light :label="b.name">
                          <template v-slot:label>
                            <span class="body-1 normal">{{ b.name }}</span>
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="4">
          <v-card>
            <v-card-title>
              <b>Kategorien</b>
            </v-card-title>
            <v-card-text>

              <v-checkbox dense small light flat v-model="onlymigel" :true-value="'on'" :false-value="'off'" label=""
                          class="mb-3">
                <template v-slot:label>
                      <span class="body-1 normal">
                        <span class="body-1 normal">Nur MiGeL</span> <span class="pl-1" style="color:#ccc"></span>
                      </span>
                </template>
              </v-checkbox>
              <v-checkbox dense small light flat v-model="ownprods" :true-value="'on'" :false-value="'off'" label="">
                <template v-slot:label>
                    <span class="body-1 normal">
                      <span class="body-1 normal">Nur eigene Produkte</span> <span class="pl-1"
                                                                                   style="color:#ccc"></span>
                    </span>
                </template>
              </v-checkbox>
              <v-divider class="my-2"></v-divider>
              <v-expansion-panels multiple accordion flat class="default mt-0 pt-0" v-if="ownprods != 'on'">
                <v-expansion-panel>
                    <v-checkbox dense small light flat v-model="showAllCats" :true-value="true" :false-value="false"
                                @click.stop="selectAllCats()" label="">
                      <template v-slot:label>
                      <span class="body-1 normal">
                        <span class="body-1 normal">Alle Kategorien</span> <span class="body-2 pl-1" style="color:#ccc">{{prodCatsSelectedFiltered.length}} / {{
                          prodCats.length
                        }}</span>
                      </span>
                      </template>
                    </v-checkbox>
                  <v-divider class="my-2"/>
                    <v-row v-for="(st,index) in prodCats" :key="'catacc'+index" class="py-0 my-0">
                      <v-col class="py-0 my-0">
                        <v-checkbox dense small light flat v-model="prodCatsSelected[index]" :true-value="st.id"
                                    :false-value="false">
                          <template v-slot:label="">
                          <span class="body-1">
                              {{ st.name }}
                          </span>
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card>
            <v-card-title>
              <b>Druckoptionen</b>
            </v-card-title>
            <v-card-text>

              <v-row class="pt-0 mt-0">
                <v-col class="pt-0 mt-0">
                  <v-radio-group v-model="printType" class="pt-0 mt-1">
                    <v-radio
                        class="mb-5"
                        v-for="pt in printTypes"
                        :label="pt.name"
                        :value="pt.value"
                        :key="pt.value"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-center">
                  <v-date-picker
                      v-model="month"
                      width="270"
                      flat
                      type="month"
                      locale="ch-de"
                      color="primary"
                  ></v-date-picker>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog persistent v-model="loadModal" max-width="700">
      <v-card>
        <v-card-title class="headline">Druckversion wird generiert</v-card-title>
        <v-card-text class="body-2">
          Dies kann unter Umständen und je nach Grösse / Komplexität des Dokumentes bis zu 5 Minuten dauern. Der
          Download beginnt sobald das Dokument bereit ist automatisch.<br><br> Bitte warten...
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" depressed @click="cancelPrint()">Druckauftrag abbrechen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import httpClient from "../utils/httpClient";
import ExportPreview from "../components/reports/ExportPreview";
import moment from 'moment';
import XLSX from 'xlsx'
import {mapGetters} from "vuex";
import {bewohnerGetters, bewohnerActions} from "../components/observables/bewohner";

export default {
  name: 'Reports',

  components: {ExportPreview},

  data() {
    return {
      runErpSync: false,
      loadModal: false,
      exportResponse: false,
      showDeleted: 'on',
      printType: 'all',
      rerender: 0,
      fileCheckInterval: false,
      fileNameToWait: false,
      printTypes: [
        {
          name: 'Ausgefüllte Karten drucken',
          value: 'all'
        },
        {
          name: 'Leere Karten drucken (manuell ausfüllen)',
          value: 'empty'
        }
      ],
      showAllCats: true,
      month: moment().startOf('month').format('YYYY-MM'),
      onlymigel: 'off',
      ownprods: 'off',
      kvgXls: [],
      reportData: [],
      allCatSwitches: [],
      prodCats: [],
      prodCatsSelected: [],
      prodCatsSelectedFiltered: [],
    }
  },

  watch: {
    prodCatsSelected: function (val) {
      var c = 0;
      this.prodCatsSelected.forEach(function (e, k) {
        if (e) {
          c++;
        }
      });
      if (c == this.prodCatsSelected.length) {
        this.showAllCats = true;
      } else {
        this.showAllCats = false;
      }
      this.prodCatsSelectedFiltered = this.prodCatsSelected.filter(p => p);
    }

  },

  computed: {
    ...bewohnerGetters,
    ...mapGetters({
      'settings': 'benutzer/settings'
    }),

    bilder: {
      get() {
        return this.$store.state['bilder'].produktbilder
      }
    }
  },

  methods: {
    ...bewohnerActions,

    getChoosenBewohner(k) {

      var bew = [];
      k.children.forEach(function (wh) {
        wh.children.forEach(function (b) {
          if (b.checkbox == true) {
            bew.push(b.internalID)
          }
        })
      });

      return bew;
    },

    cancelPrint() {
      this.loadModal = false;
      if (this.fileCheckInterval) {
        clearTimeout(this.fileCheckInterval);
      }
    },

    //Wohneinheit Planungs liste
    async printKvg() {
      this.loadModal = true;

      let res = await httpClient({
        url: process.env.VUE_APP_API_CHECKLIST + 'get/pdf-download',
        method: 'post',
        data: {
          month: moment(this.month).startOf('month').format('YYYY-MM-DD'),
          bewohner: this.getChoosenBewohner(this.reportData),
          cats: this.prodCatsSelected,
          printType: this.printType,
          onlymigel: this.onlymigel,
          ownprods: this.ownprods,
          showDeleted: this.showDeleted
        }
      })

      if (res.type == 'success') {

        this.fileNameToWait = res.filename;
        var self = this;

        self.checkIfFileIsReady();


      } else {
        this.loadModal = false;
      }

    },

    async checkIfFileIsReady() {
      var self = this;

      let resCheck = await httpClient({
        url: process.env.VUE_APP_API_CHECKLIST + 'get/check-pdf-download-ready',
        method: 'post',
        responseType: 'blob',
        data: {
          filename: this.fileNameToWait
        }
      })

      if (resCheck != 'false' && resCheck.size > 20) {

        var fileURL = window.URL.createObjectURL(new Blob([resCheck], {type: 'application/pdf'}));
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'report-' + moment(this.month).format('YYYY-MM') + '.pdf');
        document.body.appendChild(fileLink);
        this.loadModal = false;

        fileLink.click();

      } else {
        this.fileCheckInterval = setTimeout(function () {
          self.checkIfFileIsReady();
        }, 2000)
      }
    },

    selectAllCats() {
      if (this.showAllCats) {
        this.prodCatsSelected = JSON.parse(JSON.stringify(this.allCatSwitches));
      } else {
        this.prodCatsSelected = [];
      }
    },

    selectAllWH(whInternalID = false) {

      var selectType = this.reportData.checkbox;

      this.reportData.children.forEach(function (wh) {

        if (whInternalID) {

          if (whInternalID == wh.internalID) {

            selectType = wh.checkbox;

            wh.children.forEach(function (b) {
              b.checkbox = selectType;
            });
          }
        } else {
          wh.checkbox = selectType;
          wh.children.forEach(function (b) {
            b.checkbox = selectType;
          });
        }
      });

      this.checkboxState();
    },

    checkboxState() {

      var allCheck = true;

      this.reportData.children.forEach(function (wh) {

        var allWHCheck = true;


        wh.children.forEach(function (b) {
          if (!b.checkbox) {
            allWHCheck = false;
          }
        });

        if (allWHCheck == false) {
          allCheck = false;
        }

        wh.checkbox = !allWHCheck ? false : true;

      });

      this.reportData.checkbox = !allCheck ? false : true;
    },

    /*
    https://github.com/SheetJS/sheetjs#utility-functions
     */
    async getKVGXls() {

      await this.getKVGXlsData();

      // only array possible
      var xls = XLSX.utils.aoa_to_sheet(this.kvgXls)

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new()

      // add Worksheet to Workbook>
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, xls, 'report')

      var date = moment()
      // export Excel file
      XLSX.writeFile(wb, 'PURAS-VM-Leistungen-' + this.month + '-' + date.format('DDMMYYYY') + '-' + date.format('hhmm') + '.xlsx')
    },

    downloadERP() {
      var csv = this.json2CSV(this.exportResponse)

      var date = moment()
      var exportedFilenmae = 'PURAS-VM-Leistungen-ERP-' + this.month + '-' + date.format('DDMMYYYY') + '-' + date.format('hhmm') + '.csv';

      var blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    async getKVGXlsData() {
      const res = await httpClient({
        url: process.env.VUE_APP_API_CHECKLIST + 'get/kvg-xls-data',
        method: 'post',
        data: {
          month: moment(this.month).startOf('month').format('YYYY-MM-DD'),
          bewohner: this.getChoosenBewohner(this.reportData),
          cats: this.prodCatsSelected,
          onlymigel: this.onlymigel,
          ownprods: this.ownprods,
          showDeleted: this.showDeleted
        }
      })

      if (res.type == 'success') {
        this.kvgXls = res.xls;
      }
    },

    async getReportInterfaceData() {
      const res = await httpClient({
        url: process.env.VUE_APP_API_CHECKLIST + 'get/report-interface-data',
        method: 'post',
        data: {month: this.month}
      })

      if (res.type == 'success') {
        this.reportData = res.tree;
        this.prodCats = res.prodCats;
        this.prodCatsSelected = res.prodCatsSelected;
        this.allCatSwitches = JSON.parse(JSON.stringify(res.prodCatsSelected));
      }
    },

    json2CSV(objArray) {
      var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      var str = '';

      for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
          if (index > 0) line += ';'

          line += array[i][index];
        }

        str += line + '\r\n';
      }

      return str;
    }
  },


  async mounted() {
    this.getReportInterfaceData();
    this.fetchBewohner(true);

    if (this.$store.state.benutzer.settings.inkosync === 1) {
      this.printTypes.push({
        name: 'Ausgefüllte Karten inklusive Inkoplaner-Plandaten',
        value: 'inkosync'
      })
    }

    this.$store.dispatch('benutzer/loadSettings')
  }
}
</script>
<style lang="scss">
#reports {
  .v-expansion-panel-header:hover {
    background: #fff !important;
  }

  .v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
    flex: none;
  }

  #bew-select {
    .v-expansion-panel-header {
      background: #fff !important;

      .v-input {
        width: 290px !important;
        overflow: hidden;
        display: block;
      }
    }


    .v-messages {
      display: none;
    }
  }
}
</style>
