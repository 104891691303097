import Vue from 'vue'
import httpClient from "@/utils/httpClient";
import {store} from "../../store";

const kundeState = Vue.observable({
    "superadminKunden": false
});

export const kundeGetters = {
    kunde: () => kundeState.kunde,
    superadminKunden: () => kundeState.superadminKunden
}

export const kundeMutations = {
    setSuperAdminKunden(kunden) {
        kundeState.superadminKunden = kunden;
    }
}

export const kundeActions = {

    async fetchSuperAdminKunden(){
        let res = await httpClient({
            url: process.env.VUE_APP_API_SHARED+'superadmin/get/kunden',
            method: 'GET'
        })

        if(res.type === 'success'){
            kundeMutations.setSuperAdminKunden(res.kunden)
        }
    },


    async setNewUserPerspective(newid){
        let res = await httpClient({
            url: process.env.VUE_APP_API_SHARED+'superadmin/set/newuserperspective',
            method: 'post',
            data: { 'newid': newid}
        })

        if(res.type === 'success'){
            httpClient.defaults.headers.common['Authorization'] = ''
            httpClient.defaults.headers.common['uid'] = ''

            sessionStorage.clear();
            localStorage.clear();
           window.location.href = '/login';
        }
    }
}

export default {
    kundeState,
    kundeGetters,
    kundeMutations,
    kundeActions
}