<template>
  <div id="superadmin">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="primary--text mb-0">
            Superadmin
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-card flat outlined class="px-8 py-8">
            <v-card-title>
              <h2>
                Ansicht ändern
              </h2>
            </v-card-title>
            <v-card-text style="height: 116px">
              <v-autocomplete
                  v-if="superadminKunden"
                  v-model="choosenSuperAdminKunde"
                  :items="superadminKunden"
                  :filter="customerFilter"
                  no-data-text="kein Kunde gefunden"
                  clearable
                  label="Kunden"
                  item-text="NameFirma"
                  item-value="AdressNrADR"
              >
                <template slot="item" slot-scope="data">
                  {{ data.item.AdressNrADR }} - {{ data.item.NameFirma }}
                </template>

              </v-autocomplete>
            </v-card-text>
            <v-card-actions>
              <v-btn class="mt-5" color="primary" depressed @click="beAnotherUser()">Kundenansicht wechseln</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="4">
          <v-card flat outlined class="px-8 py-8">
            <v-card-title>
              <h2>
                Benutzerübersicht
              </h2>
            </v-card-title>
            <v-card-text style="height: 120px">

            </v-card-text>
            <v-card-actions>
              <router-link :to="{ name: 'user-management' }">
                <v-btn class="mt-5" color="primary" depressed> Benutzerübersicht
                </v-btn>
              </router-link>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <!--<v-img  max-width="200"></v-img>-->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>

import {kundeGetters, kundeActions} from '../components/observables/kunde';

export default {
  name: 'Superadmin',
  data() {
    return {
      choosenSuperAdminKunde: false
    }
  },
  computed: {
    ...kundeGetters,
  },

  methods: {
    ...kundeActions,

    customerFilter(item, queryText, itemText) {
      const term = queryText.toString().toLowerCase();


      return item.AdressNrADR.toString().includes(term) || item.NameFirma.toLowerCase().includes(term)
    },

    beAnotherUser() {
      if (this.choosenSuperAdminKunde) {
        this.setNewUserPerspective(this.choosenSuperAdminKunde);
      }
    },
  },

  async created() {
    await this.fetchSuperAdminKunden();
  }

}
</script>

<style scoped>
h2 {
  color: #CE20A8;
}
</style>

