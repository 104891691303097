import Vue from 'vue'
import httpClient from "@/utils/httpClient";

const aerzteState = Vue.observable({
    loadingAerzte: false,
    aerzte: []
});


export const aerzteGetters = {
    aerzte: () => {
        return aerzteState.aerzte
    },
    byBewohnerId: (state) => (id) => aerzteState.aerzte?.find(an => an.id === +id),
}

export const aerzteMutations = {
    setAerzte(aerzte) {
        aerzteState.aerzte = aerzte;
    }
}

export const aerzteActions = {
    fetchAerzte(bewohnerId) {
        if (!this.loadingAerzte) {
            this.loadingAerzte = true
            httpClient({
                url: process.env.VUE_APP_API_SHARED + 'get/aerzte',
                method: 'get',
            })
                .then(response => {
                    if (response.type === "success") {
                        aerzteMutations.setAerzte(response.aerzte);
                    }
                })
                .finally(() => {
                    this.loadingAerzte = false
                })
        }
    },


    addArzt(arzt) {
        return new Promise((resolve, reject) => {
            httpClient({
                url: process.env.VUE_APP_API_SHARED + 'save/aerzte',
                method: 'POST',
                data: {
                    arzt: arzt
                }
            }).then((response) => {
                aerzteMutations.setAerzte(response.aerzte);
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    updateArzt(id, arzt) {
        return new Promise((resolve, reject) => {
            httpClient({
                url: process.env.VUE_APP_API_SHARED + 'update/aerzte/'+id,
                method: 'PUT',
                data: {
                    arzt: arzt
                }
            }).then((response) => {
                aerzteMutations.setAerzte(response.aerzte);
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    deleteArzt(id) {
        return new Promise((resolve, reject) => {
            httpClient({
                url: process.env.VUE_APP_API_SHARED +  'delete/aerzte/'+id,
                method: 'DELETE',
            }).then((response) => {
                aerzteMutations.setAerzte(response.aerzte);
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
}

export default {
    aerzteState: aerzteState,
    aerzteGetters,
    aerzteMutations,
    aerzteActions
}
