<template>
  <div class="direktabrechnung-data">
    <div class="data-row -header align-end">

      <div
          v-for="(col, index) in dataCols"
          :key="'h-col'+index"
          style="flex-shrink: 0; align-items: center; border-top-left-radius: 10px; border-top-right-radius: 10px;"
          :style="{ 'flex-basis': col.width, 'justify-content': col.text, 'text-align': col.text }"
          class="d-flex py-1"
          :class="(col.function && col.function.startsWith('total_'))? 'px-2 ' + (col.function === 'total_verrechenbar'? (cartHasBeenSent? 'gray' : 'beige') : (cartHasBeenSent? 'lightgray' : 'leightbeige')): ''"
      >
        <b>{{ col.title }}</b>
      </div>

      <div class="controls"></div>
    </div>

    <RecordEditableRow
        v-for="(record, i) in records"
        :key="'record'+i"
        :record="record"
        :columnConfig="dataCols"
        :cart="cart"
        :isKrankenkasse="true"
    />

    <div class="data-row -footer">
      <div
          v-for="(col, index) in dataCols"
          :key="'f-col'+index"
          style="flex-shrink: 0; align-items: center; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;"
          :style="{ 'flex-basis': col.width, 'justify-content': col.text }"
          class="d-flex py-1"
          :class="(col.function && col.function.startsWith('total_'))? 'px-2 ' + (col.function === 'total_verrechenbar'? (cartHasBeenSent? 'gray' : 'beige') : (cartHasBeenSent? 'lightgray' : 'leightbeige')): ''"
      >
        <b v-if="index===0" style="position: absolute; left: 0;">
          Total (gerundet)
        </b>
        <b v-else-if="col.function==='total_nicht_verrechenbar'">
          {{ totalNichtVerrechenbar }}
        </b>
        <b v-else-if="index===dataCols.length-1">
          {{ totalVerrechenbar }}
        </b>
      </div>

      <div class="controls"></div>
    </div>
    <div class="book">
      *Preis: pro Abrechnungseinheit inkl. MwSt und  <span v-if="$store.state.benutzer.settings">{{ $store.state.benutzer.settings.zuschlag }}%</span> Admin-Zuschlag
    </div>
  </div>
</template>
<script>
import {rowConfig} from "@/config/DirektabrechnungDetailConfig"
import RecordEditableRow from "@/components/direktabrechnung/RecordEditableRow.vue";
import {mapGetters} from "vuex";

export default {
  name: "BewohnerDetailListingKrankenkasse",
  components: {RecordEditableRow},
  props: {
    cart: {
      type: Object,
      required: true
    },
  },

  computed: {
    ...mapGetters({
      pauschalen: 'direktabrechnung/bewohnerPauschalenStatus'
    }),
    dataCols() {
      return rowConfig['krankenkasse']
    },
    records() {
      return this.cart['cart_krankenkasse'] ?? []
    },
    cartHasBeenSent() {
      return this.cart.status !== 'prepared'
    },

    totalVerrechenbar() {
      var total =  this.records.reduce((a, r) => {
        if (this.pauschalen && this.pauschalen.filter(p => !p.active && p.MiGeL_Nummern.includes(r.migelnr)).length > 0) {
          return a
        } else {
          return a + parseFloat((parseFloat(r.verrechenbar) * r.menge).toFixed(2));
        }
      }, 0)

      return (Math.round(total * 20) / 20).toFixed(2)
    },

    totalNichtVerrechenbar() {
      var total = this.records.reduce((a, r) => {
        if (this.pauschalen && this.pauschalen.filter(p => !p.active && p.MiGeL_Nummern.includes(r.migelnr)).length > 0) {
          return a + (parseFloat(r.verrechenbar) * r.menge);
        } else {
          return a + (parseFloat(r.nicht_verrechenbar) * r.menge);
        }
      }, 0)

      this.cart['cart_bewohner'].filter(r => r.jahrespauschale).forEach(r => {
        total += parseFloat(r.verrechenbar) * r.menge;
      })

      return (Math.round(total * 100) / 100).toFixed(2)
    }
  },
  methods: {}
}
</script>