<template>
  <v-dialog
      v-model="show"
      persistent
      width="600"
      content-class="confirm-dialog"
  >
    <template v-slot:activator="{}">
      <span @click="open" style="cursor: pointer">
        <slot name="button"/>
      </span>
    </template>

    <v-card
        class="main"
    >
      <v-alert :type="type" v-if="type">{{ title }}</v-alert>
      <v-card-title v-else class="pl-0 text-primary text-h5">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <slot/>
      </v-card-text>
      <v-card-actions class="justify-end text-right">
        <slot name="cancel" :cancel="cancel">
          <v-btn color="secondary" style="color: #000" depressed @click="cancel">Abbrechen</v-btn>
        </slot>

        <slot v-if="optionButton" name="optionButton" :optionConfirm="optionConfirm">
          <v-btn color="secondary" depressed @click="optionConfirm">option</v-btn>
        </slot>

        <slot name="confirm" :confirm="confirm">
          <v-btn color="primary" depressed @click="confirm">Ok</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.v-alert {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.v-card-text {
  padding-left: 0 !important;
}

.v-card-actions {
  padding: 0;
}
</style>
<script>

export default {
  name: "ConfirmDialog",
  emits: ['cancel', 'confirm', 'optionConfirm'],
  props: {
    title: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: null
    },
    optionButton: {
      type: Boolean,
      default: false
    },
    preventOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false,

    }
  },
  methods: {
    open() {
      if(!this.preventOpen) {
        this.show = true
      }
    },

    cancel() {
      this.show = false
      this.$emit('cancel')
    },

    confirm() {
      this.show = false
      this.$emit('confirm')
    },

    optionConfirm() {
      this.show = false
      this.$emit('optionConfirm')
    }
  }
}

</script>

<style lang="scss">

.confirm-dialog {
  border-radius: 10px;

  .main {
    padding: 30px 40px 30px 40px;
  }

  .v-btn--rounded {
    border-radius: 10px;
  }

  .v-card__text {
    padding-top: 1rem !important;
    padding-left: 0 !important;
    font-size: 16px !important;
  }

  .v-card__actions {
    padding: 0 !important;
    padding-top: 10px !important;
  }
}

</style>
