<template>
  <v-dialog v-model="dialog" width="780" class="print-selection" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-hover v-slot="{ hover }" open-delay="0">
          <div class="action-icon cursor-pointer text-center align-center" @click="resetBewohner">
            <v-img
                :src="hover ? require('@/assets/img/ico/drucken-hover.svg') : require('@/assets/img/ico/drucken.svg')"
                alt="" max-width="35" class="cursor-pointer"></v-img>
            <div class="pt-0 caption text-center print-caption">
              {{ label }}
            </div>
          </div>
        </v-hover>
      </div>
    </template>
    <v-card class="py-4 px-2">
      <v-card-title>
        <h2 class="text mb-8 mt-4" style="color: #000 !important;"> {{ label }} drucken</h2>
        <div class="close-btn" @click="dialog=false">
          <img alt="" :src="require('@/assets/img/ico/schliessen.svg')" width="25"/>
        </div>
      </v-card-title>
      <v-card-text style="height: 500px;">
        <div class="d-flex">
          <div style="width: 350px;" class="mb-8">
            <v-text-field v-model="search" placeholder="Bewohner durchsuchen" hide-details dense>
              <template v-slot:append>
                <img  height="20" :src="require('@/assets/img/ico2023/suche.svg')"/>
              </template>
            </v-text-field>
          </div>
          <div v-if="sortable" class="ml-8" style="width: 350px;">
            <v-select
                v-model="sortBy"
                :items="sortOptions"
                item-text="text"
                item-value="value"
                dense
                hide-details></v-select>
          </div>
        </div>
        <div class="d-flex">
          <div class="mr-4" style="width: 50px;">
            <v-checkbox style="margin-top: 10px" class="mb-3" hide-details v-model="selectAll"></v-checkbox>
          </div>
          <div v-for="col in columns" :key="'l-'+col.value" class="caption pt-4" :style="'width:'+col.width">
            {{ col.label }}
          </div>
        </div>
        <div class="d-flex b-top" v-for="c in filteredBewohner"
             :key="'b-'+c.bewohner.bewohnerID">
          <div class="mr-4" style="width: 50px;">
            <v-checkbox style="margin-top: 10px" class="mb-3" hide-details v-model="printBewohnerIDs"
                        :value="c.bewohner.bewohnerID"></v-checkbox>
          </div>
          <div class="pt-4" v-for="col in columns" :key="'bc-'+col.value" :style="'width:'+col.width">
            {{ c.bewohner[col.value] }}
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="date-select-actions">
        <v-spacer></v-spacer>
        <v-btn color="danger" depressed @click="dialog = false">Abbrechen</v-btn>
        <v-btn color="primary" depressed @click="requestPrint" :disabled="printBewohnerIDs.length === 0">Drucken</v-btn>
        <!--EXCEL IF TRUE-->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {print} from "@/composables/print";
import tr from "vue2-datepicker/locale/es/tr";

export default {
  name: "PrintDialogDirektabrechnung",
  props: {
    label: {
      type: String,
      required: true
    },
    module: {
      type: String,
      required: true
    },
    data: {
      required: false
    },
    orientation: {
      type: String,
      required: false
    },
    callFunction: {
      required: false,
      default: false
    },
    callParams: {
      required: false,
      default: false
    },
    callService: {
      required: false,
      default: false
    },
    sortable: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      search: '',
      dialog: false,
      sortOptions:[
        {text: 'Sortierung in Druckversion', value: false},
        {text: 'Name A-Z', value: 'name_asc'},
        {text: 'Name Z-A', value: 'name_desc'},
        {text: 'Zimmer absteigend', value: 'zimmer_asc'},
        {text: 'Zimmer aufsteigend', value: 'zimmer_desc'},
        {text: 'Bewohner ID absteigend', value: 'externalID_asc'},
        {text: 'Bewohner ID aufsteigend', value: 'externalID_desc'},
        {text: 'Vorname A-Z', value: 'vorname_asc'},
        {text: 'Vorname Z-A', value: 'vorname_desc'},
      ],
      printBewohnerIDs: [],
      sortBy: false,
      columns: [
        {
          label: 'Vorname',
          value: 'vorname',
          width: '190px'
        },
        {
          label: 'Name',
          value: 'name',
          width: '190px'
        },
        {
          label: 'Zimmer',
          value: 'zimmer',
          width: '100px'
        },
        {
          label: 'Bewohner ID',
          value: 'externalID',
          width: '100px'
        }
      ]
    }
  },

  methods: {
    async requestPrint() {
      this.dialog = true;
      this.callParams.bewohnerIDs = this.printBewohnerIDs;
      this.callParams.sortBy = this.sortBy;
      await print(this.module, this.data, this.orientation, this.callFunction, this.callService, this.callParams, this.sortBy);
      this.dialog = false;
    },

    resetBewohner(){
      this.printBewohnerIDs = this.$store.state.direktabrechnung.carts.map((c) => c.bewohner.bewohnerID);
    }
  },

  computed: {
    selectAll: {
      get() {
        return this.printBewohnerIDs.length === this.filteredBewohner.length;
      },
      set(value) {
        if (value) {
          this.printBewohnerIDs = this.filteredBewohner.map((c) => c.bewohner.bewohnerID);
        } else {
          this.printBewohnerIDs = [];
        }
      }
    },

    filteredBewohner() {
      return this.$store.state.direktabrechnung.carts.filter((c) => {
        try {
          return c.bewohner.name.toLowerCase().includes(this.search.toLowerCase()) ||
              c.bewohner.vorname.toLowerCase().includes(this.search.toLowerCase()) ||
              (c.bewohner.zimmer != null && c.bewohner.zimmer.toLowerCase().includes(this.search.toLowerCase())) ||
              (c.bewohner.externalID != null && c.bewohner.externalID.toLowerCase().includes(this.search.toLowerCase()))

        } catch (e) {
          return true;
        }
      })
    }
  },

  mounted() {
    this.printBewohnerIDs = this.$store.state.direktabrechnung.carts.map((c) => c.bewohner.bewohnerID);
  }
}
</script>
<style lang="scss">
.print-caption {
  font-size: 14px !important;
  max-width: none !important;
}
 .close-btn {
   position: absolute;
   right: 16px;
   top: 16px;
   cursor: pointer;
 }
</style>