<template>
  <div id="orderpreview">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="primary--text mb-8">
            Bestellung
          </h1>
        </v-col>
      </v-row>
      <v-divider class="mb-12"></v-divider>
      <v-row class="mb-3">
        <v-col cols="4 ml-0">

          <div class="mb-5">
            <b>Verbrauchszeitraum</b><br>
            Bitte wählen Sie den Verbrauchszeitraum zur Berechnung ihres Bestellbedarfs.
          </div>
          <v-date-picker
              class="ml-0 pl-0"
              style="margin-top: -13px;"
              v-model="dates"
              :value="dates[0]"
              type="month"
              width="270"
              range
              first-day-of-week="1"
              locale="ch-de"
              :show-week="false"
              flat
              color="primary"
              selected-items-text="Zeitraum"
              :show-current="false"
          ></v-date-picker>

        </v-col>
        <v-col cols="5">
          <div class="mb-4">
            <b>Verbrauch-Wohneinheit</b><br>
            Bitte wählen Sie die Wohneinheit zur Berechnung<br>ihres Bestellbedarfs.
          </div>
          <v-radio-group
              v-if="wh"
              :key="'whcheck_'+rerender"
              v-model="checkedWh"
          >
            <div class="d-flex w-full" style="flex: 0 0 100%;">
              <div style="flex:1" v-for="(chunk, i) in wohneinheitenSelect" :key="'chunk' + i" class="mr-10" :class="(i === wohneinheitenSelect.length - 1) ? 'flex-grow' : ''">
                <div v-for="wh in chunk" >
                  <v-divider class="mb-4 mt-5" v-if="wh.id === 'all' && chunk.length > 1"></v-divider>
                  <v-tooltip top v-if="wh.id === 'all'">
                    <template v-slot:activator="{ on, attrs }">
                        <v-radio
                            v-bind="attrs" v-on="on"
                            :label="wh.name"
                            :value="wh.id"
                            item-text="name"
                            item-value="id"
                            class="mb-3"
                        />
                    </template>
                    <span>Verbauch aller Wohneinheiten zusammengefasst</span>
                  </v-tooltip>
                  <v-radio
                      v-else
                      :label="wh.name"
                      :value="wh.id"
                      item-text="name"
                      item-value="id"
                      class="mb-3"
                  />
                </div>
              </div>
            </div>
          </v-radio-group>
        </v-col>

        <v-col cols="3">
          <b>Bedarfszeitraum</b><br>
          Bitte geben Sie an, für wie viele Wochen der Bedarf berechnet werden
          soll.
          <v-radio-group
              v-if="calcTimeSpans"
              v-model="calcTimeSpan"
          >
            <div v-for="(c, i) in calcTimeSpans" :key="'cts' + i" class="mb-3">
              <v-radio
                  :label="c.name"
                  :value="c.id"
                  item-text="name"
                  item-value="id"
              />
            </div>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="mt-0">
        <v-col cols="12" class="pl-2 pt-0 mt-0" style="margin-top: -5px;">
          <v-row v-if="orderPreview && orderPreview.length > 0 &&  orderPreview[0].data && orderPreview[0].data.length > 0" v-for="(wh,index) in orderPreview" :key="'po-'+wh.whID">
            <v-col cols="12" v-if="wh.data && wh.data.length > 0">

              <div  style="position: sticky !important; top: 100px; background: #fff;z-index:4;padding: 20px 0">
                <h4 class="text-h6 font-weight-light primary--text mb-0 pl-1">{{ wh.name }} |

                  {{ moment(dateRange.start).locale('de').format('MMMM YYYY') }} <span v-if="dateRange.start != dateRange.end">- {{
                      moment(dateRange.end).locale('de').format('MMMM YYYY') }} </span> | {{ calcTimeString[0].name }}
                </h4>
              </div>

              <table cellpadding="0" cellspacing="0"  id="recommend-table" border="0">

                <tr >
                  <th></th>
                  <th></th>
                  <th valign="top">
                    zuletzt Bestellt am
                  </th>

                  <th valign="top">Verbrauch {{ moment(dateRange.start).locale('de').format('MMMM').substring(0, 3) }} <span v-if="dateRange.start != dateRange.end">- {{
                      moment(dateRange.end).locale('de').format('MMMM').substring(0, 3)
                    }}</span></th>
                  <th valign="top">Bedarf für {{ calcTimeString[0].name }}</th>
                  <th valign="top">Vorschlag <br>(Einkaufsverpackung)</th>
                  <th valign="top">Rest</th>
                  <th valign="top">Bestellmenge</th>
                  <th valign="top" style="text-align: right">Preis</th>
                </tr>
                <tr v-for="(p,index2) in wh.data" :key="'da-'+p.artnr">
                  <td style="width:80px;">
                   <span v-if="bilder[p.artnr] ">
                      <v-img :src="bilder[p.artnr]['Z_URL'] +'?width=80'" height="50"
                             contain></v-img>
                      </span>
                    <span v-else-if="p.bild">
                      <v-img :src="p.bild" alt="" width="45"></v-img>
                  </span>
                        <span v-else>
                    <v-img :src="require('@/assets/img/ico/sortimentsverwaltung-aktiv.svg')" alt="" width="45"></v-img>
                  </span>
                  </td>
                  <td style="width:280px;">
                    <span v-if="p.artnr.indexOf('SKU-') != -1" class="caption text--primary">[nicht bestellbar]</span>
                    <product-caption :artnr="p.showID" :name="p.name" :verpackung="p.verpackung"
                                     :ownartno="false"></product-caption>
                  </td>
                  <td>
                    <span class="cursor-pointer op-hover text-decoration-underline" @click="showSalesHistory(p)"  v-if="p.lastbuy">
                      {{  moment(p.lastbuy.Datum ).locale('de').format('DD.MM.YYYY')}}
                    </span>
                  </td>
                  <td>
                    {{ p.verbrauchMt }} <span class="caption">{{ p.zaehleinheit }}</span>
                  </td>
                  <td>
                    {{ p.count }} <span class="caption">{{ p.zaehleinheit }}</span>
                  </td>
                  <td>
                    {{ p.recommendation }} <span class="caption">{{ p.verpackung }}</span>
                  </td>
                  <td>
                  <span
                      :class="(p.angepasstemenge * p.MultiplikatorDefaultZuVerkaufseinheit - p.count) >= 0 ? 'success--text' : 'error--text'">
                      {{ (p.angepasstemenge * p.MultiplikatorDefaultZuVerkaufseinheit - p.count).toFixed(2) }} <span class="caption">{{ p.zaehleinheit }}</span>
                  </span>
                  </td>
                  <td  style="vertical-align: middle;">
                    <v-text-field dense light autocomplete="off" min="0" oninput="validity.valid||(value='');"
                                  @input="changedInput=true"
                                  max="1000" v-model="p.angepasstemenge" style="margin-top: 13px;" class="body-2  pt-0" type="number"></v-text-field>
                  </td>
                  <td  style="text-align: right">
                    CHF <span>{{ (p.preis * p.angepasstemenge).toFixed(2) }}</span>
                  </td>
                </tr>
              <tr>
                <td colspan="9">
                  <v-divider light style="width:100%"></v-divider>
                </td>
              </tr>
                <tr>
                  <td colspan="5">
                  </td>
                  <td colspan="3">
                    <b>Total</b>
                  </td>
                  <td style="text-align: right">
                    <b> CHF {{ getSubtotoal(wh.data) }}</b>
                  </td>
                </tr>

                <tr>
                  <td colspan="5">
                  </td>
                  <td colspan="3">
                   Mindermengenzuschlag
                  </td>
                  <td style="text-align: right">
                    CHF {{ getMindermengenZuschlag(getSubtotoal(wh.data)) }}
                  </td>
                </tr>
                <tr>
                  <td colspan="5">
                  </td>
                  <td colspan="3">
                    MwSt.
                  </td>
                  <td style="text-align: right">
                    CHF {{ getMwst(getSubtotoal(wh.data)) }}
                  </td>
                </tr>
                <tr>
                  <td colspan="5">
                  </td>
                  <td colspan="3">
                    <b>Bestellsumme</b>
                  </td>
                  <td nowrap="nowrap" style="text-align: right">
                    <b> CHF {{ getTotal(getSubtotoal(wh.data)) }}</b>
                  </td>
                </tr>
                <tr>
                  <td colspan="10">
                    <v-divider light style="width:100%;"></v-divider>
                  </td>
                </tr>
                <tr>
                  <td colspan="10" style="text-align: right">
                      <v-btn @click="prepareOrder(wh)" depressed color="primary" class="ml-2">
                        Kostenpflichtig bestellen
                      </v-btn>
                  </td>
                </tr>
                <tr>
                  <td colspan="9">
                    <v-divider light style="width:100%"></v-divider>
                  </td>
                </tr>
              </table>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col>
              Kein Produkte in diesem Zeitraum verbraucht.
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <br><br>
    </v-container>
    <v-dialog v-if="salesHistoryProd" v-model="salesHistoryModal" max-width="780"  style="overflow-y:auto;max-height:900px !important">
      <product-saleshistory :startdate="dateRange.start"  @closeSalesHistoryModal="closeSalesHistoryModal"  :key="'history-'+salesHistoryProd.artnr+'-'+dateRange.start+'-'+dateRange.end" :prod="salesHistoryProd"></product-saleshistory>
    </v-dialog>
    <v-dialog v-if="orderWH" v-model="orderWindowModal" max-width="780" style="overflow-y:auto;max-height:900px !important">
      <send-order :order="orderWH"
                  :mindermengenzuschlag="getMindermengenZuschlag(getSubtotoal(orderWH.data))"
                  :total="getTotal(getSubtotoal(orderWH.data))"
                  :mwst="getMwst(getSubtotoal(orderWH.data))"
                  :subtotal="getSubtotoal(orderWH.data)"
                  @closeOrderModal="closeOrderModal">

      </send-order>
    </v-dialog>
  </div>
</template>
<script>
import httpClient from "../utils/httpClient";
import ProductCaption from "../components/products/ProductCaption";
import SendOrder from "../components/order/SendOrder";
import ProductSaleshistory from "../components/products/ProductSaleshistory";
import moment from 'moment'

export default {
  name: 'Bestellvorschlag',
  components: {ProductCaption,ProductSaleshistory,SendOrder},
  data() {
    return {
      orderWindowModal: false,
      changedInput: false,
      preventUpdate: false,
      wohneinheitenChunkSize: 7,
      mindermengenPauschale: 0,
      rerender: 0,
      mindermengenSchwelle: 0,
      moment: moment,
      salesHistoryProd: false,
      salesHistoryModal: false,
      dates: [],
      orderPreview: [],
      checkedWh: 'all',
      orderWH: false,
      wh: [],
      calcTimeSpan: 4,
      timespanDays: 0,
      diffDays: 0,
      calcTimeSpans: [
        {
          'name': '1 Monat',
          'id': 4
        }, {
          'name': '2 Wochen',
          'id': 2
        },
        {
          'name': '1 Woche',
          'id': 1
        }
      ]
    }
  },

  computed: {
    bilder: {
      get() {
        return this.$store.state['bilder'].produktbilder
      }
    },

    calcTimeString() {

      var self = this;
      return this.calcTimeSpans.filter(function (el) {
        return el.id == self.calcTimeSpan;
      })

    },


    wohneinheitenSelect() {
      let wohneinheiten = this.wh.map(function (el) {
        return {
          'name': el.name,
          'id': el.id
        }
      })

      let chunks = []

      for (let i = 0; i < wohneinheiten.length; i += this.wohneinheitenChunkSize) {
        const chunk = wohneinheiten.slice(i, i + this.wohneinheitenChunkSize);
        chunks.push(chunk);
      }

      return chunks
    },


    dateRange() {
      if (this.dates[0] && this.dates[1]) {

        let startdate = this.dates[0] < this.dates[1] ? this.dates[0] : this.dates[1]

        let enddate = this.dates[0] > this.dates[1] ? this.dates[0] : this.dates[1]

        return {
          'start': startdate,
          'end': enddate
        }

      } else {
        return {
          'start': this.dates[0],
          'end': this.dates[0]
        }
      }
    },
  },


  beforeRouteLeave (to, from, next){

    if(this.changedInput){
      this.$confirm('Sie haben Änderungen am Warenkorb vorgenommen. Sollten Sie die Seite verlassen, werden Änderungen am aktuellen Warenkorb nicht gespeichert.<br><br>Trotzdem fortfahren?', {
        title: 'Achtung',
        buttonTrueText: 'OK',
        buttonFalseText: 'Abbrechen'
      }).then(async confirmed => {
        if (confirmed) {
          next();
        }else{
          return false
        }
      })
    }else{
      next();
    }
  },

  methods: {
    prepareOrder(wh){
      this.orderWH = wh;
      this.orderWindowModal = true;
    },

    closeOrderModal(){
      this.orderWindowModal = false
    },

    getSubtotoal(products){
      var total = 0;
      for(var prod in products) {
          total += products[prod].preis * products[prod].angepasstemenge;
      }

      return total.toFixed(2);
    },

    getMwst(subtotal){
      return (subtotal * (this.$store.state['benutzer'].info.tax.mwst/100)).toFixed(2);
    },

    getTotal(subtotal){
      let total = (parseFloat(subtotal) + parseFloat(this.getMwst(subtotal))  + parseFloat(this.getMindermengenZuschlag(subtotal))).toFixed(2)
      return total;
    },

    getMindermengenZuschlag(subtotal){

      if(subtotal < this.mindermengenSchwelle){
        return this.mindermengenPauschale.toFixed(2);
      }else{
        return 0.00;
      }
    },

    async getPreview() {
      const res = await httpClient({
        url: process.env.VUE_APP_API_CHECKLIST + 'get/order-preview',
        method: 'post',
        data: {
          'wh': this.checkedWh,
          'dateRange': this.dateRange,
          timeSpan: this.calcTimeSpan
        }
      })

      if (res.type == 'success') {
        this.diffDays = res.diffdays;
        this.timespanDays = res.timespanDays;
        this.orderPreview = res.orderPreview;
      }
    },

    showSalesHistory(p){
      this.salesHistoryProd = p;
      this.salesHistoryModal = true;
    },

    closeSalesHistoryModal(){
      this.salesHistoryModal = false;
    },


    checkModelChange(value,before,modelKey){
      if(this.changedInput){
        this.$nextTick(()=>{
          this.preventUpdate = true;
          this.changedInput = false;
          this[modelKey] = before;
          this.rerender++;
        })

        this.$confirm('Sie haben Änderungen am Warenkorb vorgenommen. Wenn Sie die Einstellungen ändern, werden Änderungen am aktuellen Warenkorb nicht gespeichert.<br><br>Trotzdem fortfahren?', {
          title: 'Achtung',
          buttonTrueText: 'OK',
          buttonFalseText: 'Abbrechen'
        }).then(async confirmed => {
          if (confirmed) {
            this[modelKey] = value;
            this.rerender++;
            this.preventUpdate = false;
            this.changedInput = false;
            this.getPreview();
          }else{
            this.changedInput = true;
            return false
          }
        })
      }else if(!this.preventUpdate){
        this.getPreview();
      }
    },
  },

  watch:{
    checkedWh(value,before)
    {
      this.checkModelChange(value,before,'checkedWh')
    },
    calcTimeSpan(value,before)
    {
      this.checkModelChange(value,before,'calcTimeSpan')
    },
    dates(value,before)
    {
      this.checkModelChange(value,before,'dates')
    }
  },

  async mounted() {
    const res = await httpClient({
      url: process.env.VUE_APP_API_CHECKLIST + 'get/order-preview',
      method: 'post',
      data: {'wh': 'init', 'dateRange': false, timeSpan: this.calcTimeSpan}
    })

    if (res.type == 'success') {
      this.wh = res.wh;
      this.checkedWh = res.wh[0].id;
      this.diffDays = res.diffdays;
      this.timespanDays = res.timespanDays;
      this.orderPreview = res.orderPreview;
      this.dates[0] = res.dateRange.start;
      this.dates[1] = res.dateRange.end;
      this.mindermengenPauschale = res.mindermengenPauschale;
      this.mindermengenSchwelle = res.mindermengenSchwelle;
    }
  }
}
</script>
<style lang="scss">
#orderpreview .v-picker__title {
  display: none !important;
}

#orderpreview {
  #recommend-table{
    td,th{
      padding: 3px 15px 3px 0;
      text-align: left;
    }

    td{
      padding-bottom: 20px;
    }

    th{
      font-weight: normal !important;
      font-size: 13px;
    }
  }

  th {
    position: -webkit-sticky;
    position: sticky;
    top: 120px;
    z-index: 90;
  }
}

</style>