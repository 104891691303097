<template>
  <svg  width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.806 27.52">
    <g id="shop-tool">
      <g id="Gruppe_8304" data-name="Gruppe 8304" transform="translate(-1359.828 -623.544)">
        <path id="Vereinigungsmenge_5" fill="currentColor" data-name="Vereinigungsmenge 5" d="M-253.081-1315.156a13.3,13.3,0,0,1-3.919-9.463,13.3,13.3,0,0,1,3.919-9.463,13.3,13.3,0,0,1,9.463-3.92,13.3,13.3,0,0,1,9.463,3.92,13.294,13.294,0,0,1,3.919,9.463,13.291,13.291,0,0,1-3.919,9.463,13.291,13.291,0,0,1-9.463,3.92A13.294,13.294,0,0,1-253.081-1315.156Zm.99-17.935a11.906,11.906,0,0,0-3.509,8.473,11.9,11.9,0,0,0,3.509,8.473,11.9,11.9,0,0,0,8.473,3.51,11.9,11.9,0,0,0,8.473-3.51,11.9,11.9,0,0,0,3.51-8.473,11.9,11.9,0,0,0-3.51-8.473,11.9,11.9,0,0,0-8.473-3.509A11.906,11.906,0,0,0-252.091-1333.091Zm3.931,13.432-1.805-10.69h-1.981v-1.4h3.164l1.805,10.691h8.851v1.4Z" transform="translate(1616.828 1961.545)"/>
        <g id="Gruppe_3359" data-name="Gruppe 3359" transform="translate(1369.591 642.899)">
          <path id="Vereinigungsmenge_7" fill="currentColor" data-name="Vereinigungsmenge 7" d="M-1626.592-1979.775a1.126,1.126,0,0,1,1.123-1.124,1.125,1.125,0,0,1,1.123,1.123,1.124,1.124,0,0,1-1.122,1.123A1.126,1.126,0,0,1-1626.592-1979.775Z" transform="translate(1626.491 1980.799)"/>
          <path id="Vereinigungsmenge_6" fill="currentColor" data-name="Vereinigungsmenge 6" d="M-1626.592-1979.776a1.125,1.125,0,0,1,1.123-1.123,1.125,1.125,0,0,1,1.123,1.123,1.124,1.124,0,0,1-1.123,1.123A1.125,1.125,0,0,1-1626.592-1979.776Z" transform="translate(1632.87 1980.799)"/>
        </g>
        <path id="Pfad_1376" color="currentColor" data-name="Pfad 1376" d="M407.834,605.236" transform="translate(970.768 35.951)"/>
        <path id="Pfad_1375_-_Kontur" fill="currentColor" data-name="Pfad 1375 - Kontur" d="M402.128,604.564h-9.969v-1.2h8.988l.911-4.5H390.766v-1.2h12.76Z" transform="translate(976.944 34.275)"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "LogoShop"
}
</script>

<style scoped>
svg {
  color: #000000;
}
</style>