export const system = {
    namespaced: true,

    state: {
        loadingQueue: []
    },

    getters: {
        isLoading: (state) => {
            return state.loadingQueue.length > 0
        },

        isElementLoading: (state) => (id) => {
            return state.loadingQueue.includes('id')
        },

        getLoadingQueue: (state) => {
            return state.loadingQueue
        }
    },

    mutations: {
        addToLoadingQueue(state, key) {
            if(state.loadingQueue.includes(key))
                return

            state.loadingQueue.push(key)
        },

        removeFromLoadingQueue(state, key) {
            let pos = state.loadingQueue.findIndex(k => k === key)

            if (pos > -1) {
                state.loadingQueue.splice(pos, 1)
            }
        }
    }
}
