import httpClient from "./httpClient";
import {store} from "../store/index";
import {benutzer} from "../store/modules/benutzer";

const benutzerScope = `benutzer`;
const authScope = `auth`;

const REST_ENDPOINT = process.env.VUE_APP_API_BASEURL + 'login'
const DATAPROTECTION_ENDPOINT = process.env.VUE_APP_API_BASEURL + 'dataprotection'

const AUTH_TOKEN_KEY = 'authToken';
const AUTH_UID_KEY = 'uid';

export async function acceptDataProtection(email) {

    return httpClient({
        url: `${DATAPROTECTION_ENDPOINT}`,
        method: 'POST',
        data: {
            tool: 'checklist',
            email: email
        }
    });
}

export async function loginUser(email, password) {

    try {
        let res = await httpClient({
            url: `${REST_ENDPOINT}`,
            method: 'POST',
            data: {
                tool: 'checklist',
                email: email,
                password: password,
            }
        })

        if (res.type === "success") {
            store.dispatch(`${benutzerScope}/setID`, res.uid);
            store.dispatch(`${benutzerScope}/setAuthToken`, res.token);
            store.dispatch(`${benutzerScope}/setInfo`, res.info);
            setAuthToken({token: res.token, uid: res.uid});
            store.dispatch(`${benutzerScope}/loadSettings`);
        }

        return res;

    } catch (err) {
        clearAuthToken();
        return false
    }
}

export async function logoutUser() {

    await httpClient({
        url: process.env.VUE_APP_API_BASEURL + 'logout',
        method: 'GET'
    })

    sessionStorage.clear();
    localStorage.clear();
    clearAuthToken()
}

export function setAuthToken(auth) {

    httpClient.defaults.headers.common['Authorization'] = `Bearer ${auth.token}`
    httpClient.defaults.headers.common['uid'] = `${auth.uid}`
    sessionStorage.setItem(AUTH_TOKEN_KEY, auth.token)
    sessionStorage.setItem(AUTH_UID_KEY, auth.uid)
}

export function getAuthToken() {
    return sessionStorage.getItem(AUTH_TOKEN_KEY)
}

export function clearAuthToken() {
    httpClient.defaults.headers.common['Authorization'] = ''
    httpClient.defaults.headers.common['uid'] = ''

    store.dispatch(`${benutzerScope}/setID`, false);
    store.dispatch(`${benutzerScope}/setAuthToken`, false);
    store.dispatch(`${benutzerScope}/setInfo`, false);

    sessionStorage.removeItem(AUTH_TOKEN_KEY)
    sessionStorage.removeItem(AUTH_UID_KEY)
}

export function isLoggedIn() {
    let authToken = getAuthToken()
    return !!authToken
}


/*
export function loginUser(email, password) {

    return new Promise(async (resolve, reject) => {
        try {

            axios.get(process.env.VUE_APP_API_BASEURL + 'sanctum/csrf-cookie').then(async response => {

                let res = await httpClient({
                    url: `${REST_ENDPOINT}`,
                    method: 'POST',
                    data: {
                        tool: 'checklist',
                        email: email,
                        password: password,
                    }
                })

                if(res.type == 'success'){
                    store.dispatch(`${benutzerScope}/setID`,res.uid);

                    resolve()
                }else{
                    reject()
                }
            });

        }
        catch (err) {
            //console.error('Caught an error during login:', err)
            reject(err)
        }
    })

}

export function logoutUser() {

    store.dispatch(`${benutzerScope}/setID`,0);
    store.dispatch(`${actionScope}/hide`);
    httpClient({
        url: process.env.VUE_APP_API_BASEURL+'logout',
        method: 'GET'
    })
}
*/
