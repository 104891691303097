import Vue from 'vue'
import httpClient from "@/utils/httpClient";
import moment from "moment";

const kostengutspracheState = Vue.observable({
    loading: true,
    bewohnerId: null,
    bewohnerKostengutspracheHistory: null,
})

export const kostengutspracheGetters = {
    bewohnerId: () => kostengutspracheState.bewohnerId,

    bewohnerKostengutspracheHistory: () => kostengutspracheState.bewohnerKostengutspracheHistory,
}

export const kostengutspracheMutations = {
    setBewohnerId(bewohnerId) {
        kostengutspracheState.bewohnerId = bewohnerId
    },

    setBewohnerKostengutspracheHistory(bewohnerKostengutspracheHistory) {
        kostengutspracheState.bewohnerKostengutspracheHistory = bewohnerKostengutspracheHistory
    },

    removeBewohnerKostengutspracheHistory(id) {
        let index = this.bewohnerKostengutspracheHistory.findIndex(i => i.id === id)
        if (index > -1) {
            kostengutspracheState.bewohnerKostengutspracheHistory.splice(index, 1)
        }
    },

    addBewohnerKostengutspracheHistory(kostengutsprache) {
        kostengutspracheState.bewohnerKostengutspracheHistory.push(kostengutsprache)

        kostengutspracheState.bewohnerKostengutspracheHistory.sort(function (a, b) {
            return moment.unix(a.start) - moment.unix(b.start);
        })
    }
}

export const kostengutspracheActions = {
    loadHistoryByBewohnerId(bewohnerId) {
        // reset history
        this.loading = true;
        kostengutspracheMutations.setBewohnerKostengutspracheHistory(null);
        kostengutspracheMutations.bewohnerId = bewohnerId;

        httpClient({
            url: process.env.VUE_APP_API_SHARED + 'get/kostengutsprache/' + bewohnerId,
            method: 'GET'
        }).then((response) => {
            kostengutspracheMutations.setBewohnerKostengutspracheHistory(response.history);
            this.loading = false;
        })
    },

    add(kostengutsprache) {
        return new Promise((resolve, reject) => {
            httpClient({
                url: process.env.VUE_APP_API_SHARED + 'save/kostengutsprache/' + this.bewohnerId,
                method: 'POST',
                data: {
                    kostengutsprache: kostengutsprache
                }
            }).then((response) => {
                kostengutspracheMutations.setBewohnerKostengutspracheHistory(response.history);
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    delete(id) {
        httpClient({
            url: process.env.VUE_APP_API_SHARED + 'kostengutsprache/' + this.bewohnerId + '/' + id,
            method: 'DELETE',
        }).then((response) => {
            kostengutspracheMutations.setBewohnerKostengutspracheHistory(response.history);
        })
    }
}
