<template>
  <div style="position:relative" id="ordermodal">
    <div class="op-hover" style="position:absolute;right: 10px; top: 10px;z-index:999">
      <v-icon x-large color="primary" @click="$emit('closeOrderModal');showSuccess=false;">
        mdi-close
      </v-icon>
    </div>
    <v-card flat class="px-4 pb-16">
      <v-card-title class="py-8 pb-0 d-block">
        <span v-if="!showSuccess">
          Bestellung aufgeben
        </span>
      </v-card-title>
      <v-card-text class="body-1 mt-6" v-if="!showSuccess">
        <v-row>
          <v-col cols="7">
            <b class="font-weight-bold">Lieferadresse</b>
            <v-select
                v-model="activeLiID"
                :items="liAdr"
                full-width
                @change="setLi()"
                small-chips
                dense
                :single-line="true"
                :item-value="'liID'"
            >
              <template slot="selection" slot-scope="data">
                <div>
                 Lieferadresse {{ (data.item.no+1) }}
                </div>
              </template>
              <template slot="item" slot-scope="data">
                <div>
                  Lieferadresse {{ (data.item.no+1) }}
                </div>
              </template>
            </v-select>
            <div class="mt-4">

              <v-text-field class="my-0 py-0" label="Firma" v-model="chosenLi.NameFirma"></v-text-field>
              <v-text-field class="my-0 py-0" label="Adresszeile1" v-model="chosenLi.Adresszeile1"></v-text-field>
              <v-text-field class="my-0 py-0" label="Adresszeile2" v-model="chosenLi.Adresszeile2"></v-text-field>
              <v-text-field class="my-0 py-0" label="Strasse" v-model="chosenLi.Strasse"></v-text-field>
              <v-text-field class="my-0 py-0" label="PLZ" v-model="chosenLi.PLZ"></v-text-field>
              <v-text-field class="my-0 py-0" label="Ort" v-model="chosenLi.Ort"></v-text-field>
            </div>
          </v-col>

          <v-col cols="5">

          </v-col>
        </v-row>
        <v-divider class="my-6"></v-divider>
        <v-row>
          <v-col cols="6">
            <b class="font-weight-bold">Wunsch-Lieferdatum</b>
            <v-checkbox class="mt-4" :true-value="1" :false-value="0" v-model="asap"
                        label="So schnell wie möglich"></v-checkbox>

            <div v-if="!asap">
              <v-date-picker
                  class="ml-0 pl-0 mt-6"
                  width="270"
                  v-model="sendDate"
                  :min="minDate"
                  first-day-of-week="1"
                  locale="ch-de"
                  flat
                  color="primary"
              ></v-date-picker>
            </div>
          </v-col>

        </v-row>
        <v-divider class="mb-6"></v-divider>
        <v-row>
          <v-col>
            <b class="font-weight-bold pb-2 d-block">Nachricht an Puras</b>
            <v-textarea filled flat  v-model="msg" rows="2" class="mt-0"></v-textarea>
          </v-col>
        </v-row>
        <v-divider class="mt-2 mb-2"></v-divider>
        <v-row>
          <v-col cols="12">
            <table class="w-full" style="width: 100%;">
              <tr>
                <td>
                  <b>Total</b>
                </td>
                <td style="text-align: right">
                  <b> CHF {{ subtotal }}</b>
                </td>
              </tr>
              <tr>
                <td>
                  Mindermengenzuschlag
                </td>
                <td style="text-align: right">
                  CHF {{ mindermengenzuschlag ? mindermengenzuschlag : '0.00'}}
                </td>
              </tr>
              <tr>
                <td>
                  MwSt.
                </td>
                <td style="text-align: right">
                  CHF {{ mwst }}
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <v-divider class="mt-2 mb-4" light style="width:100%;"></v-divider>
                </td>
              </tr>
              <tr>
                <td>
                  <b class="font-weight-bold">Bestellsumme</b>
                </td>
                <td nowrap="nowrap" style="text-align: right">
                  <b class="font-weight-bold"> CHF {{ total }}</b>
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
        <v-divider class="mb-0 mt-4"></v-divider>
        <v-row>
          <v-col cols="12">
            <div class="d-flex">
              <div>
                <v-checkbox label="Ich bestätige die " v-model="agb" :true-value="1" :false-value="0" class="pl-1"
                            light>
                </v-checkbox>
              </div>
              <div class="pt-5 pl-2">
                <a class="body-1" style="font-size:14px !important" target='_blank' href='https://puras.ch/de/agb.html'>allgemeinen
                  Geschäftsbedingungen</a>
              </div>
            </div>

          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else class="body-1 mt-6 text-center">

        <v-icon size="140" color="primary">
          mdi-cart-check
        </v-icon>
        <h1 class="text--primary" style="line-height: 30px;font-size: 25px;">
          Ihre Bestellung ist unterwegs. Herzlichen Dank für Ihr Vertrauen. Sollten Sie Fragen zu Ihrer Bestellung haben
          können Sie uns jederzeit kontaktieren.
        </h1>

      </v-card-text>

      <v-card-actions style="position:absolute; bottom: 10px; right: 10px">
        <v-spacer></v-spacer>
        <v-btn depressed @click="$emit('closeOrderModal');showSuccess = false">Fenster schliessen</v-btn>
        <v-btn class="primary" v-if="!showSuccess" depressed @click="sendOrder()">Bestellung absenden</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import httpClient from "../../utils/httpClient";
import moment from 'moment'

export default {

  name: 'SendOrder',

  props: ['order', 'total', 'subtotal', 'mwst', 'mindermengenzuschlag'],

  data() {
    return {
      asap: 1,
      msg: '',
      activeLiID: false,
      moment: moment,
      chosenLi: false,
      chosenRg: false,
      showSuccess: false,
      agb: 0,
      rgAdr: [],
      liAdr: [],
      sendDate: new Date().toISOString().slice(0, 10),
      minDate: new Date().toISOString().slice(0, 10)
    }
  },

  methods: {

    setLi() {
      var self = this;

      this.chosenLi = JSON.parse(JSON.stringify(this.liAdr)).filter(function (e) {
        return e.liID === self.activeLiID;
      })[0];
    },

    async sendOrder() {
      const res = await httpClient({
        url: process.env.VUE_APP_API_CHECKLIST + 'send/order',
        method: 'post',
        data: {
          li: this.chosenLi,
          rg: this.chosenRg,
          asap: this.asap,
          sendDate: this.sendDate,
          agb: this.agb,
          cart: this.$props.order,
          msg: this.msg
        }
      })

      if (res.type === "success") {

        this.showSuccess = true;

      }
    }
  },

  computed: {},

  async created() {

    const res = await httpClient({
      url: process.env.VUE_APP_API_CHECKLIST + 'get/order-adresses',
      method: 'post',
      data: {wh: this.$props.order.whID}
    })

    if (res.type === 'success') {
      this.rgAdr = res.rg;
      this.liAdr = res.li;
      this.activeLiID = res.li[0].liID;
      this.chosenLi = res.li[0];
      this.chosenRg = res.rg[0];
      this.minDate = this.moment().add(2, 'day').format('YYYY-MM-DD');
      this.sendDate = this.moment().add(2, 'day').format('YYYY-MM-DD');
    }

  }
}
</script>
<style lang="scss">
#ordermodal {
  .v-picker__title.primary {
    display: none !important;
  }
}

</style>