import moment from "moment";

export const globalRules = {
    /**
     * Rule validate required
     * @param {any} v the given value to validate
     * @param {string} label
     * @returns validate
     */

    ruleRequired: (v) => {
        return !!v || `Dieses Feld wird benötigt`;
    },


    ruleNotEmpty: (v, allowZero = false) => {
        return (!!v || (allowZero && parseInt(v) == 0)) || `Dieses Feld wird benötigt`;
    },

    ruleNotEqual: (v, notEqualValue, allowZero = false) => {
        return ((!!v || (allowZero && parseInt(v) == 0)) && v != notEqualValue) || `Bitte ändern Sie den Wert dieses Feldes`;
    },
    /**
     * Rule validate min length
     * @param {any} v the given value to validate
     * @param {number} length min length to check
     * @param {string} label
     * @returns validate
     */
    ruleMinLength: (v, length, optional = false, onlyNumeric = false) => {
        let optionalText = optional ? 'sollte' : 'muss';
        let isnum = /^\d+$/.test(v);
        let onlyNumericText = onlyNumeric && !isnum ? " und nur Zahlen enthalten" : "";
        return ((v == null || String(v).length == 0 || String(v).length >= length) || `Das Feld ${optionalText} mindestens ${length} Zeichen lang sein${onlyNumericText}`);
    },

    /**
     * Rule validate exact length
     * @param {any} v the given value to validate
     * @param {number} length min length to check
     * @param {string} label
     * @returns validate
     */
    ruleExactLength: (v, length, optional = false, onlyNumeric = false, asHint = false) => {
        let optionalText = optional ? 'sollte' : 'muss';
        let isnum = /^\d+$/.test(v);
        let onlyNumericText = onlyNumeric && !isnum ? " und nur Zahlen enthalten" : "";
        if (v == null || String(v).length == 0 || String(v).length == length) {
            return (asHint) ? "" : true
        } else {
            return `Das Feld ${optionalText} genau ${length} Zeichen lang sein${onlyNumericText}`;
        }
    },


    /**
     * Rule validate max length
     * @param {any} v the given value to validate
     * @param {number} length max length to check
     * @param {string} label
     * @returns validate
     */
    ruleMaxLength: (v, length, label = null, onlyNumeric = false) => {
        let isnum = /^\d+$/.test(v);
        let onlyNumericText = onlyNumeric && !isnum ? " und nur Zahlen enthalten" : "";
        return ((v != null && String(v).length >= length) || `Das Feld darf mindestens ${length} Zeichen lang sein${onlyNumericText}`);
    },
    /**
     * Rule validate email
     * @param {any} v the given value to validate
     * @param {string} label
     * @returns validate
     */
    ruleEmail: (v, targetValue, label = null) => {
        return !v || /^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+(\.\w{2,3})+$/.test(String(v)) || `Bitte geben Sie eine korrekte E-Mailadresse an`;
    },
    /**
     * Rule validate phone number
     * @param {any} v the given value to validate
     * @param {string} label
     * @returns validate
     */
    ruleTelephone: (v, targetValue, label = null) => {
        return !v || /^([0-9\\-])*$/.test(String(v)) || `The ${label ?? 'field'} must be a valid phone number`
    },
    /**
     * Rule validate decimal
     * @param {any} v the given value to validate
     * @param {string} label
     * @param {number} decimalPlace the decimal place to check
     * @param {boolean} checkOverDecimalOnly to check only over decimal place only
     * @returns validate
     */
    ruleDecimal: ({v, label = null, decimalPlace = 2, checkOverDecimalOnly = true}) => {
        const regex = new RegExp(`^(0|[1-9]\\d*)((\\.)${checkOverDecimalOnly ? '?' : ''}\\d{${
                checkOverDecimalOnly ? '0,' : ''
            }${decimalPlace}})$`);
        if (v && !v.toString().replace(/\s/g, '').match(regex)) {
            return `The ${label ?? 'field'} must be a valid decimal with ${decimalPlace} fraction`;
        }
        return true;
    },
    /**
     * Rule validate number less than
     * @param {any} v the given value to validate
     * @param {any} targetValue the target value to check againt
     * @param {string} label
     * @returns validate
     */
    ruleLessThanOrEqual: (v, targetValue, label = null) => {
        return !v || !targetValue || parseFloat(v) <= parseFloat(targetValue) || `Das Wert darf nicht über ${targetValue} sein`
    },
    /**
     * Rule validate number greater than
     * @param {any} v the given value to validate
     * @param {any} targetValue the target value to check againt
     * @param {string} label
     * @returns validate
     */
    ruleGreaterThan: (v, targetValue, label = null) => {
        return !v || !targetValue || parseFloat(v) > parseFloat(targetValue) || `The ${label ?? 'field'} must greater than ${targetValue}`
    },
    /**
     * Rule validate integer number
     * @param {any} v the given value to validate
     * @param {string} label
     * @returns validate
     */
    ruleNumber: (v, targetValue, label = null) => {
        return Number.isInteger(Number(v)) || `The ${label ?? 'field'} must be a valid integer`
    },

    /**
     * Rule validate date before date
     * @param {any} v the given value to validate
     * @param {any} targetValue the target value to check againt
     * @param {string} label
     * @returns validate
     */
    ruleBefore: (v, value, customText = null) => {
        let date = moment(value).format('DD.MM-YYYY');
        let text = customText ? customText : `Dieses Datum muss vor dem ${date} sein`;
        return !v ||  moment(v).isSameOrBefore(moment(value)) || text;
    },

    /**
     * Rule validate date before date
     * @param {any} v the given value to validate
     * @param {any} targetValue the target value to check againt
     * @param {string} label
     * @returns validate
     */
    ruleBeforeDate: (v, targetValue, targetLabel = null) => {
        return !v || !targetValue || moment(v).isBefore(moment(targetValue)) || `Dieses Datum muss vor dem ${targetLabel} sein`
    },
    /**
     * Rule validate date after date
     * @param {any} v the given value to validate
     * @param {any} targetValue the target value to check againt
     * @param {string} label
     * @returns validate
     */
    ruleAfterDate: (v, targetValue, targetLabel = null) => {
        return !v || !targetValue || moment(v).isAfter(moment(targetValue)) || `Dieses Datum muss nach dem ${targetLabel} sein`
    },

    /**
     * Rule validate date after date
     * @param {any} v the given value to validate
     * @param {any} targetValue the target value to check againt
     * @param {string} label
     * @returns validate
     */
    ruleSameOrAfterDate: (v, targetValue, targetLabel = null) => {
        return !v || !targetValue || moment(v).isSameOrAfter(moment(targetValue)) || `Dieses Datum muss nach dem ${targetLabel} sein`
    },
    /**
     * Rule validate is
     * @param {any} v the given value to validate
     * @param {any} targetValue the target value to check againt
     * @param {string} label
     * @returns validate
     */
    ruleIs: (v, targetValue, label = null) => {
        return !v || !targetValue || v === targetValue || `The ${label ?? 'field'} must be ${targetValue}`;
    },
    ruleVekaNumber: (v) => {
        if (!v) {
            return true;
        }
        let veka = v;
        veka = veka.replace("/[^0-9]/", "", veka);
        // Reverse the input
        veka = veka.split('').reverse().join('');
        // Define the weight factors
        const weights = [1, 3, 7];

        // Initialize the sum
        let sum = 0;

        // Iterate over the digits of the reversed input
        for (let i = 0; i < veka.length; i++) {
            // Multiply the digit with the corresponding weight factor
            let product = veka[i] * weights[i % 3];
            // Add the result to the sum
            sum += product;
        }
        // Check if the sum is divisible by 10
        return sum % 10 == 0 || 'Bitte geben Sie eine korrekte Kartennummer ein'
    },

    ruleAhvNumber: (v, asHint = false) => {
        if (!v) {
            return (asHint) ? "" : true
        }
        let number = v;
        let regex = /[7][5][6][.][\d]{4}[.][\d]{4}[.][\d]{2}$/
        let isValid = false

        //Last digit of the entered number
        let checknumber = parseInt(number[number.length - 1])

        //Validate the general setup of the insurance-number using the regex defined above
        isValid = regex.test(number);
        if (isValid) {
            //Remove last character (not needed to calculate checksum)
            let tmp_number = number.substr(0, number.length - 1)

            //Remove dots from number and reverse it
            //(if you want to know why, look at the rules in the link given in the html-comment)
            tmp_number = tmp_number.split(".").join("").split("").reverse().join("")
            let sum = 0
            for (let i = 0; i < tmp_number.length; i++) {
                var add = i % 2 == 0 ? tmp_number[i] * 3 : tmp_number[i] * 1
                sum += add
            }

            //Calculate correct checksum (again, see the documentation to undestand why)
            let checksum = (Math.ceil(sum / 10) * 10) - sum
            if (checksum !== checknumber) {
                isValid = false
            }
        }
        if (isValid) {
            return (asHint) ? "" : true
        } else {
            return 'Dies ist keine korrekte AHV-Nr.';
        }
    },

    /**
     * Rule validate ZSR number
     * @param v
     * @returns {string|boolean}
     */
    ruleZSR: (v) => {
        if (!v) {
            return true;
        }
        let number = v;
        let regex = /^([A-Z][0-9]{6})$/
        let isValid = false

        isValid = regex.test(number);
        if (isValid) {
            return true
        } else {
            return 'Bitte geben Sie eine korrekte ZSR-Nummer ein';
        }
    },
}
