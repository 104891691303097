<template>
  <v-dialog v-model="dialog" width="640">
    <template v-slot:activator="{ on, attrs }">
      <v-avatar
          v-show="nFailed>0"
          v-bind="attrs"
          v-on="on"
          color="red"
          size="25"
      >
        <span class="mt-1" style="color: #ffffff;">
          {{ nFailed }}
        </span>
      </v-avatar>
    </template>

    <v-card class="py-4 px-2">
      <v-card-title style="position: relative">
        <div class="text-h2">
          Fehlermeldungen<br>
          <span class="text--primary">{{ bewohner.vorname }} {{ bewohner.name }}</span> ({{ nFailed }})
        </div>
        <div class="close-btn" @click="dialog=false">
          <img alt="" :src="require('@/assets/img/ico/schliessen.svg')" width="25"/>
        </div>
      </v-card-title>


      <v-card-text>
        <v-list>
          <v-list-item
              v-for="(failedValidation, i) in latestCart.validation"
              :key="'failed-'+i"
              class="mb-1 px-0"
          >
            <div style="width: 100%; font-size: 18px;">
              {{ failedValidation.message }}
              <v-divider class="mt-3"/>
            </div>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CartValidationDialog",
  props: {
    bewohnerID: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      dialog: false
    }
  },

  computed: {
    carts() {
      return this.$store.state['direktabrechnung'].carts.filter(c => c.bewohner_id === this.bewohnerID);
    },

    latestCart() {
      return this.carts[this.carts.length - 1]
    },

    bewohner() {
      if (!this.carts || this.carts.length <= 0) {
        return null
      }

      return this.carts[0].bewohner
    },

    nFailed() {
      return this.latestCart.validation.length
    },

  }
}
</script>

<style lang="scss" scoped>
.close-btn {
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
}
</style>