<template>
  <v-dialog
      v-model="show"
      persistent
      width="600"
  >
      <template v-slot:activator="{}">
      <span @click="open" style="cursor: pointer">
        <slot name="button"/>
      </span>
      </template>

    <v-card
        class="main"
    >
      <v-alert :type="type" v-if="type">{{ title }}</v-alert>
      <v-card-title v-else class="pl-0 text-primary text-h5">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <slot/>
      </v-card-text>
      <v-card-actions class="justify-end text-right">
        <slot name="close" :confirm="close">
          <v-btn color="primary" variant="flat" rounded @click="close">Schliessen</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.v-alert {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.v-card-text {
  padding-left: 0 !important;
}
.v-card-actions {
  padding: 0;
}
</style>
<script>

export default {
    name: "InfoDialog",
    emits: ['close'],
    props: {
        title: {
            type: String,
            required: true
        },

        type: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            show: false,
            
        }
    },
    methods: {
        open() {
            this.show = true
        },

        close() {
            this.show = false
            this.$emit('close')
        }
    }
}

</script>

<style scoped lang="scss">
.main {
  padding: 30px 40px 30px 40px;
}

.v-btn--rounded {
  border-radius: 10px;
}

.v-card__text {
  padding-top: 1rem !important;
  padding-left: 0 !important;
  font-size: 16px !important;
}
.v-card__actions {
  padding: 0 !important;
  padding-top: 10px !important;
}

</style>
