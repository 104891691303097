<template>
  <div id="toooadmin">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="primary--text mb-0">
            Benutzerverwaltung
          </h1>
        </v-col>
      </v-row>
      <v-row class="mt-0 fixed sticky mb-2">
        <v-col cols="1">
          <div class="pt-5">
            <v-btn @click="addUser()" small fab light depressed color="primary" class="addbtn">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="1" class="col-icon">
        </v-col>
        <v-col cols="2" class="col-firstname">
          <b>Vorname</b>
        </v-col>
        <v-col cols="2" class="col-lastname">
          <b>Name</b>
        </v-col>
        <v-col cols="2" class="col-email">
          <b>E-Mail</b>
        </v-col>
        <v-col cols="2" class="col-password">
          <b>Passwort</b>
        </v-col>
        <v-col cols="1" class="col-permissions">
          <b>Berechtigungen</b>
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
      <v-row v-for="(u,index) in clientUsers" :key="'u-'+index" v-if="u.superadmin != 1" class="b-top hover-row">
        <v-col cols="1"  class="text-vertical-align col-icon" >
          <span v-if="u.tooladmin === 1">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        ize="24"
                        class="mr-4"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                    >
                      mdi-crown
                    </v-icon>
              </template>
              <span>Benutzerverwaltung</span>
            </v-tooltip>
          </span>
          <span v-if="u.accountant">
                        <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        ize="24"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                    >
                      mdi-book-outline
                    </v-icon>
              </template>
              <span>Heim-Administration</span>
            </v-tooltip>
          </span>
        </v-col>
        <v-col cols="2"  class="text-vertical-align col-firstname" >
          {{ u.vorname }}
        </v-col>
        <v-col cols="2"  class="text-vertical-align col-lastname" >
          {{ u.name }}
        </v-col>
        <v-col cols="2"  class="text-vertical-align col-email" >
          <span class="caption" style="hyphens: auto">{{ u.email }}</span>
        </v-col>
        <v-col cols="2"  class="text-vertical-align col-password" >
          <v-btn @click="resetPassword(u.id)" depressed color="primary" small>Passwort zurücksetzen</v-btn>
        </v-col>
        <v-col cols="1"  class="col-permissions text-vertical-align d-flex flex-row" style="gap: 6px;">
          <template v-for="(translation, key) in accessOptions">
            <v-tooltip top v-if="key != 'tool:shared'">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <LogoVM v-if="key == 'tool:checklist'" class="access-option" :class="{ inactive: !u.tool_access.includes(key)}"/>
                  <LogoIP v-else-if="key == 'tool:inkoplaner'" class="access-option" :class="{ inactive: !u.tool_access.includes(key)}"/>
                  <LogoWunde v-else-if="key == 'tool:wunde'" class="access-option" :class="{ inactive: !u.tool_access.includes(key)}"/>
                  <LogoShop v-else-if="key == 'tool:shop'" class="access-option" :class="{ inactive: !u.tool_access.includes(key)}"/>
                </span>
              </template>
              <span>{{ translation }}</span>
              <div style="color: #cccccc;" v-if="u.last_login[key] !== undefined">
                letztes Login: {{ u.last_login[key] }}
              </div>
            </v-tooltip>
          </template>
        </v-col>
        <v-col cols="2"   class="text-vertical-align d-flex justify-end">
          <div class="mr-3" v-if="u.notes && u.notes != ''">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }" open-delay="0">
                  <v-icon v-bind="attrs" v-on="on" :color="hover ? 'primary' : 'grey'" :class="hover ? '' : 'text--darken-2'" @click="openEditUser(u)">
                    mdi-information-outline
                  </v-icon>
                </v-hover>
              </template>
              <span>
                {{ u.notes }}
              </span>
            </v-tooltip>
          </div>
          <div class="mr-3">
            <v-hover v-slot="{ hover }" open-delay="0">
              <v-img v-if="hover" class="mt-0 cursor-pointer"
                     @click="openEditUser(u)"
                     :src="require('@/assets/img/ico2023/bearbeiten-aktiv.svg')" alt=""
                     max-width="30"></v-img>
              <v-img v-else class="mt-0 cursor-pointer"
                     @click="openEditUser(u)"
                     :src="require('@/assets/img/ico2023/bearbeiten.svg')" alt="" max-width="30"></v-img>
            </v-hover>
          </div>
          <div>
            <v-hover v-slot="{ hover }" open-delay="0">
              <v-img @click="deleteUser(u.id)" v-if="hover" class="mt-0 cursor-pointer" :src="require('@/assets/img/ico/loeschen-hover.svg')" alt="" max-width="30"></v-img>
              <v-img @click="deleteUser(u.id)" v-else class="mt-0 cursor-pointer" :src="require('@/assets/img/ico/loeschen.svg')" alt="" max-width="30"></v-img>
            </v-hover>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="editModal" persistent max-width="400">
      <v-card v-if="editUser">
        <v-card-title class="headline">
          {{ editUser.vorname }} {{ editUser.name }}
        </v-card-title>
        <v-card-text class="body-2">
          <v-row>
            <v-col>
              <div class="access_selection">
                Tool Berechtigungen
                <div v-for="(translation, key) in accessOptions">
                  <v-checkbox
                      v-if="key != 'tool:shared'"
                      v-model="editUser.tool_access"
                      :label="translation"
                      :value="key"
                      hide-details
                      :disabled="!activeAccessOptions.includes(key)"
                  />
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field autocomplete="off" v-model="editUser.vorname" label="Vorname"></v-text-field>
              <input type="hidden" :value="editUser.id">
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field autocomplete="off" v-model="editUser.name" label="Name"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field autocomplete="off" v-model="editUser.email" label="E-Mail"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea autocomplete="off" v-model="editUser.notes" label="Notizen"></v-textarea>
            </v-col>
          </v-row>
          <v-row v-if="editUser.id == -1">
            <v-col>
              <v-switch
                  v-model="sendmail"
                  true-value="yes"
                  false-value="no"
                  dense
                  small
                  light
                  flat
              >
                <template v-slot:label="">
                    <span class="body-2">
                       Benachrichtigung per Mail versenden
                    </span>
                </template>
              </v-switch>
            </v-col>
          </v-row>
          <v-row class="mt-0 pt-0">
            <v-col class="mt-0 pt-0">
              <v-switch
                  v-model="tooladmin"
                  :true-value="1"
                  :false-value="0"
                  dense
                  small
                  light
                  flat
              >
                <template v-slot:label="">
                    <span class="body-2">
                      Benutzerverwaltung
                    </span>
                </template>
              </v-switch>
            </v-col>
          </v-row>
          <v-row class="mt-0 pt-0">
            <v-col class="mt-0 pt-0">
              <v-switch
                  class="mt-0 pt-0"
                  v-model="accountant"
                  :true-value="1"
                  :false-value="0"
                  dense
                  small
                  light
                  flat
              >
                <template v-slot:label="">
                    <span class="body-2">
                      Heim-Administration
                    </span>
                </template>
              </v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" depressed @click="editModal = false">Abbrechen</v-btn>
          <v-btn color="primary" depressed @click="editModal = false;saveUser(editUser)">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import httpClient from "../utils/httpClient";
import LogoIP from "@/components/svg/LogoIP";
import LogoVM from "@/components/svg/LogoVM";
import LogoWunde from "@/components/svg/LogoWunde";
import LogoShop from '@/components/svg/LogoShop.vue'

export default {
  name: 'Tooladmin',
  components: { LogoShop, LogoWunde, LogoVM, LogoIP},
  data() {
    return {
      editModal: false,
      editUser: false,
      accountant: 0,
      sendmail: 'yes',
      tooladmin: 0,
      clientUsers: [],
      accessOptions: [],
      activeAccessOptions: []
    }
  },
  computed: {},

  methods: {
    postUserMigration(user) {
      this.editModal = false
      this.clientUsers.push(user)
    },

    openEditUser(u) {
      this.editUser = Object.assign({}, u);

      this.tooladmin = u.tooladmin
      this.accountant = u.accountant
      this.editModal = true;

      if (this.editUser.tool_access === undefined) {
        this.editUser.tool_access = []
      }
    },

    addUser: function () {

      this.editUser = {
        'name': '',
        'vorname': '',
        'email': '',
        'id': -1,
        'tooladmin': 0,
        'accountant': 0,
        tool_access: [],
      }

      this.editModal = true;
    },

    async deleteUser(uid) {

      this.$confirm('Möchten Sie diesen Benutzer wirklich löschen?', {
        title: 'Achtung',
        buttonTrueText: 'Löschen',
        buttonFalseText: 'Abbrechen'
      }).then(async confirmed => {
        if (confirmed) {
          const res = await httpClient({
            url: process.env.VUE_APP_API_SHARED + 'tooladmin/delete/user',
            method: 'post',
            data: {uid: uid}
          })

          if (res.type == 'success') {
            this.clientUsers = res.users;
          }
        }
      })
    },

    async resetPassword(uid) {
      this.$confirm('Wenn Sie diesem Benutzer das Passwort zurücksetzen, wird er eine Willkommens-Mail / Passwort-Mail erhalten und das aktuelle Passwort wird ungültig.', {
        title: 'Achtung',
        buttonTrueText: 'Zurücksetzen',
        buttonFalseText: 'Abbrechen'
      }).then(async confirmed => {
        if (confirmed) {
          const res = await httpClient({
            url: process.env.VUE_APP_API_SHARED + 'tooladmin/reset/user',
            method: 'post',
            data: {uid: uid}
          })

          if (res.type == 'success') {
            this.clientUsers = res.users.filter(user => user.superadmin != 1)
          }
        }
      })
    },

    async saveUser() {
      const res = await httpClient({
        url: process.env.VUE_APP_API_SHARED + 'tooladmin/save/user',
        method: 'post',
        data: {
          user: this.editUser,
          tool: 'checklist',
          sendmail: this.sendmail,
          tooladmin: this.tooladmin,
          accountant: this.accountant
        }
      })

      if (res.type == 'success') {
        this.clientUsers = res.users.filter(user => user.superadmin != 1);
      }
    },
  },
  async mounted() {

    const res = await httpClient({
      url: process.env.VUE_APP_API_SHARED + 'tooladmin/get/users',
      method: 'get'
    })

    if (res.type == 'success') {
      this.accessOptions = res.accessOptions
      this.activeAccessOptions = res.activeAccessOptions
      this.clientUsers = res.users.filter(user => user.superadmin != 1);
    }
  }
}
</script>

<style lang="scss" scoped>
#toooadmin {
  font-size: 16px;

  b {
    font-size: 16px;
    font-weight: 300;
    font-family: "Avenir LT Std Heavy" !important;
  }
}

.access_selection {
  .v-input--selection-controls {
    margin-top: 0;
  }
}

.access-option {
  width: 24px;
  height: 24px;

  &.inactive {
    color: #cccccc;
  }
}

.col-icon {
  flex: 0 0 6%;
  max-width: 6%;
}
.col-email {
  flex: 0 0 21.6666666667%;
  max-width: 21.6666666667%;
}
.col-password {
  flex: 0 0 14%;
  max-width: 14%;
}

.col-icon {
  flex: 0 0 6%;
  max-width: 6%;
}
.col-email {
  flex: 0 0 21.6666666667%;
  max-width: 21.6666666667%;
}
.col-password {
  flex: 0 0 14%;
  max-width: 14%;
}
</style>

