import Vue from 'vue'
import httpClient from "@/utils/httpClient";
import moment from "moment";

const bewohnerTherapienState = Vue.observable({
    loadingTherapien: false, 
    therapien: []
})

export const bewohnerTherapienGetters = {
    bewohnerTherapies: () => {
        return bewohnerTherapienState.therapien
    },
    getByBewohnerId: (state) => (id) => !state.therapien ? null : state.therapien.find(b => b.bewohnerID === +id),
    getByBewohnerIdAndMigelKategorie: (state) => (bewohnerID, migelPauschalKategorieID) => {
        return !state.therapien ? [] : state.therapien.filter(t => t.bewohner_id === +bewohnerID && t.migel_pauschal_kategorie_id === +migelPauschalKategorieID)
    },
}

export const bewohnerTherapienMutations = {
    setTherapien(therapien) {
        bewohnerTherapienState.therapien = therapien
    }
}

export const bewohnerTherapienActions = {
    async fetchTherapien(bewohnerID) {
        if (!this.loadingTherapien) {
            this.loadingTherapien = true
            httpClient({
                url: process.env.VUE_APP_API_SHARED + 'get/bewohner/therapien/' + bewohnerID, method: 'get',
            })
                .then(response => {
                    if (response.type === "success") {
                        bewohnerTherapienMutations.setTherapien(response.therapien);
                    }
                })
                .finally(() => {
                    this.loadingTherapien = false
                })

        }
    },

    addTherapie(therapie, bewohnerID) {
        return new Promise((resolve, reject) => {
            httpClient({
                url: process.env.VUE_APP_API_SHARED + 'save/bewohner/therapien/' + bewohnerID,
                method: 'POST',
                data: {
                    therapie: therapie
                }
            }).then((response) => {
                bewohnerTherapienMutations.setTherapien(response.therapien);
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    deleteTherapie(id) {
        return httpClient({
            url: process.env.VUE_APP_API_SHARED + 'bewohner/therapien/' + id,
            method: 'DELETE',
        }).then((response) => {
            bewohnerTherapienMutations.setTherapien(response.therapien);
        });
    }
}
