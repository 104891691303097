<template>
  <div id="direktabrechnung">
    <div style="position: fixed;width: 100%; top: 0px; z-index: 98; left: 0px; height: 116px;"
         class="text-vertical-align">
      <v-container>
        <v-row>
          <v-col cols="3" class="text-vertical-align">
            <v-row class="d-none d-lg-flex">
              <v-col cols="1" class="icon-container text-vertical-align" style="background-color: white">
                <router-link to="/verbrauchsliste">
                  <v-img :src="require('@/assets/img/ico/planung.png')" alt="" width="32"></v-img>
                </router-link>
              </v-col>
              <v-col cols="10" class="pl-0 name-container text-vertical-align"
                     style="background-color: white">
                <div>
                  <router-link to="/verbrauchsliste" class="kunden-name"
                               :class="{'small-text': this.$store.state.benutzer.info.firma.length > 30}">
                    {{ this.$store.state.benutzer.info.firma }}
                  </router-link>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="text-vertical-align mt-2">
            <v-row>
              <v-col cols="7">
                <v-text-field
                    style="margin-top: -9px;"
                    v-model="searchTerm"
                    clearable
                    light
                    placeholder="Suche nach Bewohner (Zimmer, Name)"
                    autocomplete="off"
                />
              </v-col>
              <v-col cols="5" class="text-vertical-align">
                <div class="ml-8">
                  <span class="text-h5 pr-3 op-hover cursor-pointer"
                        @click="changeChoosenDate('sub')">
                  <svg xmlns="http://www.w3.org/2000/svg" width="9.254" height="15.697"
                       viewBox="0 0 9.254 15.697">
                  <path id="Pfad_1215" data-name="Pfad 1215"
                        d="M139.074,569.512l7.133,7.151-7.133,7.133"
                        transform="translate(147.62 584.502) rotate(180)" fill="none" stroke="#241911"
                        stroke-miterlimit="10"
                        stroke-width="1"/>
                  </svg>
                  </span>
                  <span class="text-h5 font-weight-light">{{
                      $moment($store.state.direktabrechnung.year + '-' + $store.state.direktabrechnung.month).locale('de-CH').format('MMMM YYYY')
                    }}</span>
                  <span class="text-h5 pl-3 cursor-pointer op-hover"
                        v-if="$moment($store.state.direktabrechnung.year+'-'+$store.state.direktabrechnung.month).format('YYYYMM') < $moment().format('YYYYMM')"
                        @click="changeChoosenDate('add')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="9.254" height="15.697"
                         viewBox="0 0 9.254 15.697">
                      <path id="Pfad_1215" data-name="Pfad 1215" d="M0,14.283,7.133,7.133,0,0"
                            transform="translate(0.708 0.707)" fill="none" stroke="#241911"
                            stroke-miterlimit="10"
                            stroke-width="1"/>
                    </svg>
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-row style="margin-top: 30px;">
            <v-col>
              <h1 class="primary--text my-0">
                Direktabrechnung
              </h1>
            </v-col>
            <v-col class="d-flex justify-end align-center">
              <div>
                <print-dialog-direktabrechnung
                    label="Monatsübersicht"
                    module="insurance-month"
                    :sortable="false"
                    :data="false"
                    call-function="insuranceMonth"
                    :call-params="{year: this.$store.state.direktabrechnung.year, month: this.$store.state.direktabrechnung.month}"
                    orientation="portrait"/>
              </div>
              <div class="ml-6">
                <print-dialog-direktabrechnung
                    label="Detailabrechnung"
                    module="insurance-detail"
                    :sortable="true"
                    :data="false"
                    call-function="insuranceDetail"
                    :call-params="{year: this.$store.state.direktabrechnung.year, month: this.$store.state.direktabrechnung.month}"
                    orientation="portrait"/>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-select
              v-model="filterStatus"
              :items="statusList"
              item-text="text"
              item-value="value"
              label="Status filtern"
              hide-details
          />
        </v-col>
        <v-col cols="4">
          <v-checkbox
              v-model="filterErrorsOnly"
              hide-details
          >
            <template #label>
              <span >
                nur Fehler anzeigen&nbsp;<span class="red--text font-weight-bold">({{ nCartsWithErrors }})</span>
              </span>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
    </v-container>
    <div
        class="white pt-10"
        style="position: sticky; top: 116px; z-index: 1"
    >
      <v-container class="pl-2 pb-0">
        <v-row>
          <v-col cols="6">
            <v-btn
                outlined
                @click="toggleShowType('krankenkasse')"
                class="mr-2 font-weight-bold"
                :class="{ passive: showType !== 'krankenkasse'}"
            >
              Krankenkasse
            </v-btn>
            <v-btn
                outlined
                @click="toggleShowType('bewohner')"
                class="font-weight-bold"
                :class="{ passive: showType !== 'bewohner'}"
            >
              Bewohner
            </v-btn>
          </v-col>
          <v-col cols="6" class="text-right">
            <ConfirmDialog title="Abrechnung einreichen" @confirm="submit" v-if="showType==='krankenkasse'">
              <template #button>
                <v-btn depressed  :disabled="selectedBewohner.length === 0" color="primary" class="ml-8">Abrechnung einreichen
                </v-btn>
              </template>
              Die ausgewählten Leistungsabrechnungen werden jetzt an die Krankenkassen übermittelt.
            </ConfirmDialog>

          </v-col>
        </v-row>
        <v-divider class="mt-6 mb-3" style="background-color: #000000"/>
      </v-container>
    </div>
    <v-container class="pt-0">
      <v-row v-if="!isSearchActive">
        <v-col cols="6" class="pl-2">
          <v-checkbox
              @change="selectAllBewohner"
              v-model="selectAll"
              hide-details
              class="ma-0"
          >
            <template #label>
              <span class="mt-1 font-weight-bold black--text">Alle Bewohner auswählen</span>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="6" class="text-right">

        </v-col>
      </v-row>
      <v-row>
        <v-col class="pl-2">
          <OverviewWohneinheitContainer
              v-for="(whId, index) in wohneinheitenIds"
              :key="whId"
              :wohneinheit-id="whId"
              :show-type="showType"
              :index="index"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-overlay
        :value="loading"
        z-index="999"
        color="white"
    >
      <v-progress-circular
          color="black"
          indeterminate
          size="64"
          width="3"
      />
    </v-overlay>
  </div>
</template>
<script>
import OverviewDataRow from "@/components/direktabrechnung/OverviewDataRow"
import moment from 'moment'
import {mapGetters} from "vuex";
import OverviewWohneinheitContainer from "@/components/direktabrechnung/OverviewWohneinheitContainer.vue";
import {activeCards} from "@/composables/direktabrechnung";
import PrintDialogDirektabrechnung from "../components/direktabrechnung/PrintDialogDirektabrechnung.vue";
import ConfirmDialog from "@/components/shared/ConfirmDialog.vue";

export default {
  name: 'Direktabrechnung',
  components: {ConfirmDialog, PrintDialogDirektabrechnung, OverviewWohneinheitContainer, OverviewDataRow},
  data() {
    return {
      selectAll: true,
      showType: 'krankenkasse',
      datum: {
        actualMonthYear: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
        choosenMonthYear: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
      }
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loader/loading',
      isSearchActive: 'direktabrechnung/isSearchActive',
      filters: 'direktabrechnung/filters',
    }),

    searchTerm: {
      get() {
        return this.$store.getters['direktabrechnung/searchTerm']
      },
      set(value) {
        this.selectAll = false
        this.selectAllBewohner()
        this.$store.commit('direktabrechnung/setSearchTerm', value)
      }
    },

    filterStatus: {
      get() {
        return this.filters.status
      },
      set(value) {
        this.selectAll = false
        this.selectAllBewohner()
        this.$store.commit('direktabrechnung/setFilterStatus', value)
      }
    },

    filterErrorsOnly: {
      get() {
        return this.filters.errorsOnly
      },
      set(value) {
        this.selectAll = false
        this.selectAllBewohner()
        this.$store.commit('direktabrechnung/setFilterErrorsOnly', value)
      }
    },

    wohneinheitenIds() {
      let carts = this.$store.state.direktabrechnung.carts

      return [...new Set(carts.map(item => item.bewohner.wohneinheitenID))]
    },

    selectedBewohner() {
      return this.$store.state['direktabrechnung'].selectedBewohner
    },

    activeCards() {
      return activeCards(this.$store.state.direktabrechnung.carts, this.showType)
    },

    statusList() {
      const objectList = [{
        value: null,
        text: 'alle anzeigen'
      }]

      for (const [key, value] of Object.entries(this.$t('direktabrechnung.status'))) {
        objectList.push({
          value: key,
          text: value
        })
      }

      return objectList
    },

    nCartsWithErrors() {
      return this.$store.state.direktabrechnung.carts.filter(c => c.validation.length > 0).length
    }
  },

  methods: {

    toggleShowType(type) {
      this.showType = type

      this.selectAll = true
      this.selectAllBewohner()
    },

    submit() {
      this.$store.dispatch('direktabrechnung/submit');
    },

    selectAllBewohner() {
      if (this.selectAll) {
        this.$store.state['direktabrechnung'].selectedBewohner = [...new Set(this.activeCards.map(item => item.bewohner_id))]
      } else {
        this.$store.state['direktabrechnung'].selectedBewohner = []
      }
    },

    async resetSelectedBewohner() {
      this.$store.state['direktabrechnung'].selectedBewohner = [];

      await this.$store.dispatch('direktabrechnung/index', true);

      this.selectAll = true
      this.selectAllBewohner()
    },

    async changeChoosenDate(addOrSubOrToday) {

      let yearmonth = moment(this.$store.state.direktabrechnung.year + '-' + this.$store.state.direktabrechnung.month + '-01')

      if (addOrSubOrToday === 'sub') {
        this.$store.state.direktabrechnung.year = yearmonth.subtract(1, 'month').format('YYYY')
        this.$store.state.direktabrechnung.month = yearmonth.format('MM')
      } else if (addOrSubOrToday === 'add') {
        this.$store.state.direktabrechnung.year = yearmonth.add(1, 'month').format('YYYY')
        this.$store.state.direktabrechnung.month = yearmonth.format('MM')
      } else if (addOrSubOrToday === 'today') {
        this.$store.state.direktabrechnung.year = moment().format('YYYY')
        this.$store.state.direktabrechnung.month = moment().format('MM')
      }

      await this.resetSelectedBewohner();
    },
  },

  watch: {
    selectedBewohner() {
      this.selectAll = !!(this.selectedBewohner.length === this.activeCards.length)
    }
  },

  async mounted() {
    await this.resetSelectedBewohner();
  }
}
</script>

<style lang="scss" scoped>

.action-icon {
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    color: #CE20A8;
  }
}

.v-btn.passive {
  border-color: transparent;
}

.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;

  .v-label {
    display: initial;
    text-align: right;
  }

  .v-application--is-ltr & {
    .v-input--selection-controls__input {
      margin-right: 0;
      margin-left: 8px;
    }
  }

  .v-application--is-rtl & {
    .v-input--selection-controls__input {
      margin-left: 0;
      margin-right: 8px;
    }
  }
}
</style>
