<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        width="1100"
    >
      <template v-slot:activator="{ on, attrs }">
        <div @click="show" class="cursor-pointer hov-opacity" >
          <img  height="43" :src="require('@/assets/img/ico/pauschale-anzeigen.svg')" alt=""/>
          <div class="pt-0 caption text-center">
            Pauschalen<br>anzeigen
          </div>
        </div>
      </template>
      <v-card class="jahrespauschalen">
        <div class="op-hover" style="position:absolute;right: 26px; top: 32px;z-index:999">
          <img :src="require('@/assets/img/ico/schliessen.svg')" @click="dialog=false" alt="" width="25px" height="25px"
                class="mx-auto cursor-pointer" >
        </div>
        <v-card-title class="text-h2 mb-8">
          Pauschalen {{ year }}&nbsp;<span class="text--primary">{{ bewohner.vorname }} {{ bewohner.name }}</span>
        </v-card-title>
        <v-card-text>
          <div v-if="yearly && yearly.items.length === 0">
            Keine Pauschalen vorhanden.
          </div>
          <div v-else>
            <div class="d-flex mb-2 b-bottom pb-2">
              <div class="pr-4" :style="'width:'+columnsWidth[0]+'px'">
                <b>Bezeichnung Pauschale</b>
              </div>
              <div class="text-right pr-4" :style="'width:'+columnsWidth[1]+'px'">
                <b>Guthaben*</b>
              </div>
              <div class="text-right pr-4" :style="'width:'+columnsWidth[2]+'px'">
                <b>verbraucht*</b>
              </div>
              <div class="pr-4 text-right" :style="'width:'+columnsWidth[3]+'px'">
                <b>Restguthaben*</b>
              </div>
              <div class="text-right" :style="'width:'+columnsWidth[4]+'px'">
                <b>Aufgebraucht</b>
              </div>
            </div>
            <div class="d-flex b-bottom pt-1 pb-2" v-for="(item, index) in yearly.items">
              <div class="d-flex pr-4" :style="'width:'+columnsWidth[0]+'px'">
                <div>{{ item.name }}</div><div v-if="item.id === 'INKO' && item.latest_inkograd_this_year" class="ml-4" style="margin-top: 3px;"> <inko-grad-indicator :inkograd="item.latest_inkograd_this_year" /></div>
              </div>
              <div class="text-right pr-4"  :style="'width:'+columnsWidth[1]+'px'">
                <span v-if="item.aufgebraucht !== 1">
                  <span v-if="item.rates.yearlyPauschaleProRata > 0">
                  {{ item.rates.yearlyPauschaleProRata }}
                </span>
                <router-link v-else-if="item.kein_verbrauch !== true" to="/bewohnerverwaltung" style="color: #000">Therapie erfassen</router-link>
                </span>
              </div>
              <div class="pr-4 text-right " :style="'width:'+columnsWidth[2]+'px'">
                 <span v-if="item.aufgebraucht !== 1 && item.kein_verbrauch !== true">
                  {{ item.total_yearly.toFixed(2) }}
                 </span>
              </div>
              <div class="pr-4 text-right" :style="'width:'+columnsWidth[3]+'px'">
                 <span v-if="item.aufgebraucht !== 1">
                    <span v-if=" parseFloat(item.rates.yearlyPauschaleProRata) - parseFloat(item.total_yearly) > 0">
                       {{ (parseFloat(item.rates.yearlyPauschaleProRata) - parseFloat(item.total_yearly)).toFixed(2) }}
                    </span>
                    <span v-else-if="item.kein_verbrauch !== true">
                      -
                    </span>
                 </span>
              </div>
              <div class="d-flex justify-right pr-0" :style="'width:'+columnsWidth[4]+'px'">
                <div v-if="item.aufgebraucht === 1 && item.aufgebraucht_details" class="mr-3">
                  {{ $moment(item.aufgebraucht_details.created_at).format('DD.MM.YYYY') }}
                </div>
                <div style="margin-top: -5px; margin-right: -17px;">
                  <v-switch
                      :true-value="1"
                      :false-value="0"
                      @change="changeAufgebraucht(item.aufgebraucht, item.internal_id)"
                      v-model="item.aufgebraucht"
                      dense
                      flat
                      inset
                      hide-details
                      class="ma-0"
                  />
                </div>
              </div>
            </div>
            <div class="mt-6 mb-9">
              <b>*Hinweis: Die Beträge sind Annahmen, ohne Gewähr</b>
            </div>
            <div v-if="yearly && yearly.items.length > 0">
              <v-textarea v-model="comment" placeholder="Kommentar" no-resize height="30" outlined></v-textarea>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <div class="d-flex w-full d-block justify-right mb-4" style="width: 100%">
            <v-btn
                v-if="yearly && yearly.items.length > 0"
                color="primary"
                depressed
                @click="saveComment"
            >
              speichern
            </v-btn>
          </div>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import InkoGradIndicator from "../inkograd/InkoGradIndicator.vue";

export default {
  name: "Jahrespauschalen",
  components: {InkoGradIndicator},

  props: {
    bewohnerID: {
      type: Number,
      required: true
    },
    bewohner: {
      type: Object,
      required: true
    },
    year: {
      type: String,
      required: true
    },
    month: {
      type: String,
      required: true
    }

  },

  data() {
    return {
      comment: '',
      dialog: false,
      columnsWidth: [
        450, 160, 160, 160, 120
      ]
    }
  },

  computed: {
    yearly() {
      return this.$store.state.direktabrechnung.pauschalen.length > 0 ?  this.$store.state.direktabrechnung.pauschalen[0] : false;
    }
  },

  methods: {
    async show() {
      await this.$store.dispatch('direktabrechnung/getPauschalen', {
        bewohnerID: this.bewohnerID,
        year: this.year,
        month: this.month,
        type: 'yearly'
      });

      this.comment = this.yearly && this.yearly.items.length > 0 ? this.yearly.items[0].comment : '';
      this.dialog = true;
    },

    async changeAufgebraucht(aufgebraucht, id) {

      await this.$store.dispatch('direktabrechnung/changePauschaleAufgebraucht', {
        bewohnerID: this.bewohnerID,
        year: this.year,
        aufgebraucht: aufgebraucht,
        id: id
      });
    },

    async saveComment(){
      await this.$store.dispatch('direktabrechnung/saveComment', {
        bewohnerID: this.bewohnerID,
        year: this.year,
        comment: this.comment
      });
    }
  },

  mounted() {
    //this.comment = this.yearly && this.yearly.items.length > 0 ? this.yearly.items[0].comment : '';
  }
}
</script>
<style lang="scss">
.jahrespauschalen{
  .v-textarea,fieldset, .v-textarea .v-input__control{
    border: none !important;
    outline: none !important;
    background: #F9F6F4 !important;
  }

}

</style>