import { wohneinheitenMutations } from '../../components/observables/wohneinheiten'
import httpClient from '@/utils/httpClient'
import Promise from 'lodash/_Promise'

export const prescription = {
  namespaced: true,
  state: {
    bewohner: [],
    generatorConfig: null,
  },
  getters: {
    generatorConfig: state => state.generatorConfig,
    bewohnerPrescriptions: (state) => (id) => state.bewohner.find(b => b.bewohnerId === id)
  },

  mutations: {
    /**
     *
     * @param state
     * @param config
     */
    setGeneratorConfig (state, config) {
      state.generatorConfig = config
    },

    /**
     * Set prescriptions
     * @param state
     * @param bewohnerId
     * @param prescriptions
     */
    setPrescriptions (state, { bewohnerId, prescriptions }) {
      const pos = state.bewohner.findIndex(b => b.bewohnerId === bewohnerId)

      if (pos >= 0) {
        state.bewohner[pos].prescriptions = prescriptions
      } else {
        state.bewohner.push({
          bewohnerId: bewohnerId,
          prescriptions: prescriptions
        })
      }
    },

    /**
     * Delete prescription
      * @param state
     * @param bewohnerId
     * @param prescription
     */
    addPrescription (state, { bewohnerId, prescription }) {
      const pos = state.bewohner.findIndex(b => b.bewohnerId === bewohnerId)

      if (pos >= 0) {
        state.bewohner[pos].prescriptions.push(prescription)
      } else {
        state.bewohner.push({
          bewohnerId: bewohnerId,
          prescriptions: [prescription]
        })
      }
    },

    /**
     *
     * @param state
     * @param bewohnerId
     * @param prescriptions
     */
    updatePrescription(state, { bewohnerId, prescriptions }) {
      const pos = state.bewohner.findIndex(b => b.bewohnerId === bewohnerId)

      if (pos !== -1) {
        const pPos = state.bewohner[pos].prescriptions.findIndex(p => p.id === prescriptions.id)

        if (pPos !== -1) {
          state.bewohner[pos].prescriptions.splice(pPos, 1, prescriptions)
        }
      }
    },

    /**
     * Delete prescription
     * @param state
     * @param bewohnerId
     * @param id
     */
    deletePrescription (state, { bewohnerId, id }) {
      const pos = state.bewohner.findIndex(b => b.bewohnerId === bewohnerId)

      if(pos !== -1) {
        state.bewohner[pos].prescriptions = state.bewohner[pos].prescriptions.filter(p => p.id !== id)
      }
    }
  },

  actions: {
    /**
     *
     * @param commit
     * @returns {Promise<void>}
     */
    fetchConfig ({ commit, state }) {
      if(!state.generatorConfig) {
        httpClient.get(process.env.VUE_APP_API_SHARED + 'prescription/config')
          .then((response) => {
            commit('setGeneratorConfig', response)
          })
      }
    },

    /**
     * Fetch bewohner prescriptions
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    fetchBewohnerPrescriptions ({ commit }, id) {
      return httpClient.get(process.env.VUE_APP_API_SHARED + 'bewohner/' + id + '/prescription')
        .then((response) => {
          commit('setPrescriptions', { bewohnerId: id, prescriptions: response.data })
        })
    },

    /**
     * Add prescription to bewohner
     * @param commit
     * @param bewohnerId
     * @param formData
     * @returns {Promise<void>}
     */
    addPrescription ({ commit }, { bewohnerId, formData }) {
      return httpClient.post(process.env.VUE_APP_API_SHARED + 'bewohner/' + bewohnerId + '/prescription', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          commit('addPrescription', { bewohnerId: bewohnerId, prescription: response.data })
        })
    },

    /**
     *
     * @param commit
     * @param bewohnerId
     * @param id
     * @returns {Promise<void>}
     */
    deletePrescription ({ commit }, { bewohnerId, id }) {
      return httpClient.delete(process.env.VUE_APP_API_SHARED + 'bewohner/' + bewohnerId + '/prescription/' + id)
        .then(() => {
          commit('deletePrescription', { bewohnerId: bewohnerId, id: id })
        })
    },

    togglePrescriptionValidity({ commit }, { bewohnerId, id, valid }) {
      return httpClient.put(process.env.VUE_APP_API_SHARED + 'bewohner/' + bewohnerId + '/prescription/' + id, {
        valid: valid
      })
        .then((response) => {
          commit('updatePrescription', { bewohnerId: bewohnerId, prescriptions: response.data })
        })
    }
  }
}