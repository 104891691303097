<template>
    <div>
        <v-menu
                v-model="picker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                content-class="compact-calendar-menu"
                offset-y
                left
                min-width="auto">
            <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                    <v-img :src="hover ? require('@/assets/img/ico2023/kalender-hover.svg') : require('@/assets/img/ico2023/kalender.svg')"
                           width="28"
                           v-bind="attrs"
                           v-on="on"
                           class="icon"></v-img>
                </v-hover>
            </template>
            <v-date-picker
                    v-model="date"
                    no-title
                    class="compact-calendar"
                    type="month"
                    color="primary"
                    :active-picker.sync="activePicker"
                    :show-current="false"
                    range
                    @change="selectDate($event[0], $event[1])"
                    :min="minDate ? minDate.format('YYYY-MM-DD') : null"
                    locale="locale"
            >
                <template #default>
                    <button class="openEndButton" color="primary" variant="flat" rounded
                            @click="selectDate(date[0], false)" small
                            :disabled="!date"
                            v-if="!date || date.length < 2">{{ openEndLabel }}
                    </button>
                    <button class="openEndButton" color="primary" variant="flat" rounded
                            @click="selectDate(date[0], date[1], false)" small
                            v-if="date && date.length == 2">Auswählen
                    </button>
                </template>
            </v-date-picker>
        </v-menu>
    </div>
</template>

<script>

import moment from "moment";

export default {
    name: 'DateRangePicker',
    components: {},
    emits: ['update:modelValue'],
    props: {
        telescope: {
            required: false,
            default: true
        },
        inline: {
            required: false,
            default: false
        },
        minDate: {
            required: false,
            default: null
        },
        startDate: {
            required: false,
            default: moment()
        },
        locale: {
            required: false,
            default: "ch-de"
        },
        yearPicker: {
            required: false,
            default: false
        },
        yearRange: {
            required: false,
            default() {
                return [1900, 2100]
            }
        },
        tooltip: {
            required: false,
            default: null
        },
        openEndLabel: {
            required: false,
            default: 'Ende offen'
        }
    },
    data() {
        return {
            date: null,
            dp: null,
            modelType: null,
            picker: false,
            activePicker: null
        }
    },
    methods: {
        selectDate(start_date, end_date = null) {
            this.picker = false;
            this.$emit('update:modelValue', {
                start_date: moment(start_date).startOf(this.yearPicker ? 'year' : 'month').toDate(),
                end_date: end_date ? moment(end_date).endOf(this.yearPicker ? 'year' : 'month').toDate() : null
            });
            this.date = null;
        },
    },
    mounted() {
        this.modelType = this.yearPicker ? "yyyy-01-01" : "yyyy-MM-01";
    },
    watch: {
        picker(val) {
            if (this.yearPicker) {
                val && setTimeout(() => (this.activePicker = 'YEAR'))
            }
        },
    },
}

</script>

<style scoped>
.v-btn--rounded {
    border-radius: 10px;
}

.icon {
    cursor: pointer;
}

.openEndButton {
    float: right;
    font-weight: 700;
    color: #CE20A8;
    text-decoration: underline;
    padding-bottom: 3px;
    margin-bottom: 2px;
}
</style>
