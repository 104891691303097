<template>
  <div class="d-flex align-center">
    <div @click="sub" style="transform: rotate(-270deg)" :class="{'disabled-action': bewohnerAnteil == 0}">
      <v-hover v-slot="{ hover }" open-delay="0">
        <v-img  v-if="hover" class="mt-0  cursor-pointer" :src="require('@/assets/img/ico/circle-arrow-active.svg')" alt="" max-width="20"></v-img>
        <v-img v-else class="mt-0 cursor-pointer" :src="require('@/assets/img/ico/circle-arrow.svg')" alt="" max-width="20"></v-img>
      </v-hover>
    </div>
    <div style="width: 30px; text-align: center">
      {{ bewohnerAnteil }}
    </div>
    <div @click="add" style="transform: rotate(270deg)" :class="{'disabled-action': bewohnerAnteil == max}">
      <v-hover v-slot="{ hover }" open-delay="0">
        <v-img  v-if="hover" class="mt-0  cursor-pointer" :src="require('@/assets/img/ico/circle-arrow-active.svg')" alt="" max-width="20"></v-img>
        <v-img v-else class="mt-0 cursor-pointer" :src="require('@/assets/img/ico/circle-arrow.svg')" alt="" max-width="20"></v-img>
      </v-hover>
    </div>
  </div>
</template>

<script>
export default {
  name: "counterInput",
  props: {
    value: {
      type: Number,
      required: true
    },

    max: {
      type: Number
    }
  },

  data() {
    return {
      bewohnerAnteil: this.value
    }
  },

  methods: {
    add() {
      if (!this.max || this.bewohnerAnteil+1 <= this.max) {
        this.bewohnerAnteil++
      }

      this.$emit('input', this.bewohnerAnteil)
    },
    sub() {
      this.bewohnerAnteil--

      if (this.bewohnerAnteil < 0) {
        this.bewohnerAnteil = 0
      }

      this.$emit('input', this.bewohnerAnteil)
    }
  },

  watch: {
    value() {
      this.bewohnerAnteil = this.value
    }
  }

}
</script>

<style lang="scss">
.disabled-action {
  opacity: .3;
  pointer-events: none;
}
</style>