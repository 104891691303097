<template>
  <span class="opacity-50" @click="refresh">&nbsp;</span>
</template>

<script>
import {createWidgetMixin} from 'vue-instantsearch';

const connectRefresh = (renderFn, unmountFn) => (widgetParams = {}) => ({
  init() {
    renderFn({
      refresh() {
      }
    }, true);
  },

  render({instantSearchInstance}) {
    const refresh = instantSearchInstance.refresh.bind(instantSearchInstance);
  setTimeout(function(){
    renderFn({refresh}, false);

  },200)
  },

  dispose() {
    unmountFn();
  },
});

export default {
  name: 'AppRefresh',
  mixins: [createWidgetMixin({connector: connectRefresh})],
  props: ['refreshsearch'],

  data(){
    return {
      triggerRefresh: this.refreshsearch,
    }
  },
  methods: {
    refresh() {
      this.state.refresh();
    },

  },
  mounted() {

  },

  watch: {
    refreshsearch: {
      handler: function(val, oldVal) {
        this.refresh();
      },
      deep: true
    }

  },


};
</script>