<template>
    <v-dialog
            v-model="show"
            persistent
            width="515"
    >
        <template v-slot:activator="{ props }">
            <slot name="button" :open="open"></slot>
        </template>
        <v-card class="main">
            <header>
                <div class="title">
                    <h1>
                        {{ logo ? 'Logo' : 'Logo hochladen' }}
                    </h1>
                </div>
                <div class="close" @click="cancel()"><img :src="require('@/assets/img/ico/schliessen.svg')" width="25">
                </div>
            </header>
            <div v-if="logo">
                <div class="logo-container">
                    <img :src="logo" max-height="100" width="auto"/>
                </div>
                <div class="delete">
                    <v-hover v-slot="{hover}">
                        <img :src="hover ? require('@/assets/img/ico/loeschen-hover.svg') : require('@/assets/img/ico/loeschen.svg')"
                             @click="deleteLogo()" class="cursor-pointer" width="27">
                    </v-hover>
                    <div class="filename">{{ logoDisplayName }}</div>
                </div>
            </div>
            <div v-if="!logo">
                <LogoUpload @change="uploadLogo($event)"></LogoUpload>
            </div>
        </v-card>

    </v-dialog>
</template>

<script>
import {globalRules} from "@/composables/rules";
import ConfirmDialog from "@/components/shared/ConfirmDialog.vue";
import httpClient from "@/utils/httpClient";
import kunde from "@/components/observables/kunde";
import {mapGetters} from "vuex";
import LogoUpload from "@/components/Systemsettings/dialogs/components/LogoUpload.vue";

export default {
    name: 'LogoDialog',
    components: {LogoUpload},
    emits: ['cancel', 'delete'],
    props: {
        logo: {
            required: false
        }
    },
    data() {
        return {
            rules: globalRules,
            show: false,
            valid: false,
            form: null,
            data: null,
        }
    },
    computed: {
        kunde() {
            return kunde
        },
        ...mapGetters({
            'info': 'benutzer/info'
        }),

        logoDisplayName() {
            let split = this.logo.split('/');
            return split[split.length - 1];
        }
    },
    methods: {
        open() {
            this.show = true;
        },

        cancel() {
            this.show = false;
            this.$emit('cancel')
        },

        async uploadLogo(e) {
            let file = e.target.files[0]

            let data = new FormData();
            data.append('logo', file, file.name);

            const res = await httpClient({
                url: process.env.VUE_APP_API_SHARED + 'settings/logo',
                method: 'post',
                headers: {
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                },
                data: data
            }).then((response) => {
                this.info.logo = response.img
            })
        },

        async deleteLogo(e) {
            const res = await httpClient({
                url: process.env.VUE_APP_API_SHARED + 'settings/logo',
                method: 'delete',
            }).then((response) => {
                this.info.logo = null;
            })
        },
    },

    watch: {
        show() {
            if (this.show) {
            }
        }
    }
}
</script>

<style scoped lang="scss">
.main {
  padding: 40px 35px 23px 30px;
}

.title {
  h1 {
    margin: 0;
    margin-top: 0 !important;
    font-family: "Avenir LT Std";
    font-weight: 300 !important;
    font-size: 33px !important;
    line-height: 43px;
    margin-bottom: 2.5rem;
  }
}

.close {
  position: absolute;
  top: 30px;
  right: 40px;
  cursor: pointer;
}

.v-alert {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.v-card-text {
  padding-left: 0 !important;
}

.v-card__actions {
  padding: 0 !important;
  padding-top: 10px !important;
  justify-content: right;
}

.hidden {
  opacity: 0;
  height: 0;
  overflow: hidden;
  padding: 0 !important;
  margin: 0 !important;
}

.main {
  float: left;
  width: 100%;
}

.logo-container {
    padding: 0 40px 40px 40px;

    img {
        max-width: 100%;
        max-height: 200px;
        margin: 0 auto;
    }
}

.delete {
  img, .filename {
    display: block;
    float: left;
  }

  .filename {
    width: calc(100% - 37px);
    margin-left: 10px;
      line-height: 28px
  }
}

.title {
  h1 {
    line-height: 1.1em;
  }
}
</style>
