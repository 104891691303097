<template>
    <div>
        <v-form v-model="valid" ref="form">
            <v-row>
                <v-col cols="4">
                    <v-text-field label="Vorname*" variant="underlined" v-model="formValues.vorname"
                                  :rules="[rules.ruleNotEmpty]" :disabled="isBewohnerSyncActive"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Nachname*" variant="underlined" v-model="formValues.name"
                                  :rules="[rules.ruleNotEmpty]" :disabled="isBewohnerSyncActive"></v-text-field>
                </v-col>
                <v-col cols="4" class="pb-0">
                    <strong class="custom-label small">Geschlecht*</strong>
                    <v-radio-group v-model="formValues.geschlecht" color="primary" :rules="[rules.ruleNotEmpty]"
                                   :disabled="isBewohnerSyncActive">
                        <v-radio value="w" v-bind:key="'w'" label="Weiblich"></v-radio>
                        <v-radio value="m" v-bind:key="'m'" label="Männlich"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="4">
                    <v-select label="Wohneinheit*" variant="underlined" v-model="formValues.wohneinheitenID"
                              :disabled="isBewohnerSyncActive"
                              :items="wohneinheiten"
                              :rules="[rules.ruleNotEmpty]"
                              menu-icon="mdi-chevron-down"
                              item-text="name"
                              item-value="id">
                    </v-select>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Zimmer" variant="underlined" v-model="formValues.zimmer"
                                  :disabled="isBewohnerSyncActive"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Externe Bewohner ID" variant="underlined"
                                  v-model="formValues.externalID" :disabled="isBewohnerSyncActive"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <CustomDatePicker label="Eintrittsdatum" :formValues="formValues" name="entry_at" :clearable="true"
                                      :max-date="formValues['deleted_at'] ? asMoment(formValues['deleted_at']).subtract(1,'day').format('YYYY-MM-DD') : null"></CustomDatePicker>
                </v-col>
                <v-col cols="4">
                    <CustomDatePicker label="Austrittsdatum" :formValues="formValues" name="deleted_at"
                                      :clearable="true"
                                      :min-date="formValues['entry_at'] ? formValues['entry_at'] : null"
                                      :rules="formValues.entry_at ? [rules.ruleSameOrAfterDate(formValues.deleted_at,formValues.entry_at, 'Eintrittsdatum')]: []"></CustomDatePicker>
                </v-col>
            </v-row>
        </v-form>

        <div class="additional-actions" v-if="edit">
            <div class="action-icon" v-if="showDeleteButton && !isBewohnerSyncActive">
                <ConfirmDialog title="Bewohner löschen" @confirm="$emit('delete')">
                    <template #button>
                        <v-hover v-slot="{ hover }">
                            <div>
                                <v-img :src="hover ? require('@/assets/img/ico/loeschen-hover.svg') : require('@/assets/img/ico/loeschen.svg')"
                                       alt="" style="margin:0 auto;" max-width="35"
                                       class="cursor-pointer"></v-img>
                                <div class="block pt-0 caption text-center small">
                                    Löschen
                                </div>
                            </div>
                        </v-hover>
                    </template>
                    Möchten Sie diesen Bewohner wirklich löschen?
                </ConfirmDialog>
            </div>
            <div class="action-icon restore" v-if="showEntryButton">
                <ReEntryDialog title="Wiedereintritt des Bewohners" @confirm="$emit('reentry', $event)">
                    <template #button="slotProps">
                        <v-hover v-slot="{hover}">
                                   <span
                                           class="cursor-pointer text-center align-center"
                                           @click="slotProps.open()">
                                    <v-img :src="require('@/assets/img/ico2023/bewohner-hinzuefuegen2.svg')"
                                           :contain="true" alt="" style="margin:0 auto;" max-height="32"
                                           class="cursor-pointer"></v-img>
                                    <div class="block pt-0 caption text-center small">
                                       Wiedereintritt
                                    </div>
                                  </span>
                        </v-hover>
                    </template>
                    Bitte gib das Datum für den Wiedereintritt an
                </ReEntryDialog>
            </div>
            <div class="action-icon restore" v-if="showRestoreButton">
                <ConfirmDialog title="Bewohner wiederherstellen" @confirm="$emit('restore')">
                    <template #button>
                        <v-hover v-slot="{ hover }">
                            <div>
                                <v-img :src="hover ? require('@/assets/img/ico2023/plus-black-aktiv.svg') : require('@/assets/img/ico2023/plus-black.svg')"
                                       alt="" style="margin:0 auto;" max-width="28"
                                       class="cursor-pointer"></v-img>
                                <div class="block pt-0 caption text-center small">
                                    Wiederherstellen
                                </div>
                            </div>
                        </v-hover>
                    </template>
                    Möchten Du diesen Benutzer wiederherstellen?<br/>
                    Aus Sicherheitsgründen erscheint ein wiederhergestellter Benutzer immer bei den Austritten und ist
                    nicht direkt aktiv.
                </ConfirmDialog>
            </div>
            <!--                <div class="action-icon" v-if="formValues.deleted_at">-->
            <!--                  <ConfirmDialog title="Austritt Bewohner">-->
            <!--                    <template #button="slotProps">-->
            <!--                      <v-hover>-->
            <!--                        <template v-slot:default="{ isHovering, props }">-->
            <!--                                 <span-->
            <!--                                   v-bind="props"-->
            <!--                                   class="cursor-pointer text-center align-center"-->
            <!--                                   @click="slotProps.open()">-->
            <!--                                  <v-img :src="isHovering ? deleteIcon : deleteIcon" alt="" style="margin:0 auto;" max-width="35"-->
            <!--                                         class="cursor-pointer"></v-img>-->
            <!--                                  <div class="block pt-0 caption text-center small">-->
            <!--                                     Austritt-->
            <!--                                  </div>-->
            <!--                                </span>-->
            <!--                        </template>-->
            <!--                      </v-hover>-->
            <!--                    </template>-->
            <!--                    Möchten Sie den Austritt für diesen Benutzer speichern?-->
            <!--                  </ConfirmDialog>-->
            <!--                </div>-->
        </div>
    </div>
</template>

<script>
import {wohneinheitenActions, wohneinheitenGetters} from "@/components/observables/wohneinheiten";
import {bewohnerActions, bewohnerGetters, bewohnerMutations} from "@/components/observables/bewohner";
import {globalRules} from "@/composables/rules";
import moment from 'moment'
import CustomDatePicker from "@/components/form/CustomDatePicker.vue";
import ConfirmDialog from "@/components/shared/ConfirmDialog.vue";
import ReEntryDialog from "@/components/Bewohner/Bewohnerverwaltung/dialogs/ReEntryDialog.vue";
import {mapGetters} from "vuex";

export default {
    name: 'Bewohnerdaten',
    components: {ConfirmDialog, CustomDatePicker, ReEntryDialog},
    props: {
        showRestoreButton: {
            required: false,
            default: false,
        },
        showDeleteButton: {
            required: false,
            default: false,
        },
        showEntryButton: {
            required: false,
            default: false,
        },
        edit: {
            required: false,
            default: false,
        },
        formValues: {
            required: true
        },
    },
    emits: ['changeValidity', 'delete', 'restore', 'reentry'],

    data() {
        return {
            valid: false,
            form: null,
            rules: globalRules,
            picker: false,
            picker2: false,
        }
    },

    watch: {
        valid: function (newState, oldState) {
            this.$emit('changeValidity', newState);
        }
    },

    computed: {
        ...wohneinheitenGetters,
        ...bewohnerGetters,
        ...mapGetters({
            'isErpSyncActive': 'benutzer/isErpSyncActive',
            'isBewohnerSyncActive' : 'benutzer/isBewohnerSyncActive',
            'settings': 'benutzer/settings',
        }),

        entryDate() {
            return this.formValues.entry_at ? moment(this.formValues.entry_at).format('DD.MM.YYYY') : ''
        },
        deletedDate() {
            return this.formValues.deleted_at ? moment(this.formValues.deleted_at).format('DD.MM.YYYY') : ''
        },
        // showRestoreButton() {
        //     return (this.edit || this.formValues.bewohnerID) && (this.formValues.deleted_at && !this.formValues.austritt && !this.isErpSyncActive)
        // },
        // showDeleteButton() {
        //     return (this.edit || this.formValues.bewohnerID) && (!this.isErpSyncActive && (!this.formValues.deleted_at || this.formValues.austritt))
        // },
        // showEntryButton() {
        //     return (this.edit || this.formValues.bewohnerID) && (this.formValues.deleted_at && this.formValues.austritt && !this.isErpSyncActive)
        // }
    },

    methods: {
        ...wohneinheitenActions,
        ...bewohnerActions,
        ...bewohnerMutations,

        open() {
            this.show = true
        },

        close() {
            this.show = false
        },

        saveAndQuit() {
            this.show = false;
        },

        asMoment(date) {
            return moment(date);
        }
    },

    created() {
        this.fetchWohneinheiten();
    },
    mounted() {
        if (this.edit || this.formValues.bewohnerID) {
            this.valid = this.$refs.form.validate()
        }
    }
}

// import deleteIcon from "/public/img/ico/loeschen.svg";
// import RestoreIcon from "/public/img/ico/plus-black.svg";
// import EntryIcon from "/public/img/ico/bewohner-hinzuefuegen2.svg";
// import ConfirmDialog from "@/components/System/ConfirmDialog";
// import {useWohneinheitenStore} from "@/store/wohneinheiten";
// import {onMounted, ref} from "vue";
// import {useRules} from "@/rules";
// import ReEntryDialog from "@/components/Bewohnerverwaltung/dialog/dialogs/ReEntryDialog.vue";
// import CustomDatePicker from "@/components/fields/CustomDatePicker.vue";
// import {useAuthStore} from "@/store/auth";
//
// const rules = useRules;
// const authStore = useAuthStore();
// const wohneinheitenStore = useWohneinheitenStore();
// const wohneinheiten = wohneinheitenStore.wohneinheiten;
//
// const valid = ref(false)
// const form = ref(null)
// const showRestoreButton = ref(false)
// const showDeleteButton = ref(false)
// const showEntryButton = ref(false)
//
// const emits = defineEmits(['changeValidity', 'delete', 'restore', 'reentry']);
//
// const props = defineProps({
//   edit: {
//     required: false,
//     default: false,
//   },
//   formValues: {
//     required: true
//   },
// });
//
// const validate = (items = []) => {
//   items.forEach((item)=> {
//     form.value.items[item].validate()
//   })
//
// }
//
// onMounted(() => {
//   //validate on load
//   if(this.edit || this.formValues.bewohnerID) {
//     form.value.validate();
//     if (this.formValues.deleted_at && !this.formValues.austritt && !authStore.isErpActive) {
//       showRestoreButton.value = true;
//     }
//     if (!authStore.isErpActive && (!this.formValues.deleted_at || this.formValues.austritt)) {
//       showDeleteButton.value = true;
//     }
//     if (this.formValues.deleted_at && this.formValues.austritt && !authStore.isErpActive) {
//       showEntryButton.value = true;
//     }
//   }
// })

</script>

<style scoped lang="scss">
.additional-actions {
  position: absolute;
  bottom: 31px;
  left: 50px;
  font-family: "Avenir LT Std Heavy";
  font-size: 16px;

  .v-img {

  }

  .action-icon {

    .caption {
      max-width: 90px !important;
      margin-top: 4px;
    }

    &.restore {
      .caption {
        max-width: 120px !important;
      }
    }
  }
}
::v-deep {
    .v-messages {
        min-height: 0 !important;
    }
}
</style>

