<template>
    <v-dialog
            v-model="show"
            width="700"
            content-class="custom-dialog"
    >
        <template v-slot:activator="{ props }">
            <slot name="button" :open="open"></slot>
        </template>

        <v-card class="main">
            <header>
                <div class="title">
                    <h1>
                        <template v-if="edit">Anschrift bearbeiten</template>
                        <template v-if="!edit">Abweichende Anschrift erfassen</template>
                    </h1>
                </div>
                <div class="close" @click="cancel()"><img :src="require('@/assets/img/ico/schliessen.svg')" width="25">
                </div>
            </header>
            <v-divider></v-divider>
            <div class="content">
                <v-form v-model="valid" ref="form" validate-on="input">
                    <v-row>
                        <v-col cols="6">
                            <v-text-field label="Vorname" variant="underlined" v-model="bewohner.vorname"
                                          :disabled="true"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Nachname" variant="underlined" v-model="bewohner.name"
                                          :disabled="true"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field label="c/o Adresse" variant="underlined"
                                          v-model="formData.Firma"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field label="Strasse *" variant="underlined" v-model="formData.Strasse"
                                          :rules="[rules.ruleNotEmpty]"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Hausnummer" variant="underlined"
                                          v-model="formData.HausNr"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="PLZ *" variant="underlined" v-model="formData.PLZ"
                                          :rules="[rules.ruleNotEmpty]"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Ort *" variant="underlined" v-model="formData.Ort"
                                          :rules="[rules.ruleNotEmpty]"></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </div>
            <div class="additional-actions" v-if="edit">
                <div class="action-icon">
                    <ConfirmDialog title="Adresse löschen" @confirm="del()">
                        <template #button>
                            <v-hover v-slot="{ hover }">
                                <div>
                                    <v-img :src="hover ? require('@/assets/img/ico/loeschen-hover.svg') : require('@/assets/img/ico/loeschen.svg')"
                                           alt="" style="margin:0 auto;" max-width="35"
                                           class="cursor-pointer"></v-img>
                                    <div class="block pt-0 caption text-center small">
                                        Löschen
                                    </div>
                                </div>
                            </v-hover>
                        </template>
                        Möchten Sie diese Adresse wirklich löschen?
                    </ConfirmDialog>
                </div>
            </div>
            <v-card-actions>
                <v-btn color="danger" elevation="0" variant="flat" rounded @click="cancel()">Abbrechen</v-btn>
                <v-btn color="primary" elevation="0" variant="flat" rounded @click="save()" :disabled="!valid">Speichern und
                    schliessen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {globalRules} from "@/composables/rules";
import ConfirmDialog from "@/components/shared/ConfirmDialog.vue";

export default {
    name: 'AnschriftDialog',
    components: {ConfirmDialog},
    emits: ['cancel', 'save', 'delete'],
    props: {
        'anschrift': {
            required: false,
            default() {
                return {}
            }
        },
        'edit': {
            required: false,
            default: false
        },
        'bewohner': {
            required: true,
        }
    },
    data() {
        return {
            show: false,
            form: null,
            valid: false,
            rules: globalRules,
            formData: {},
            anschriftBlueprint: {
                'Firma': '',
                'Strasse': '',
                'HausNr': '',
                'PLZ': '',
                'Ort': '',
            }
        }
    },
    methods: {
        open() {
            this.show = true
        },
        cancel() {
            this.show = false
            this.$emit('cancel')
        },
        del() {
            this.show = false
            this.$emit('delete')
        },
        save() {
            this.show = false
            this.$emit('save', this.formData)
        }
    },
    watch: {
        show() {
            if (this.show) {
                if (this.anschrift) {
                    this.formData = Object.assign({}, this.anschrift)
                } else {
                    this.formData = Object.assign({}, this.anschriftBlueprint)
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
.main {
  padding: 40px 50px 23px 50px;
  position: relative;
}

.title {
  h1 {
    margin: 0;
    margin-top: 0 !important;
    font-family: "Avenir LT Std";
    font-weight: 300 !important;
    font-size: 33px !important;
    line-height: 43px;
    margin-bottom: 30px;
  }
}

.close {
  position: absolute;
  top: 30px;
  right: 40px;
  cursor: pointer;
}

.v-alert {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.v-card-text {
  padding-left: 0 !important;
}


.content {
  margin-top: 30px;
  margin-bottom: 30px;
}


.v-btn--rounded {
  border-radius: 10px;
}

.deactiveIcon {
  display: block;
  float: left;
  flex: none;
  cursor: pointer;
}

.additional-actions {
  position: absolute;
  bottom: 31px;
  left: 50px;
  font-family: "Avenir LT Std Heavy";
  font-size: 16px;

  .action-icon {

    .caption {
      max-width: 90px !important;
      margin-top: 4px;
    }

    &.restore {
      .caption {
        max-width: 120px !important;
      }
    }
  }
}
</style>
