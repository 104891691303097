<template>
  <div id="wohneineheiten">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="primary--text mb-0">
            Wohneinheiten
          </h1>
          Sie können die Wohneinheiten mittels "Drag&Drop" verschieben um die Reihenfolge in der Planung anzupassen.
        </v-col>
      </v-row>
      <v-row class="mt-2 mb-6">
        <v-col cols="2">
          <v-btn @click="addWohneinheit()" small fab light depressed color="primary" class="wh-addbtn" >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn @click="saveWohneinheiten()" small fab light depressed color="primary" >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <draggable class="w-full flex flex-wrap row" :list="wohneinheiten" @change="reorder">
          <v-col cols="4"  v-for="(wh,index) in wohneinheiten" :key="'id-'+index">
            <v-card class="flex" elevation="0" outlined>
              <span class="cursor-pointer" style="position: absolute;right:10px;top:10px;">
                <span class="" style="font-size: 14px; color:#ccc; margin-right: 10px;">ID: {{ wh.id }}</span>
                  <img contain height="14" :src="require('@/assets/img/ico2023/dragdrop.svg')"/>
              </span>
              <v-col class="secondary px-6 pt-3 pb-1 cursor-pointer">
                <v-text-field v-model="wh.name" label="Name Wohneinheit*"></v-text-field>
              </v-col>
              <v-col class="pa-6 pb-16">
                <v-text-field label="Firma*" v-model="wh.Firma"></v-text-field>
                <v-text-field maxlength="40" label="zu Händen von Wohngruppe / Abteilung / Station" v-model="wh.Adresszeile1"></v-text-field>
                <v-text-field maxlength="40" label="Abladestelle" v-model="wh.Adresszeile2"></v-text-field>
                <v-text-field label="Strasse*" v-model="wh.Strasse"></v-text-field>
                <v-text-field label="PLZ*" v-model="wh.PLZ"></v-text-field>
                <v-text-field label="Ort*" v-model="wh.Ort"></v-text-field>
                <v-combobox class="notemail" v-model="wh.notificationmails"
                            label="Benachrichtigungen, E-Mail-Adressen"
                            mails
                            :error=false
                            flat
                            :rules="emailRules"
                            multiple>
                  <template v-slot:selection="data">
                    <v-chip :input-value="data.selected"
                            small
                            text-color="black"
                            class="mb-2 secondary"
                            close
                            @click:close="remove(wh,data.item)">
                      <strong>{{ data.item }}</strong>&nbsp;
                    </v-chip>
                  </template>
                </v-combobox>
                <v-text-field label="Information an Spediteur" maxlength="70" v-model="wh.Avisierung"></v-text-field>
                <input type="hidden" :value="wh.id">
                <v-hover v-slot="{ hover }" open-delay="0" >
                  <v-img v-if="hover" @click="reallyDelete=true;delWhID=wh.id" :src="require('@/assets/img/ico/loeschen-hover.svg')" alt="" max-width="30" class="mt-4 cursor-pointer"></v-img>
                  <v-img v-else @click="reallyDelete=true;delWhID=wh.id" :src="require('@/assets/img/ico/loeschen.svg')" alt="" max-width="30" class="mt-4 cursor-pointer"></v-img>
                </v-hover>
              </v-col>
            </v-card>
          </v-col>
        </draggable>
      </v-row>
    </v-container>
    <v-dialog v-model="reallyDelete" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Achtung! Sicher?</v-card-title>
        <v-card-text class="body-2">
          Möchten Sie die Daten wirklich löschen? Sollten Sie aus versehen was gelöscht haben, bitte kontaktieren Sie den Support.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="reallyDelete = false;deleteWhFromList(delWhID)">Ja, löschen</v-btn>
          <v-btn color="primary" depressed @click="reallyDelete = false">NEIN</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {wohneinheitenGetters, wohneinheitenActions, wohneinheitenMutations} from '../components/observables/wohneinheiten';
import draggable from 'vuedraggable';
import httpClient from "../utils/httpClient";
export default {
  name: 'Wohneinheiten',
  components: { draggable },
  data() {
    return {
      sortlist: [],
      emailRules :[
        v => {
          if (!v || v.length === 1) {
            return true;
          }else if (v.length > 0) {
            for (let i = 0; i < v.length; i++) {
              if (!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(v[i]))) {
                return 'keine gültige E-Mail-Adresse (mindestens eine Adresse wird so nicht gespeichert)';
              }
            }
          }
          return true;

        }
      ],
      reallyDelete: false,
      panel: 0,
      delWhID: 0,
    }
  },
  computed:{
    ...wohneinheitenGetters
  },
  methods:{
    ...wohneinheitenActions,
    ...wohneinheitenMutations,

    async reorder(e){

      let x = 0;
      let ordererdWH = [];
      this.wohneinheiten.forEach(function(wh){
        ordererdWH.push({
          'id': wh.id,
          'sort': x
        })
        x++;
      })

      const res = await httpClient({
        url: process.env.VUE_APP_API_SHARED+'save/wohneinheiten-reorder',
        method: 'post',
        data: { 'ordered': ordererdWH }
      })

      if(res.type === 'success'){
        wohneinheitenMutations.setWohneinheiten(res.wohneinheiten)
      }
    },

    remove(wh,item) {
      wh.notificationmails.splice(wh.notificationmails.indexOf(item), 1)
      wh.notificationmails = [...wh.notificationmails]
    },


    deleteWhFromList(whID){
      this.deleteWohneinheit(whID);
    },


  },
  created() {
    this.fetchWohneinheiten();
  },

  /*beforeRouteLeave(to,from,next){
    this.saveWohneinheiten();
    next();
  }*/
}
</script>
<style lang="scss">
.wh-addbtn{
  margin-right: 15px !important;
}
.notemail .mdi-menu-down{
  display: none !important;
}
#wohneineheiten{
  .v-chip{
    opacity: 0.7;
  }
}

.notemail .v-label--active {
  top: -3px !important;

}
</style>
