import httpClient from "@/utils/httpClient";

export async function print(module, data, orientation, callFunction = false, callService = false, callParams = false, sort = false) {
    const resCheck = await httpClient({
        url: process.env.VUE_APP_API_SHARED + 'print',
        method: 'post',
        responseType: 'blob',
        data: {module: module, data: data, format: orientation, callFunction: callFunction, callParams: callParams, callService: callService, tool: 'checklist'}
    })

    if (resCheck !== 'false' && resCheck.size > 1000) {

        const fileURL = window.URL.createObjectURL(new Blob([resCheck], {type: 'application/pdf'}));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', module+'.pdf');
        document.body.appendChild(fileLink);

        fileLink.click();
    }
}