<template>
  <v-app>
    <Loader :visible="loading"/>
    <v-app-bar class="app-bar" height="116" app extension-height="120" color="white" v-if="isLoggedIn() && this.$route.name != 'login' && this.$route.name != 'passwortreset'" style="z-index: 98">
      <v-container>
        <v-row>
          <v-col cols="3" class="text-vertical-align">
            <v-row class="d-none d-lg-flex">
              <v-col cols="1" class="icon-container text-vertical-align">
                <router-link to="/verbrauchsliste">
                  <v-img :src="require('@/assets/img/ico/planung.png')" alt="" width="32"></v-img>
                </router-link>
              </v-col>
              <v-col cols="10" class="pl-0 name-container text-vertical-align">
                <div>
                  <router-link to="/verbrauchsliste" class="kunden-name" :class="{'small-text': this.$store.state.benutzer.info.firma.length > 30}">
                    {{ this.$store.state.benutzer.info.firma }}
                  </router-link>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
          </v-col>
          <v-col cols="3" class="logo-container">
            <span>
              <router-link to="/verbrauchsliste" class="logo">
                <v-img :src="require('@/assets/img/logo-checklist.svg')" alt="" width="285" height="72"
                       class="mt-1"></v-img>
              </router-link>
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-main class="app-main" :class="this.$route.name != 'login' && this.$route.name != 'passwortreset' ? 'pb-16' : 'pb-0'"
            :style="wrongSettings ? 'display:none !important' : '' "
    >
      <v-layout>
        <Navigation v-if="this.$route.name != 'login' && this.$route.name != 'passwortreset'" :superadmin="this.$store.state.benutzer.info.superadmin" :tooladmin="this.$store.state.benutzer.info.tooladmin"></Navigation>
      </v-layout>
      <router-view :style="$router.currentRoute.name != 'login' ? 'margin:0 110px' : ''"/>
    </v-main>
    <div id="settingserror" class="d-print-none app-error px-8" style="padding-top: 100px" :style="wrongSettings ? 'display:block !important' : '' ">
      <v-container>
        <v-row>
          <v-col>
            <v-img width="300" height="87" :src="require('@/assets/img/logo-checklist.svg')" alt="" class="mt-16 my-0 py-0"></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="mb-2">
              Warnung
            </h2>
            <div style="font-size: 20px">
              Um mit dem Puras Verbrauchsmanager zu arbeiten, verwenden Sie bitte einen grösseren Bildschirm<span v-if="wrongSettings"> und einen der folgenden Browser: Chrome, Firefox, Safari, Edge, Brave</span>.<br><br>
              <span class="caption" style="font-size: 14px !important">Puras AG – Normannenstrasse 8 – 3018 Bern –
              Telefon 031 996 85 85 –
                <a href="mailto:contact@puras.ch">contact@puras.ch</a></span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-snackbar v-model="showmsg" timeout="3500" color="secondary" class="black--text" v-if="msg != 'Unauthenticated.'">
      <v-row dense>
        <v-col cols="1">
          <v-icon size="30" v-if="msgtype == 'error'" color="error" class="pt-2">mdi-alert</v-icon>
          <v-icon size="30" v-if="msgtype == 'success'" color="green" class="pt-0">mdi-check-circle</v-icon>
        </v-col>
        <v-col cols="10" class="body-1">
          <div class="pl-4 pt-1 block black--text">
            <template v-if="Array.isArray(msg)">
              <div v-for="(line, i) in msg" :key="'error-' + i">
                {{ line }}
              </div>
            </template>
            <template v-else>
              {{ msg }}
            </template>
          </div>
        </v-col>
      </v-row>
    </v-snackbar>
  </v-app>
</template>
<script>

import Loader from "@/components/layout/Loader";
import Navigation from "@/components/layout/Navigation";
import {mapState} from "vuex";
import httpClient from "./utils/httpClient";
import {isLoggedIn} from '@/utils/auth';

export default {
  name: 'App',
  components: {
    Loader, Navigation
  },
  computed: {
    ...mapState("loader", ["loading", 'msg', 'msgtype']),

    wrongSettings() {
      return this.$browserDetect.isIE || this.$browserDetect.isOpera;
    },

    showmsg: {
      get() {
        return this.$store.state['loader'].showmsg
      },
      set(newValue) {
        return this.$store.commit('loader/setmsgvisible', newValue)
      }
    },
  },
  methods: {
    isLoggedIn() {
      return 1;
      //return isLoggedIn()
    }
  },
}
</script>
<style lang="scss">
@import './scss/custom';

h1 {
  margin-top: 30px;
}

.name-container {
  flex: 0 0 calc(100% - 63px);
  max-width: calc(100% - 63px);
}

.kunden-name {
  font-size: 25px;
  color: #000 !important;
  text-decoration: none !important;

  &.small-text {
    font-size: 20px;
  }
}

#settingserror {
  height: 100%;
  background-image: url("./assets/img/login-bg-2021.jpg");
  background-size: cover;
  background-position: left center;
}

#maincontent {
  height: 100%;
}

.v-snack__wrapper {
  box-shadow: none !important;
}

.logo-container {
  position: relative;
}

.icon-container {
  position: relative;
  top: -5px;
  flex: 0 0 63px;
  max-width: 63px;
}

.logo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
</style>
