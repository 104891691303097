<template>
  <div id="smart-logistic-settings" class="mt-3">
    <v-row>
      <v-col cols="12">
        <div class="custom-box">
          <div class="title">
            <h2>Smart Logistics</h2>
          </div>
          <div class="content">
            <v-row>
              <v-col cols="3">
                <h4>ERP-System</h4>
                <v-select
                    v-model="$store.state.smartLogisticSettings.model"
                    :items="$store.state.smartLogisticSettings.blueprint.model"
                    item-text="label"
                    item-value="value"
                />
              </v-col>
              <v-col cols="6">
                <div class="pt-11 pl-4">
                  <v-switch v-model="$store.state.smartLogisticSettings.allowImmediateDelivery" :true-value="1" :false-value="0"
                              label="Sofortbestellung ermöglichen"></v-switch>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="custom-box">
          <div class="title">
            <h2>Bestelloptionen</h2>
          </div>

        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>

export default {
  name: 'SmartLogisticsSettings',
  data() {
    return {


    }
  },

  mounted () {
    this.$store.dispatch('smartLogisticSettings/fetchLivingUnitsWithSettings');
  }
}
</script>