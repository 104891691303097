<template>
  <v-dialog
      v-model="show"
      persistent
      width="600"
      content-class="custom-dialog"
  >
    <template v-slot:activator="{ props }">
      <slot name="button" :open="open"/>
    </template>

    <v-card
        class="main rounded-xl"
    >
      <v-alert :type="type" v-if="type">{{ title }}</v-alert>
      <v-card-title v-else class="pl-0 text-primary text-h5">
        {{ title }}
      </v-card-title>
      <v-card-text>
          <slot/>
        <br/>
          <v-date-picker
                  v-model="entryAt"
                  locale="ch-de"
                  :rules="[rules.ruleNotEmpty]"
          />
      </v-card-text>
      <v-card-actions class="justify-end text-right">
        <v-btn color="danger" variant="flat" rounded @click="cancel">Abbrechen</v-btn>

        <slot name="confirm" :confirm="confirm">
          <v-btn color="primary" variant="flat" rounded @click="confirm">Ok</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import {globalRules} from "@/composables/rules";
import moment from "moment";

export default {
    name: 'ReEntryDialog',
    emits: ['cancel', 'confirm'],
    props: {
        title: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            show: false,
            rules: globalRules,
            entryAt: moment().format("YYYY-MM-DD")
        }
    },
    methods: {
        open() {
            this.show = true
        },
        cancel() {
            this.show = false
            this.$emit('cancel')
        },
        confirm() {
            this.show = false
            this.$emit('confirm', this.entryAt)
        }
    }
}
</script>

<style scoped lang="scss">
.v-alert {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.v-card-text {
  padding-left: 0 !important;
}

.main {
  padding: 30px 40px 15px 40px;
}

.v-btn--rounded {
  border-radius: 10px;
}


</style>
