import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);


const vuetify = new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#CE20A8',
                secondary: '#f4ede7',
                greenlight: '#F0BCE5',
                beige: '#F4EDE7',
                leightbeige: '#f9f5f2',
                rosa: '#EBCDCE',
                red: '#BE4648',
                accent: '#000000',
                gray: '#E6E6E6',
                lightgray: '#eeeeee',
                darkgray: '#C7C5C5',
                error: '#b71c1c',
            },
        }
    },
});


import VuetifyConfirm from 'vuetify-confirm'
Vue.use(VuetifyConfirm, { vuetify })

export default vuetify;
