import Vue from 'vue'
import httpClient from "@/utils/httpClient";

const anschriftenState = Vue.observable({
    loadingAnschriften: false,
    anschriften: []
});


export const anschriftenGetters = {
    anschriften: () => {
        return anschriftenState.anschriften
    },
    byBewohnerId: (state) => (id) => anschriftenState.anschriften?.find(an => an.id === +id),
}

export const anschriftenMutations = {
    setAnschriften(anschriften) {
        anschriftenState.anschriften = anschriften;
    }
}

export const anschriftenActions = {
    fetchAnschriften(bewohnerId) {
        if (!this.loadingAnschriften) {
            this.loadingAnschriften = true
            httpClient({
                url: process.env.VUE_APP_API_SHARED + 'get/bewohner/anschriften/' + bewohnerId,
                method: 'get',
            })
                .then(response => {
                    if (response.type === "success") {
                        anschriftenMutations.setAnschriften(response.anschriften);
                    }
                })
                .finally(() => {
                    this.loadingAnschriften = false
                })
        }
    },


    addAnschrift(anschrift, bewohnerId) {
        return new Promise((resolve, reject) => {
            httpClient({
                url: process.env.VUE_APP_API_SHARED + 'save/bewohner/anschriften/' + bewohnerId,
                method: 'POST',
                data: {
                    anschrift: anschrift
                }
            }).then((response) => {
                anschriftenMutations.setAnschriften(response.anschriften);
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    updateAnschrift(anschrift, bewohnerId) {
        return new Promise((resolve, reject) => {
            httpClient({
                url: process.env.VUE_APP_API_SHARED + 'update/bewohner/anschriften/' + bewohnerId + '/' + anschrift.id,
                method: 'PUT',
                data: {
                    anschrift: anschrift
                }
            }).then((response) => {
                anschriftenMutations.setAnschriften(response.anschriften);
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    deleteAnschrift(anschriftId, bewohnerId) {
        return new Promise((resolve, reject) => {
            httpClient({
                url: process.env.VUE_APP_API_SHARED + 'delete/bewohner/anschriften/' + bewohnerId + '/' + anschriftId,
                method: 'DELETE',
            }).then((response) => {
                anschriftenMutations.setAnschriften(response.anschriften);
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
}

export default {
    anschriftenState: anschriftenState,
    anschriftenGetters,
    anschriftenMutations,
    anschriftenActions
}
