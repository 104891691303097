<template>
  <div>
    <div v-if="value" class="selected-file">
      <span>{{ value.name }}</span>
      <v-hover v-slot="{ hover }">
        <v-img
            :src="hover ? require('@/assets/img/ico/loeschen-hover.svg') : require('@/assets/img/ico/loeschen.svg')"
            max-width="20"
            max-height="25"
            class="icon"
            @click="clear"
        ></v-img>
      </v-hover>
    </div>
    <div v-else>
      <label>
        <input
            type="file"
            :accept="accept"
            @change="update"
            :disabled="disabled"
        />
        <v-hover v-slot="{hover}">
          <v-img
              :src="hover ? require('@/assets/img/ico2023/upload-hover.svg') : require('@/assets/img/ico2023/upload.svg')"
              :contain="true"
              alt=""
              class="icon"
              max-width="25"
          ></v-img>
        </v-hover>
        <span>{{ label }}</span>
      </label>

      <ul v-if="hasErrors" class="error-list">
        <li v-for="error in errors" :key="error">
          <span v-if="error === 'file-size-exceeded'">Datei ist zu gross (max. {{ fileSize }}KB)</span>
          <span v-if="error === 'file-type-not-allowed'">Dateityp nicht erlaubt</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomFileUpload',

  emits: ['input'],

  props: {
    value: {
      default: null,
    },
    label: {
      type: String,
      required: true
    },
    accept: {
      type: String,
      // pdf and images
      default: 'application/pdf,image/jpeg,image/png'
    },
    fileSize: { // in KB
      type: Number,
      default: 2024
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      errors: []
    }
  },

  computed: {
    hasErrors() {
      return this.errors.length > 0
    }
  },

  methods: {
    update: function (e) {
      if(this.disabled) {
        return
      }

      this.errors = []

      if(e.target.files[0].size > this.fileSize *1024) {
        this.errors.push('file-size-exceeded')
      }

      if(!this.accept.split(',').includes(e.target.files[0].type)) {
        this.errors.push('file-type-not-allowed')
      }

      if(this.errors.length === 0) {
        this.$emit('input', e.target.files[0])
      } else {
        this.clear()
      }
    },

    clear() {
      if(!this.disabled) {
        this.$emit('input', null)
      }
    }
  },
}
</script>

<style scoped lang="scss">
.selected-file {
  display: flex;
  gap: 1rem;
  align-items: center;
  font-size: 14px;

  span {
    word-break: break-all;
  }
}

label {
  display: flex;
  gap: 1rem;
  cursor: pointer;
  outline: none;
  width: 150px;

  input {
    display: none;
  }

  span {
    font-size: 14px;
  }
}

.error-list {
  margin: 0;
  padding: 5px;
  list-style: none;
  color: #b71c1c;
}
</style>