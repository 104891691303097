<template>
  <v-chip small :color="statusColor" style="font-size: 14px; pointer-events: none;">
    {{ statusText }}
  </v-chip>
</template>
<script>

export default {
  name: "Status",

  props: {
    cart: {
      type: Object,
      required: true
    },
  },

  computed: {
    statusColor() {
      if (this.cart.status === 'sent') {
        return 'primary'
      } else if (this.cart.status === 'prepared') {
        if(this.hasErrors) {
          return 'light-grey'
        }

        return 'beige'
      } else if (this.cart.status === 'declined') {
        return 'rosa'
      }
    },

    status() {
      var status = this.cart.status

      if(status === 'prepared' && this.hasErrors) {
        status = 'flawed'
      }

      return status
    },

    statusText() {

      return this.$t('direktabrechnung.status.' + this.status)
    },

    hasErrors() {
      return this.cart.validation && this.cart.validation.length > 0
    }
  }
}
</script>