<template>
  <v-dialog
      v-model="show"
      persistent
      width="660"
  >
    <template v-slot:activator="{ props }">
      <slot name="button" :open="open"></slot>
    </template>
    <v-card class="main" v-if="data">
      <header>
        <div class="title">
          <h1>
            <template v-if="edit">
              <span class="name">{{ data.name }}</span><br/>
              bearbeiten
            </template>
            <template v-else>
              Neuer Arzt erfassen
            </template>
          </h1>
        </div>
        <div class="close" @click="cancel()"><img :src="require('@/assets/img/ico/schliessen.svg')" width="25">
        </div>
      </header>
      <v-form v-model="valid" ref="form" validate-on="input" @update:modelValue="$emit('changeValidity', $event)">
        <v-row>
          <v-col cols="12">
            <v-switch label="Als Standard festlegen" :true-value="1" color="primary"
                      :false-value="0" v-model="data.default"></v-switch>
          </v-col>
          <v-col cols="12">
            <v-text-field label="Praxisname*" variant="underlined" v-model="data.name"
                          :rules="[rules.ruleNotEmpty]"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field label="Strasse*" variant="underlined" v-model="data.strasse"
                          :rules="[rules.ruleNotEmpty]"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field label="Haus-Nr." variant="underlined" v-model="data.hausnr" ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field label="PLZ*" variant="underlined" v-model="data.plz"
                          :rules="[rules.ruleNotEmpty]"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field label="Ort*" variant="underlined" v-model="data.ort"
                          :rules="[rules.ruleNotEmpty]"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field label="E-Mail" variant="underlined" v-model="data.email"
                          :rules="[rules.ruleEmail]"></v-text-field>
          </v-col>
          <v-col cols="6">
          </v-col>
          <v-col cols="6">
            <v-text-field label="GLN*" variant="underlined" v-model="data.gln"
                          :rules="[rules.ruleNotEmpty]"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field label="ZSR*" variant="underlined" v-model="data.zsr"
                          :rules="[rules.ruleNotEmpty, rules.ruleZSR]"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" offset="6">
            <p class="float-right"><a href="https://www.sasis.ch/zsr/de/kurzversion" target="_blank">SASIS
              Ärzteverzeichnis</a></p>
          </v-col>
        </v-row>
      </v-form>
      <div class="additional-actions" v-if="edit">
        <div class="action-icon">
          <ConfirmDialog title="Arzt löschen" @confirm="deleteArzt()">
            <template #button>
              <v-hover v-slot="{ hover }">
                                <span class="cursor-pointer text-center align-center"
                                      @click="slotProps.open()">
                      <v-img :src="hover ? require('@/assets/img/ico/loeschen-hover.svg') : require('@/assets/img/ico/loeschen.svg')"
                             alt="" style="margin:0 auto;" max-width="35"
                             class="cursor-pointer"></v-img>
                                <div class="block pt-0 caption text-center small">
                                    Löschen
                                </div>
                    </span>
              </v-hover>
            </template>
            Möchten Sie diesen Arzt wirklich löschen?
          </ConfirmDialog>
        </div>
      </div>
      <v-card-actions>
        <v-btn color="danger" variant="flat" rounded @click="cancel()">Abbrechen</v-btn>
        <v-btn color="primary" variant="flat" rounded @click="save()" :disabled="!valid">Speichern und
          schliessen
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
import { globalRules } from '@/composables/rules'
import ConfirmDialog from '@/components/shared/ConfirmDialog.vue'

export default {
  name: 'ArztDialog',
  components: { ConfirmDialog },
  emits: ['cancel', 'save', 'delete'],
  props: {
    arzt: {
      required: false
    },
    edit: {
      required: false,
      default: false
    }
  },
  data () {
    return {
      rules: globalRules,
      show: false,
      valid: false,
      form: null,
      data: null,
      arztBlueprint: {
        name: null,
        strasse: null,
        hausnr: null,
        plz: null,
        ort: null,
        email: null,
        gln: null,
        zsr: null,
        default: false,
      },
    }
  },
  methods: {
    open () {
      this.show = true
      window.setTimeout(() => {
        if (this.arzt) {
          this.form.validate()
        }
      }, 100)
    },

    cancel () {
      this.show = false
      this.$emit('cancel')
    },

    deleteArzt () {
      this.show = false
      this.$emit('delete')
    },

    save () {
      this.show = false
      this.$emit('save', this.data)
    }
  },

  watch: {
    show () {
      if (this.show) {
        if (this.arzt) {
          this.data = { ...this.arzt }
        } else {
          this.data = Object.assign({}, this.arztBlueprint)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  padding: 40px 50px 23px 50px;
}

.title {
  h1 {
    margin: 0;
    margin-top: 0 !important;
    font-family: "Avenir LT Std";
    font-weight: 300 !important;
    font-size: 33px !important;
    line-height: 43px;
    margin-bottom: 30px;
  }
}

.close {
  position: absolute;
  top: 30px;
  right: 40px;
  cursor: pointer;
}

.v-alert {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.v-card-text {
  padding-left: 0 !important;
}

.v-card__actions {
  padding: 0 !important;
  padding-top: 10px !important;
  justify-content: right;
}

.hidden {
  opacity: 0;
  height: 0;
  overflow: hidden;
  padding: 0 !important;
  margin: 0 !important;
}

.additional-actions {
  position: absolute;
  bottom: 31px;
  left: 50px;
  font-family: "Avenir LT Std Heavy";
  font-size: 16px;

  .v-img {

  }

  .action-icon {

    .caption {
      max-width: 90px !important;
      margin-top: 4px;
    }

    &.restore {
      .caption {
        max-width: 120px !important;
      }
    }
  }
}

.title {
  h1 {
    line-height: 1.1em;

    .name {
      color: #CE20A8;
    }
  }
}
</style>
