import Vue from 'vue'
import httpClient from "@/utils/httpClient";

const migelPauschalKategorienState = Vue.observable({
    migelPauschalKategorien: []
});


export const migelPauschalKategorienGetters = {
    migelPauschalKategorien: () => {
        return migelPauschalKategorienState.migelPauschalKategorien
    },
}

export const migelPauschalKategorienMutations = {
    setMigelPauschalKategorien(migelPauschalKategorien) {
        migelPauschalKategorienState.migelPauschalKategorien = migelPauschalKategorien;
    }
}

export const migelPauschalKategorienActions = {
    async fetchMigelPauschalKategorien() {
        const res = await httpClient({
            url: process.env.VUE_APP_API_SHARED + 'get/migel-pauschal-kategorien',
            method: 'get',
        })

        if (res.type === "success") {
            migelPauschalKategorienMutations.setMigelPauschalKategorien(res.migelPauschalKategorien)
        }
    },
}

export default {
    migelPauschalKategorienState: migelPauschalKategorienState,
    migelPauschalKategorienGetters,
    migelPauschalKategorienMutations,
    migelPauschalKategorienActions
}
