<template>
  <v-dialog
      v-model="dialog"
      persistent
      width="1100"
      content-class="custom-dialog"
  >
    <template v-slot:activator="{ props }">
      <slot name="button" :open="open"></slot>
    </template>

    <v-card class="main" v-if="dialog">
      <header>
        <div class="title">
          <h1>Neues Rezept hinzufügen
            <template v-if="bewohnerData">-<br><span class="primary--text">{{
                bewohnerData.vorname + ' ' + bewohnerData.name
              }}</span>
            </template>
          </h1>
        </div>
        <div class="close" @click="cancel()">
          <img :src="require('@/assets/img/ico/schliessen.svg')" width="25"/>
        </div>
      </header>

      <v-divider></v-divider>

      <div class="content my-8">
        <v-form v-model="formIsValid" ref="form" validate-on="input" v-if="editablePrescription">
          <v-row>
            <v-col cols="4">
              <v-text-field
                  v-model="editablePrescription.name"
                  label="Rezeptname"
                  class="pt-0"
                  :rules="[ruleRequired]"
                  :disabled="loading"
              />
            </v-col>
            <v-col cols="4">
              <CustomDatePicker
                  label="Beginnend ab"
                  name="from"
                  :formValues="editablePrescription"
                  :rules="[ruleRequired]"
                  :disabled="loading"
              />
            </v-col>
            <v-col cols="4" class="d-flex align-self-end justify-center">
              <CustomFileUpload
                  v-model="editablePrescription.asset"
                  label="Rezept hochladen"
                  class="mb-6"
                  style="width: 180px;"
                  :disabled="loading"
                  @input="validate"
              />
            </v-col>
          </v-row>
        </v-form>
      </div>

      <v-card-actions>
        <v-btn color="danger" elevation="0" variant="flat" rounded @click="cancel()" :disabled="loading">Abbrechen</v-btn>
        <v-btn color="primary" elevation="0" variant="flat" rounded @click="save()" :disabled="!valid" :loading="loading">Speichern und
          schliessen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomDatePicker from '@/components/form/CustomDatePicker.vue'
import { globalRules } from '@/composables/rules'
import CustomFileUpload from '@/components/form/CustomFileUpload.vue'

export default {
  name: 'MedicalPrescriptionUploadDialog',
  components: { CustomFileUpload, CustomDatePicker },

  props: {
    bewohnerData: {
      type: Object,
      required: false,
    },
    prescription: {
      type: Object,
      default: null,
    }
  },

  data () {
    return {
      ...globalRules,
      dialog: false,
      loading: false,
      formIsValid: false,
      valid: false,
      editablePrescription: null,
      prescriptionBlueprint: {
        name: '',
        from: '',
        till: '',
        asset: null,
      },
    }
  },

  methods: {
    /**
     *
     */
    generatePrescription () {
      this.errors = []

      if (this.prescription) {
        this.editablePrescription = Object.assign({}, this.prescriptionBlueprint, this.prescription)
      } else {
        this.editablePrescription = Object.assign({}, this.prescriptionBlueprint)
      }
    },

    /**
     *
     */
    open () {
      this.dialog = true
    },

    /**
     *
     */
    cancel () {
      if (!this.loading) {
        this.dialog = false
        this.$emit('cancel')
      }
    },

    /**
     *
     */
    save () {
      this.loading = true

      const formData = new FormData()
      formData.append('name', this.editablePrescription.name)
      formData.append('from', this.editablePrescription.from)
      formData.append('asset', this.editablePrescription.asset)

      this.$store.dispatch('prescription/addPrescription', { bewohnerId: this.bewohnerData.bewohnerID, formData: formData })
          .then(() => {
            this.dialog = false
          })
          .finally(() => {
            this.loading = false
            this.$emit('saved')
          })
    },

    validate() {
      this.valid = !!(this.formIsValid && this.editablePrescription.asset && this.editablePrescription.asset instanceof File)
    }
  },

  watch: {
    dialog () {
      if (this.dialog) {
        this.generatePrescription()
      }
    },

    formIsValid() {
      this.validate()
    }
  },

  created () {
    this.generatePrescription()
  }
}
</script>

<style scoped lang="scss">
.main {
  padding: 40px 50px 23px 50px;
}

.title {
  h1 {
    margin: 0;
    margin-top: 0 !important;
    font-family: "Avenir LT Std";
    font-weight: 300 !important;
    font-size: 33px !important;
    line-height: 43px;
    margin-bottom: 30px;
  }
}

.close {
  position: absolute;
  top: 30px;
  right: 40px;
  cursor: pointer;
}

.v-card-text {
  padding-left: 0 !important;
}


</style>