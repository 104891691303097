import httpClient from '../../utils/httpClient'

export const tree = {
  namespaced: true,

  state: {
    livingUnitsWithResidents: {
      children: []
    },
    dates: []
  },

  getters: {
    livingUnitsWithResidents: (state) => state.livingUnitsWithResidents,

    /**
     *
     * @param state
     * @returns {function(*): *|*[]}
     */
    data: (state) => (dateString) => {
      const date = state.dates.find(d => d.dateString === dateString)

      return date ? date.data : []
    },

    dataTree: (state) => (dateString) => {
      const date = state.dates.find(d => d.dateString === dateString)

      const data = date ? date.data : []

      // merge living units with residents data
      const dataTree = []

      state.livingUnitsWithResidents.children.forEach(livingUnit => {
        var livingUnitData = Object.assign({}, livingUnit)
        livingUnitData.children = data.filter(d => d.wohneinheiten_id === livingUnitData.internalID)

        dataTree.push(livingUnitData)
      })

      return dataTree
    },

    /**
     *
     * @param state
     * @returns {(function(*, *): ([]|*))|*}
     */
    livingUnitData: (state) => (id, dateString) => {
      const date = state.dates.find(d => d.dateString === dateString)

      if (!date)
        return []

      return date.data.filter(d => d.wohneinheiten_id === id)
    },

    /**
     *
     * @param state
     * @returns {(function(*, *): ([]|*))|*}
     */
    residentData: (state) => (dateString, id) => {
      const date = state.dates.find(d => d.dateString === dateString)

      if (!date)
        return null

      return date.data.find(d => d.internalID === id) ?? null
    }
  },

  actions: {
    /**
     * @param commit
     */
    fetchLivingUnits ({ commit }, dateString) {
      httpClient.get(process.env.VUE_APP_API_SHARED + 'get/living-units-with-residents/' + dateString)
        .then(response => {
          commit('setLivingUnitsWithResidents', response.tree)
        })
    },

    /**
     * @todo
     */
    fetchResidentTree ({ commit }, { residentId, dateString }) {

    },

    /**
     *
     * @param commit
     * @param livingUnitId
     * @param dateString
     */
    fetchLivingUnitTree ({ commit }, { livingUnitId, dateString }) {
      httpClient.get(process.env.VUE_APP_API_SHARED + `tree/living-unit/${livingUnitId}/${dateString}`)
        .then(response => {
          commit('setLivingUnitData', {
            dateString: dateString,
            livingUnitTree: response
          })
        })
    },

    /**
     *
     * @param commit
     * @param dateString
     */
    fetchWholeTree ({ commit }, dateString) {
      httpClient.get(process.env.VUE_APP_API_SHARED + `tree/full/${dateString}`)
        .then(response => {
          commit('setTreeData', {
            dateString: dateString,
            tree: response
          })
        })
    },

    /**
     *
     * @param commit
     * @param prodId
     * @param bewohnerID
     * @param dateString
     * @returns {Promise<unknown>}
     */
    removeArticle ({ commit, state }, { prodId, bewohnerID, dateString }) {
      return new Promise((resolve, reject) => {
        httpClient.post(process.env.VUE_APP_API_CHECKLIST + 'remove/product', {
          monthYear: dateString,
          prodID: prodId,
          bewohnerID: bewohnerID,
        })
          .then(response => {
            if (response.type === 'success') {
              // reset all dates as this may have influence on other dates
              commit('resetDates', dateString)

              commit('setResidentData', {
                dateString: dateString,
                residentTree: response.tree
              })
            }

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /**
     *
     * @param commit
     * @param prodId
     * @param internalID
     * @param targetType
     * @param dateString
     * @returns {Promise<unknown>}
     */
    assignArticle ({ commit }, { prodId, internalID, targetType, dateString }) {
      return new Promise((resolve, reject) => {
        httpClient.post(process.env.VUE_APP_API_CHECKLIST + 'assign/product', {
          monthYear: dateString,
          prodID: prodId,
          internalID: internalID,
          targetType: targetType
        })
          .then(response => {
            if (response.type === 'success') {
              // reset all dates as this may have influence on other dates
              commit('resetDates', dateString)

              if (targetType === 'bewohner') {
                commit('setResidentData', {
                  dateString: dateString,
                  residentTree: response.tree
                })
              } else if (targetType === 'wohneinheit') {
                commit('setLivingUnitData', {
                  dateString: dateString,
                  livingUnitTree: response.tree
                })
              } else if (targetType === 'kunde') {
                commit('setTreeData', {
                  dateString: dateString,
                  tree: response.tree
                })
              }
            }

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updateInterval ({ commit }, { dateString, residentId, article }) {
      return new Promise((resolve, reject) => {
        httpClient.put(process.env.VUE_APP_API_SHARED + `tree/interval`, {
          dateString: dateString,
          residentId: residentId,
          artnr: article.internalID,
          count: article.currPlandaten.count,
          interval: article.currPlandaten.intervall,
        })
          .then(response => {
            if(response.type === 'success') {
              // reset all dates as this may have influence on other dates
              commit('resetDates', dateString)
            }
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  },

  mutations: {
    /**
     *
     * @param state
     * @param livingUnitsWithResidents
     */
    setLivingUnitsWithResidents (state, livingUnitsWithResidents) {
      state.livingUnitsWithResidents = livingUnitsWithResidents
    },

    /**
     *
     * @param state
     * @param keepCurrent
     */
    resetDates (state, curDate=null) {
      // preserve current date if given
      if(curDate) {
        state.dates = state.dates.filter(date => date.dateString == curDate)
      } else {
        state.dates = []
      }
    },

    /**
     *
     * @param state
     * @param dateString
     * @param tree
     */
    setTreeData (state, { dateString, tree }) {
      const datePos = state.dates.findIndex(date => date.dateString === dateString)

      if (datePos === -1) {
        const treeData = {
          dateString: dateString,
          data: []
        }

        tree.children.forEach(livingUnitTree => {
          livingUnitTree.children.forEach(residentTree => {
            treeData.data.push(residentTree)
          })
        })

        state.dates.push(treeData)
      } else {
        tree.children.forEach(residentTree => {
          const dataPos = state.dates[datePos].data.findIndex(r => r.internalID === residentTree.internalID)

          if (dataPos === -1) {
            state.dates[datePos].data.push(residentTree)
          } else {
            state.dates[datePos].data.splice(dataPos, 1, residentTree)
          }
        })
      }
    },

    /**
     *
     * @param state
     * @param dateString
     * @param livingUnitTree
     */
    setLivingUnitData (state, { dateString, livingUnitTree }) {
      const datePos = state.dates.findIndex(d => d.dateString === dateString)

      if (datePos === -1) {
        state.dates.push({
          dateString: dateString,
          data: livingUnitTree.children ?? []
        })
      } else {
        livingUnitTree.children.forEach(residentTree => {
          const dataPos = state.dates[datePos].data.findIndex(r => r.internalID === residentTree.internalID)

          if (dataPos === -1) {
            state.dates[datePos].data.push(residentTree)
          } else {
            state.dates[datePos].data.splice(dataPos, 1, residentTree)
          }
        })
      }
    },

    /**
     *
     * @param state
     * @param dateString
     * @param residentTree
     */
    setResidentData (state, { dateString, residentTree }) {
      if (!residentTree) {
        console.warn('No residentTree data provided')
        return
      }

      const datePos = state.dates.findIndex(date => date.dateString === dateString)

      if (datePos === -1) {
        state.dates.push({
          dateString: dateString,
          data: [residentTree]
        })
      } else {
        const dataPos = state.dates[datePos].data.findIndex(r => r.internalID === residentTree.internalID)

        if (dataPos === -1) {
          state.dates[datePos].data.push(residentTree)
        } else {
          state.dates[datePos].data.splice(dataPos, 1, residentTree)
        }
      }
    },
  },

  persist: false
}