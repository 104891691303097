<template>
  <div id="bewohner" v-if="wohneinheiten.length > 0">
    <div style="position: fixed;width: 100%; top: 0px; z-index: 98; left: 0px; height: 116px;"
         class="text-vertical-align">
      <v-container>
        <v-row>
          <v-col cols="3" class="text-vertical-align">
            <v-row class="d-none d-lg-flex">
              <v-col cols="1" class="icon-container text-vertical-align" style="background-color: white">
                <router-link to="/verbrauchsliste">
                  <v-img :src="require('@/assets/img/ico/planung.png')" alt="" width="32"></v-img>
                </router-link>
              </v-col>
              <v-col cols="10" class="pl-0 name-container text-vertical-align"
                     style="background-color: white">
                <div>
                  <router-link to="/verbrauchsliste" class="kunden-name"
                               :class="{'small-text': this.$store.state.benutzer.info.firma.length > 30}">
                    {{ this.$store.state.benutzer.info.firma }}
                  </router-link>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="text-vertical-align">
            <v-text-field v-model="search" clearable light
                          placeholder="Suche nach Name, Vorname, Zimmer"
                          autocomplete="off"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container>
      <v-row class="mb-0">
        <v-col cols="12">
          <h1 class="primary--text mb-0">
            Bewohnerverwaltung
          </h1>
          <div v-if="$store.state.benutzer.settings.erp !== 'none' && $store.state.benutzer.settings.erp !== 'abacus'">
            Bewohnerdaten müssen in ihrem Verwaltungs-System mutiert werden und werden stündlich mit dem
            puras
            Verbrauchsmanager abgeglichen.
          </div>
        </v-col>
      </v-row>
      <v-row style="max-height: 70px;">
        <v-col cols="10">
          <v-row>
            <v-col cols="12" class="mb-0 pb-0" style="margin-left: 11px">
              <v-tabs class="custom-tabs mb-0 pb-0" v-model="bewohnerFilter"
                      @change="changeFilter($event)">
                <v-tab>Aktiv ({{ activeBewohnerCount }})</v-tab>
                <v-tab>Austritte ({{ austritteBewohnerCount }})</v-tab>
                <v-tab>Gelöschte ({{ deletedBewohnerCount }})</v-tab>
              </v-tabs>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="2" style="text-align: right;">
          <BewohnerverwaltungDialog :edit="false" v-if="!isBewohnerSyncActive">
            <template #button>
              <div class="action-icon">
                <v-hover v-slot="hover">
                                <span class="cursor-pointer text-center align-center">
                              <v-img :src="require('@/assets/img/ico2023/bewohner-hinzuefuegen.svg')" alt=""
                                     style="margin:0 auto;" max-width="47"
                                     class="cursor-pointer"></v-img>
                              <div class="block pt-0 caption text-center small">
                                 Bewohner erfassen
                              </div>
                            </span>
                </v-hover>
              </div>
            </template>
          </BewohnerverwaltungDialog>
        </v-col>
      </v-row>
      <v-row class="mt-4 fixed sticky">
        <v-col cols="2">
          <v-select v-model="whfilter" :items="whSelect" label="Wohneinheit" clearable item-text="name"
                    @click:clear="whClear($event)"
                    item-value="id"></v-select>
        </v-col>
      </v-row>

      <div class="pt-10">
        <div class="container">
          <v-row>
            <v-col cols="2" class="col-firstname pl-0">
              <!-- <span class="cursor-pointer" @click="orderBy('vorname','asc')">↓</span> <span  class="cursor-pointer" @click="orderBy('vorname','desc')">↑</span>-->
              <b class="cursor-pointer" @click="orderBy('vorname',orderDir == 'asc' ? 'desc' : 'asc')">Vorname
                <v-icon size="16">mdi-sort</v-icon>
              </b>
            </v-col>
            <v-col cols="3" class="col-lastname">
              <b class="cursor-pointer" @click="orderBy('name',orderDir == 'asc' ? 'desc' : 'asc')">Name
                <v-icon size="16">mdi-sort</v-icon>
              </b>
            </v-col>
            <v-col cols="2" class="col-bewohnerId">
              <b class="cursor-pointer" @click="orderBy('externalID',orderDir == 'asc' ? 'desc' : 'asc')">ID

                <v-icon size="16">mdi-sort</v-icon>
              </b>
            </v-col>
            <!--                        <v-col cols="1" class="col-sexe">-->
            <!--                            <b class="cursor-pointer" @click="orderBy('geschlecht',orderDir == 'asc' ? 'desc' : 'asc')">Geschlecht-->
            <!--                                <v-icon size="16">mdi-sort</v-icon>-->
            <!--                            </b>-->
            <!--                        </v-col>-->
            <v-col cols="2" class="col-wohneinheit">
              <b class="cursor-pointer" @click="orderBy('whName',orderDir == 'asc' ? 'desc' : 'asc')">Wohneinheit
                <v-icon size="16">mdi-sort</v-icon>
              </b>
            </v-col>
            <v-col cols="1" class="col-zimmer">
              <b class="cursor-pointer" @click="orderBy('zimmer',orderDir == 'asc' ? 'desc' : 'asc')">Zimmer
                <v-icon size="16">mdi-sort</v-icon>
              </b>
            </v-col>
            <v-col cols="1" class="col-inkograd">
              <b class="cursor-pointer"
                 @click="orderBy('inkograd',orderDir == 'asc' ? 'desc' : 'asc')">Inkograd
                <v-icon size="16">mdi-sort</v-icon>
              </b>

            </v-col>
            <v-col cols="1" class="col-edit">

            </v-col>
          </v-row>
        </div>
        <div class="container ">
          <v-row v-for="(b,index) in filteredBewohner" :key="'bid-'+index" class="b-top hover-row">
            <v-col cols="2" class="col-firstname text-vertical-align pl-2">
              {{ b.vorname }}
            </v-col>
            <v-col cols="3" class="col-lastname text-vertical-align">
              {{ b.name }}
            </v-col>
            <v-col cols="2" class="col-bewohnerId text-vertical-align">
              {{ b.externalID }}
            </v-col>
            <!--                        <v-col cols="1" class="col-sexe text-vertical-align">-->
            <!--                            {{ b.geschlecht == 'm' ? 'männlich' : 'weiblich' }}-->
            <!--                        </v-col>-->
            <v-col cols="2" class="col-wohneinheit">
              {{ getWohnheinheitNameFromID(b.wohneinheitenID) }}
            </v-col>
            <v-col cols="1" class="col-zimmer">
              {{ b.zimmer }}
            </v-col>
            <v-col cols="1" class="col-inkograd">
              <InkoGradIndicator :inkograd="bewohnerHelper.getCurrentInkogradFromHistory(b.bewohnerID)"
                                 v-if="bewohnerHelper.getCurrentInkogradFromHistory(b.bewohnerID) != null"/>
            </v-col>
            <v-col cols="1"
                   class="col-edit text-vertical-align justify-right ">
              <BewohnerverwaltungDialog :bewohner-data="b" :edit="true">
                <template #button>
                  <v-hover v-slot="{ hover }" open-delay="0">
                         <span class="px-1 text-vertical-align">
                  <v-img v class="mt-0 cursor-pointer"
                         :src="hover ? require('@/assets/img/ico/bearbeiten-aktiv.svg') : require('@/assets/img/ico/bearbeiten.svg')" alt=""  height="18"
                         max-height="18"
                         width="18"
                         max-width="18"></v-img>
                         </span>
                  </v-hover>
                </template>
              </BewohnerverwaltungDialog>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
import {bewohnerGetters, bewohnerActions, bewohnerMutations} from '../components/observables/bewohner';
import {wohneinheitenGetters, wohneinheitenActions} from '../components/observables/wohneinheiten';
import _ from 'lodash';
import moment from 'moment'
import BewohnerDatepickerModal from "../components/Bewohner/BewohnerDatepickerModal";
import BewohnerverwaltungDialog from "../components/Bewohner/Bewohnerverwaltung/BewohnerverwaltungDialog";
import InkoGradIndicator from "@/components/inkograd/InkoGradIndicator.vue";
import {useBewohnerHelper} from "@/composables/bewohnerHelper";
import {mapGetters} from "vuex";


export default {
  name: 'Bewohnerverwaltung',
  components: {BewohnerDatepickerModal, BewohnerverwaltungDialog, InkoGradIndicator},
  data() {
    return {
      bewohnerHelper: useBewohnerHelper,
      bewohnerFilter: 0,
      filters: ['active', 'austritte', 'deleted'],
      moment: moment,
      search: '',
      editMode: 'details',
      orderCol: 'zimmer',
      orderDir: 'asc',
      showDeleted: false,
      editModal: false,
      editBewohner: false,
      whfilter: -1,
      requiredRules: [
        v => !!v || 'Dieses Feld ist erforderlich',
      ],
      geschlecht: [
        {
          key: 'w',
          name: 'weiblich'
        }, {
          key: 'm',
          name: 'männlich'
        }
      ]
    }
  },
  computed: {
    ...wohneinheitenGetters,
    ...bewohnerGetters,
    ...mapGetters({
      'isErpSyncActive': 'benutzer/isErpSyncActive',
      'settings': 'benutzer/settings',
      'isBewohnerSyncActive': 'benutzer/isBewohnerSyncActive',
    }),

    activeBewohnerCount() {
      return this.getActiveBewohner.length;
    },

    deletedBewohnerCount() {
      return this.getDeletedBewohner.length;
    },

    austritteBewohnerCount() {
      return this.getAustritteBewohner.length;

    },

    whSelect() {
      var whSelect = JSON.parse(JSON.stringify(this.wohneinheiten));
      whSelect.unshift({'name': 'alle', 'bewohnerID': '-1'})
      return whSelect
    },

    filteredBewohner() {
      let fb;
      switch (this.filters[this.bewohnerFilter]) {
        case 'active':
          fb = this.getActiveBewohner;
          break;
        case 'deleted':
          fb = this.getDeletedBewohner;
          break;
        case 'austritte':
          fb = this.getAustritteBewohner;
          break;
      }

      if (!this.search) {
        this.search = '';
      }
      if (fb !== undefined) {

        var bew = fb.filter(o => {
          try {
            return (
                (o.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || o.vorname.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
                    (o.zimmer != null && o.zimmer.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || o.externalID.toLowerCase().indexOf(this.search.toLowerCase()) > -1))
                && (o.wohneinheitenID == this.whfilter || this.whfilter == -1 || this.whfilter == 'alle')
            )
          } catch (e) {
            return false;
          }
        })
      } else {
        return false;
      }

      var self = this;
      bew = bew.map(function (el, k) {
        el.whName = self.getWohnheinheitNameFromID(el.wohneinheitenID);
        return el;
      });

      if (this.orderCol) {
        if (this.orderCol == 'zimmer') {

          // bew = _.orderBy(bew, function (o) { return parseFloat(o.zimmer); }, [this.orderDir])
          const reg = /[0-9]+/g;
          var self = this;

          bew.sort((a, b) => {

            if (a.zimmer == null || b.zimmer == null) {
              return -1;
            }

            let v0 = a.zimmer.replace(reg, v => v.padStart(10, '0'));
            let v1 = b.zimmer.replace(reg, v => v.padStart(10, '0'));
            if (self.orderDir == 'asc') {
              return v0.localeCompare(v1);

            } else {
              return v1.localeCompare(v0);
            }
          });

        } else {
          bew = _.orderBy(bew, [this.orderCol], [this.orderDir]);
        }
      }

      return bew;
    },
    resultCount() {
      return this.filteredBewohner && this.filteredBewohner.length
    }
  },

  watch: {},

  methods: {
    ...wohneinheitenActions,
    ...bewohnerActions,
    ...bewohnerMutations,

    changeFilter(value) {
      this.bewohnerFilter = value
    },

    whClear(event) {
      setTimeout(() => {
        this.whfilter = -1;
      }, 50)
    },

    orderBy(col, dir) {
      this.orderDir = dir;
      this.orderCol = col;
    },

    deleteBewohnerFromList(id) {

      this.$confirm('<div class="font-weight-bold" style="font-size:16px;">Möchten Sie diesen Benutzer wirklich löschen?</div> Daten aus der Vergangenheit werden behalten, alle zukünftigen Daten jedoch gelöscht. Sollte der Bewohner in einem anderen Puras Tool (z.B. Inkoplaner) aktiv sein, werden zukünftige Daten dort ebenfalls gelöscht und der Bewohner entfernt. Sollten Sie den Bewohner lediglich im Verbrauchsmanager nicht mehr benötigen, können Sie diesen auch ausblenden.', {
        title: 'Achtung',
        buttonTrueText: 'Löschen',
        buttonFalseText: 'Abbrechen'
      }).then(async confirmed => {
        if (confirmed) {
          this.deleteBewohner(id);
        }
      })
    },

    saveThisBewohner() {
      this.saveBewohner(this.editBewohner)
    },

    restoreThisBewohner(id) {
      this.restoreBewohner(id, 'austritt');
    },

    openEditBewohner(b, mode) {
      this.editMode = mode;
      this.editBewohner = b;
      this.editModal = true;
    },

    getWohnheinheitNameFromID(whid) {
      try {
        var whid = whid;
        var w = this.wohneinheiten.filter(o => {
          return (
              o.id == whid
          )
        })
        return w[0].name;
      } catch (e) {
        return '';
      }
    },

    addBewohnerAndRemoveFilters: function () {
      this.whfilter = -1;
      this.search = '';

      this.editBewohner = {
        'name': '',
        'vorname': '',
        'geschlecht': '',
        'zimmer': '',
        'AdressNrADR': this.$store.state['benutzer'].AdressNrADR,
        'wohneinheitenID': 0,
        'bewohnerID': '-1'
      }

      this.editModal = true;
    }
  },
  created() {
    this.fetchWohneinheiten();
    this.fetchBewohner(true);
  },
}
</script>
<style lang="scss" scoped>

#bewohner {
  font-size: 16px;

  b {
    font-size: 16px;
    font-weight: 300;
    font-family: "Avenir LT Std Heavy" !important;
  }
}

.addbtn {
  margin-right: 15px !important;
}

.odd {
  background-color: #F4EDE7;
}

.col-firstname, .col-lastname, .col-wohneinheit {
  flex: 0 0 20%;
  max-width: 20%;
}

//.col-sexe {
//  flex: 0 0 11%;
//  max-width: 11%;
//}

.col-zimmer, .col-bewohnerId, .col-inkograd, .col-edit {
  flex: 0 0 10%;
  max-width: 10%;
}

</style>
