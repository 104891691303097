<template>
  <ConfirmDialog
      title="Zum nächsten Bewohner"
      @confirm="saveAndNext"
      @optionConfirm="discardAndNext"
      option-button
      :preventOpen="!hasBeenEdited"
  >
    <template #button>
      <span @click="skipConfirmDialog" :style="(next && isLast || !next && isFirst)? 'opacity: 0.3;': ''">
        <v-hover
            v-slot="{ hover }"
            open-delay="0"
            :disabled="(next && isLast || !next && isFirst)"
        >
            <v-img
                v-if="hover"
                class="mt-0 cursor-pointer"
                :style="next? 'transform: rotate(-180deg);': 'transform: rotate(0);'"
                :src="require('@/assets/img/ico/circle-arrow-active.svg')" alt=""
                width="38"
            />
            <v-img
                v-else
                class="mt-0 cursor-pointer"
                :style="next? 'transform: rotate(180deg);': 'transform: rotate(0);'"
                :src="require('@/assets/img/ico/circle-arrow.svg')"
                alt=""
                width="38"
            />
        </v-hover>
      </span>
    </template>

    <template v-slot:cancel="slotProps">
      <v-btn
          color="beige"
          depressed
          @click="slotProps.cancel"
      >
        Abbrechen
      </v-btn>
    </template>

    <template v-slot:optionButton="slotProps">
      <v-btn
          outlined
          color="black"
          @click="slotProps.optionConfirm"
      >
        Nicht speichern
      </v-btn>
    </template>

    <template v-slot:confirm="slotProps">
      <v-btn
          depressed
          color="primary"
          @click="slotProps.confirm"
      >
        Speichern
      </v-btn>
    </template>

    Wollen Sie die Änderung speichern? Anpassungen an der Abrechnungsperiode dieses Bewohners gehen verloren, wenn sie nicht gespeichert werden.
  </ConfirmDialog>
</template>

<script>
import ConfirmDialog from "@/components/shared/ConfirmDialog.vue";
import {mapGetters} from "vuex";

export default {
  name: "ToNextBewohner",
  components: {ConfirmDialog},
  emits: ['save'],
  props: {
    curBewohner: {
      type: Object,
      required: true
    },
    hasBeenEdited: {
      type: Boolean,
      required: true
    },
    next: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      sortedCarts: null,
      bewohnerPos: 0,
      isFirst: true,
      isLast: false
    }
  },

  computed: {
    ...mapGetters({
      carts: 'direktabrechnung/carts'
    }),
  },

  created() {
    this.sortedCarts = this.carts.sort((a, b) => a.bewohner.wohneinheitenID - b.bewohner.wohneinheitenID)
    this.bewohnerPos = this.sortedCarts.findIndex(c => c.bewohner_id === this.curBewohner.bewohnerID)
    this.isFirst = this.bewohnerPos === 0
    this.isLast = this.bewohnerPos === this.sortedCarts.length - 1
  },


  methods: {
    saveAndNext() {
      this.$emit('save')
      this.goToNextBewohner()
    },

    discardAndNext() {
      this.goToNextBewohner()
    },

    goToNextBewohner() {
      var goToPos = 0

      if (this.next) {
        if (!this.isLast) {
          goToPos = this.bewohnerPos + 1
        } else {
          goToPos = this.bewohnerPos
        }
      } else {
        if (!this.isFirst) {
          goToPos = this.bewohnerPos - 1
        }
      }

      if(goToPos !== this.bewohnerPos) {
        this.$store.commit('direktabrechnung/setBewohnerInEditmode', this.sortedCarts[goToPos].bewohner_id)
      }
    },

    skipConfirmDialog() {
      if (!this.hasBeenEdited) {
        this.goToNextBewohner()
      }
    }
  }
}
</script>