<template>
    <div style="position:relative">

      <v-card flat class="px-4 pb-0" id="prodsearch-dialog">
        <v-card-title class="headline text-h5 py-8" style="position: sticky;top: 0px;width: 100%; background: #fff;z-index: 8">
          <div class="op-hover" style="position:absolute;right: 26px; top: 32px;z-index:999">
            <img :src="require('@/assets/img/ico/schliessen.svg')" @click="$emit('closeProdChooser')" width="25px" height="25px" max-width="25px"
                 max-height="25px" class="mx-auto cursor-pointer" contain="contain">
          </div>
          <span class="dialog-title" v-html="producttarget.caption"></span>
        </v-card-title>
        <v-card-text class="body-1" v-if="productcategories.length > 0">
          <ais-instant-search key="instantsearch" class="flex flex-wrap"
                              style="display: flex !important;grid-gap: inherit !important;" index-name="artikel"
                              :search-client="searchClient">
            <ais-configure :filters="activeFilter" :hits-per-page.camel="12"></ais-configure>
            <v-row>
              <v-col cols="4">
                <div class="pr-8" style="position: sticky; top: 98px;">
                  <ais-search-box>
                    <template v-slot="{ currentRefinement, isSearchStalled, refine }">
                      <v-text-field ref="srch" clearable  class="mb-0"
                                    placeholder="Suche nach Artikelname, Artikelnummer"
                                    v-model="searchTerm"
                                    @focus="refine($event.currentTarget.value)"
                                    @keyup="refine($event.currentTarget.value);"
                      >
                      </v-text-field>
                    </template>
                  </ais-search-box>

                  <!-- filter row 1 -->
                  <div class="hide-v-massages">
                    <v-switch inset v-if="migel" v-model="migel.value" label="nur MiGeL" dense :false-value="false"
                              medium light flat :true-value="1" class="mb-2"></v-switch>
                  </div>
                  <v-divider class="mt-4"></v-divider>
                  <ais-refinement-list attribute="klassifizierung"  :sort-by="refinementSort"  :limit="20"
                                       key="refinelist">
                    <div slot-scope="{items,isShowingMore, isFromSearch, refine, createURL, searchForItems, sendEvent}">
                      <div v-if="items.length > 0">
                        <!-- filter row 1 -->
                        <div v-if="items.find(item => item.value === 'Eigene Produkte')">
                          <div class="py-4 hide-v-massages">
                            <div v-for="item in items" :key="`filter-${item.label}`">
                              <div v-if=" item.value === 'Eigene Produkte'">
                                <v-checkbox dense light @click.prevent="refine(item.value)" class="full-color">
                                  <template v-slot:label>
                                      <span class="body-1">
                                        <span style="color:black;">{{ item.value }}</span> {{
                                          item.count.toLocaleString()
                                        }}
                                      </span>
                                  </template>
                                </v-checkbox>
                              </div>
                            </div>
                          </div>
                          <v-divider></v-divider>
                        </div>
                        <!-- filter row 2 -->
                        <div class="pt-4 hide-v-massages">
                          <div v-for="item in items" :key="`filter-${item.label}`">
                            <div v-if="item.value !== 'Eigene Produkte'">
                              <v-checkbox dense light @click.prevent="refine(item.value)" class="full-color">
                                <template v-slot:label>
                                    <span class="body-1">
                                      <span style="color:black;">{{ item.value }}</span> <span class="count">{{
                                        item.count.toLocaleString()
                                      }}</span>

                                    </span>
                                </template>
                              </v-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="items.length === 0">
                        <p class="my-4">
                          Keine Filter verfügbar
                        </p>
                      </div>
                    </div>
                  </ais-refinement-list>
                </div>
              </v-col>
              <v-col cols="8">
                <app-infinite-hits colwidth="4" key="ais-auto"  class="pb-16 mt-2">
                  <template slot="item" slot-scope="{ item }">
                    <v-card :ref="'card-'+item.objectID" class="mb-3 elevation-soft product-card" rounded  style="height:100%;">
                      <div class="d-flex flex-column justify-space-between" style="height:100%;">
                        <!-- product card content upper -->
                        <div>
                          <div class="px-4 py-2">
                            <v-img v-if="item.hauptbild" :src="item.hauptbild" width="100%" height="80px"
                                   max-width="80px" max-height="100px" class="mx-auto"
                                   contain="contain"></v-img>

                            <div v-else style="min-height:140px;padding-top: 25px;">
                              <img :src="require('@/assets/img/ico/sortimentsverwaltung-aktiv.svg')" width="100%" height="75px" max-width="80px"
                                   max-height="80px" class="mx-auto" contain="contain">
                            </div>
                          </div>
                          <div class="pa-4 mb-6">
                            <p class="ma-0 font-weight-bold text-sm-body-2">
                              <span v-if="prodinfos[item.objectID + '-' + 'customerartno']">
                                {{ prodinfos[item.objectID + '-' + 'customerartno'].value }}
                              </span>
                              <span v-else>
                                  {{ item.objectID }}
                              </span>
                            </p>
                            <div class="ma-0">
                              <span class="pr-2">
                                {{ item.name }}
                              </span>
                              <div v-if="recentButDeletedProducts.indexOf(item.objectID) > -1 "
                                   style="margin-top: 4px;display: inline-block; color: #fff; font-size: 12px;line-height:17px;padding: 2px 5px 0px 0px;font-weight: bold">
                                <v-tooltip top class="body-2 py-1 px-2 ">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-hover v-slot="{ hover }" open-delay="0">
                                      <v-icon color="red" v-bind="attrs" size="22" v-on="on"
                                              style="margin-top: -2px;">
                                        mdi-information-outline
                                      </v-icon>
                                    </v-hover>
                                  </template>
                                  <div class="py-2 px-2">
                                    Dieses Produkt wurde aus dem Sortiment gelöscht
                                  </div>
                                </v-tooltip>
                              </div>
                            </div>
                            <div class="op-hover" style="position:absolute; bottom: 10px; right: 15px;">
                              <v-img g @click="assignProd(item.objectID)" :src="require('@/assets/img/ico/hinzufuegen-box.svg')" alt=""
                                     class="cursor-pointer" style="width:26px; height:auto;" max-width="46px">
                              </v-img>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </template>
                </app-infinite-hits>
              </v-col>
            </v-row>
          </ais-instant-search>
        </v-card-text>
      </v-card>
    </div>
</template>
<script>
import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/satellite-min.css';
import {AisInstantSearch, AisSearchBox} from 'vue-instantsearch';
import AppInfiniteHits from '../search/InfiniteHits'
import AppRefresh from "../search/Refresh";
import httpClient from "../../utils/httpClient";

  export default {
    props: ['producttarget','productcategories','prodinfos','idfilter'],
    components: {
      AppInfiniteHits,
      AisInstantSearch,
      AisSearchBox,
      AppRefresh,
    },
    name: 'ProductChooser',

    data(){
      return{

        searchTerm: '',
        recentButDeletedProducts: [],

        searchClient: algoliasearch(
            process.env.VUE_APP_ALGOLIA_ID,
            process.env.VUE_APP_ALGOLIA_KEY,
        ),

        adressNrADR: this.$store.state.benutzer.info.AdressNrADR,

        filterTypes: [
          {
            'name': 'MiGel',
            'value': false,
            'filterType': 'filter',
            'operator': '=',
            'key': 'isArticleMiGeL'
          },
          {
            'name': 'mySortiment',
            'value': this.$store.state.benutzer.info.AdressNrADR,
            'filterType': 'mysortiment',
          }
        ],
      }
    },

    methods:{
      refinementSort: function (a, b) {
        return this.productcategories.indexOf(a.name) - this.productcategories.indexOf(b.name);
      },

      async assignProd(prodID) {

        if (this.producttarget.targetType != 'bewohner') {
          this.$confirm('Dieses Produkt wird mehreren Bewohnern automatisch zuegordnet. Möchten Sie weiterfahren?', {
            title: 'Achtung',
            buttonTrueText: 'Hinzufügen',
            buttonFalseText: 'Abbrechen'
          }).then(async confirmed => {
            if (confirmed) {
              this.$emit('confirmedAssignProduct',prodID);
            }
          })
        } else {
          this.$emit('confirmedAssignProduct',prodID);
        }
      },
    },

    computed:{

      bilder: {
        get() {
          return this.$store.state['bilder'].produktbilder
        }
      },

      //Filter Switch
      migel() {
        let migel = this.filterTypes.filter(function (el, k) {
          if (el.name == 'MiGel') {
            return el;
          }
        })
        return migel[0];
      },

      //Filter Switch
      mySortiment() {
        let mySortiment = this.filterTypes.filter(function (el, k) {
          if (el.name == 'mySortiment') {
            return el;
          }
        })
        return mySortiment[0];
      },

      activeFilter() {
        var outputFilter = '(isClient:false OR isClient:' + this.adressNrADR + ') AND ((geloscht:0 OR NOT isClient:false)  ';

        var hasRecentDeleted = false;
        this.recentButDeletedProducts.forEach(function (artnr, k) {
          hasRecentDeleted = true;
          if(k === 0) {
            outputFilter += ' OR (objectID:' + artnr;
          } else {
            outputFilter += ' OR objectID:' + artnr;
          }
        })

        if(hasRecentDeleted) {
          outputFilter += ')';
        }

        outputFilter += ')';

        var self = this;
        this.filterTypes.map(function (el, k) {

          if (el.value && outputFilter != '') {
            outputFilter += ' AND ';
          }

          if (el.filterType != 'mysortiment') {
            if (el.value) {
              outputFilter += el.key + el.operator + el.value; //+ ' AND objectID:1351046'
            }
          } else {
            if (el.value) {
              outputFilter += '(';
              outputFilter += self.idfilter
              outputFilter += ')';
            }
          }
        })
        return outputFilter;
      },
    },

    watch:{
      //Suche in PopUp Produkte
      searchTerm(val){
        if(!val){
          this.searchTerm = '';
          var self = this;
          self.$refs.srch.blur();
          setTimeout(function(){
            self.$refs.srch.focus();
          },100)
        }
      }
    },

    async mounted() {
      const resRecent = await httpClient({
        url: process.env.VUE_APP_API_SHARED + 'get/recent-deleted-but-history-products',
        method: 'get'
      })

      if (resRecent.type == 'success') {
        this.recentButDeletedProducts = resRecent.products;
      }
    }
  }
</script>
