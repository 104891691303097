<template>
  <v-card>
    <v-card-title class="headline text-h5 font-weight-regular d-block">
      Eigene Leistungsnummer <br><div class="d-block mt-2 text-body-1">{{ curProdName }}</div>
    </v-card-title>
    <v-card-text class="body-1">
      <v-row>
        <v-col>
          <v-combobox
              v-if="this.$store.state.benutzer.settings.erp && this.$store.state.benutzer.settings.erp !== 'none' && this.erpTypeHints.length > 0"
              maxlength="20"
              label="Leistungsnummer eingeben oder Artikel suchen"
              @keyup="changeOwnProdNo($event)"
              @change="changeOwnProdNo($event)"
              :value="ownProdNo"
              item-value="LeistungsNummer"
              item-text="LeistungsNummer"
              :items="resultHints">
            <template v-slot:item="{ index, item }">
              {{ item.Bezeichnung }} <span v-if="item.Zusatztext && item.Zusatztext != ''"> - {{ item.Zusatztext }} </span><v-chip color="primary" x-small style="padding-top: 10px; margin-top: -2px;" class="caption ml-2 pb-2 px-2">{{ item.LeistungsNummer }}</v-chip>
            </template>
          </v-combobox>
          <v-text-field
              v-else
              maxlength="20"
              label="Leistungsnummer, Artikelnummer eingeben"
              v-model="ownProdNo">
          </v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="pb-6">
      <v-hover v-slot="{ hover }" open-delay="0" v-if="showRemove">
        <v-row @click="$emit('addProductInfo',{ownProdNo: '', objectID: curProdId})" class="cursor-pointer flex flex-wrap ml-2">
          <div class="flex-column w">
            <v-img class="mt-1 mb-2"
                   :src="hover ? require('@/assets/img/ico/loeschen-hover.svg') : require('@/assets/img/ico/loeschen.svg')"
                   alt="" max-width="30"></v-img>
          </div>
          <div class="caption flex-column pl-4 pb-1" style="font-weight: bold !important">Nummer<br>entfernen</div>
        </v-row>
      </v-hover>
      <v-spacer></v-spacer>
      <v-btn color="danger" depressed @click="$emit('abort');ownProdNo=''">Abbrechen</v-btn>
      <v-btn color="primary" depressed @click="$emit('addProductInfo',{ownProdNo: ownProdNo, objectID: curProdId})">Speichern</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
  import httpClient from "../../utils/httpClient";

  export default {
    name: 'ProductLpTypeHint',
    props:['curProdNameProp','ownProdNoProp','curProdId','showRemove'],
    data(){
      return {
        curProdName: '',
        ownProdNo: false,
        erpTypeHints: [],
        resultHints: [],
      }
    },

    watch: {
      ownProdNo(val){
        var self = this;
        this.resultHints =  Object.assign(this.resultHints, this.erpTypeHints);

        this.resultHints =  this.resultHints.filter(function(item){
          return self.ownProdNo == '' || (
            item.Bezeichnung.toLocaleLowerCase().indexOf(self.ownProdNo.toLocaleLowerCase()) > -1 ||
            item.LeistungsNummer.toLocaleLowerCase().indexOf(self.ownProdNo.toLocaleLowerCase()) > -1 ||
            item.Zusatztext.toLocaleLowerCase().indexOf(self.ownProdNo.toLocaleLowerCase()) > -1 ||
            item.ArtikelNrEvt.toLocaleLowerCase().indexOf(self.ownProdNo.toLocaleLowerCase()) > -1
          )
        });
      },
    },


    methods: {

      changeOwnProdNo(e) {
        if(!isNaN(e)){
          this.ownProdNo = e;
        }else if(e.LeistungsNummer !== undefined){
          this.ownProdNo = e.LeistungsNummer;
        }else{
          this.ownProdNo = e.target.value;
        }
      },
    },
    async mounted() {
      this.ownProdNo = this.$props.ownProdNoProp;
      this.curProdName = this.$props.curProdNameProp;

      if(this.$store.state.benutzer.settings.erp !== 'none'){
        const resTypeHintsERP = await httpClient({
          url: process.env.VUE_APP_API_CHECKLIST + 'erp/type-hints',
          method: 'get'
        })

        if (resTypeHintsERP.type == 'success') {
          this.erpTypeHints = resTypeHintsERP.hints;
        }
      }
    },
  }
</script>