<template>
  <div id="strichliste" style="position: relative">
    <div style="position: fixed;width: 100%; top: 0px; z-index: 98; left: 0px; height: 116px;"
         class="text-vertical-align">
      <v-container>
        <v-row>
          <v-col cols="3" class="text-vertical-align">
            <v-row class="d-none d-lg-flex">
              <v-col cols="1" class="icon-container text-vertical-align" style="background-color: white">
                <router-link to="/verbrauchsliste">
                  <v-img :src="require('@/assets/img/ico/planung.png')" alt="" width="32"></v-img>
                </router-link>
              </v-col>
              <v-col cols="10" class="pl-0 name-container text-vertical-align" style="background-color: white">
                <div>
                  <router-link to="/verbrauchsliste" class="kunden-name"
                               :class="{'small-text': this.$store.state.benutzer.info.firma.length > 30}">
                    {{ this.$store.state.benutzer.info.firma }}
                  </router-link>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="text-vertical-align">
            <v-row>
              <v-col cols="7">
                <v-text-field
                    ref="bewSearch"
                    style="margin-top: -9px;"
                    name="bewSearch"
                    clearable
                    light
                    :placeholder="srchtxt"
                    autocomplete="off"
                    @click:clear="resetSearch"
                    @keyup="setSearch"
                ></v-text-field>
                <div style="margin-top: -25px;">
                  <v-row>
                    <v-col cols="12">

                      <v-row>
                        <div class="px-3 pt-2 float-left" :class="searchTarget == 'bewohner' ? 'text--primary' : ''"
                             style="font-size: 15px;">
                          <div class="cursor-pointer hov-opacity" style="margin-top: 3px;"
                               @click="searchTarget = 'bewohner';">
                            Bewohner
                          </div>
                        </div>
                        <div class="float-left pt-2 mt-0" style="position:relative;" id="srchSwitch">
                          <v-switch style="" v-model="searchTarget" class=" mt-0"
                                    :ripple="false"
                                    true-value="produkte" false-value="bewohner" dense x-small light flat>
                          </v-switch>
                        </div>
                        <div class="float-left  pt-2 px-3" cols="3"
                             :class="searchTarget == 'produkte' ? 'text--primary' : ''" style="font-size: 15px;">
                          <div class="cursor-pointer hov-opacity" style="margin-top: 3px;"
                               @click="searchTarget = 'produkte';">
                            Produkte
                          </div>
                        </div>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="5" class="text-vertical-align">
                <div class="ml-8">
                  <span class="text-h5 pr-3 op-hover cursor-pointer" @click="changeChoosenDate('sub')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="9.254" height="15.697" viewBox="0 0 9.254 15.697">
                      <path id="Pfad_1215" data-name="Pfad 1215" d="M139.074,569.512l7.133,7.151-7.133,7.133"
                            transform="translate(147.62 584.502) rotate(180)" fill="none" stroke="#241911" stroke-miterlimit="10"
                            stroke-width="1"/>
                    </svg>
                  </span>
                  <span class="text-h5 font-weight-light">{{
                      moment(datum.choosenMonthYear).locale('de').format('MMMM YYYY')
                    }}</span>
                  <span class="text-h5 pl-3 cursor-pointer op-hover"
                        v-if="moment(datum.choosenMonthYear).format('YYYYMM') < moment().add(1,'month').format('YYYYMM')"
                        @click="changeChoosenDate('add')" depressed small>
                    <svg xmlns="http://www.w3.org/2000/svg" width="9.254" height="15.697" viewBox="0 0 9.254 15.697">
                      <path id="Pfad_1215" data-name="Pfad 1215" d="M0,14.283,7.133,7.133,0,0"
                            transform="translate(0.708 0.707)" fill="none" stroke="#241911" stroke-miterlimit="10"
                            stroke-width="1"/>
                    </svg>
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container>
      <v-row>
        <v-col cols="12" class="md-pl-0 pb-0 mb-0 ">
          <h1 class="primary--text mb-0">
            Verbrauchsliste Bewohner
          </h1>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" v-if="filteredLivingUnitsWithResidents">
          <v-expansion-panels
              :multiple="searchIsActive"
              accordion
              flat
              v-model="panels"
              class="expansion-panel"
          >
            <template v-if="loading">
              <div class="loading-wrapper">
                <v-progress-circular color="primary" indeterminate></v-progress-circular>
              </div>
            </template>
            <v-expansion-panel
                v-model="panels" v-for="(wh,i) in filteredLivingUnitsWithResidents.children"
                :key="'acc'+i"
                @click="fetchLivingUnitArticles(wh.internalID, datum.choosenMonthYear)"
            >
              <v-expansion-panel-header
                  v-if="!searchIsActive"
                  @click="setActiveAccDelay(i)"
                  class="pl-2 v-expansion-panel-header--reversed">
                <v-row dense>
                  <v-col cols="7" class="d-flex align-center">
                    <div class="d-inline-flex pr-4 pl-4">
                      <h2 class="font-weight-medium pt-4 pb-3">
                        {{ wh.name }}
                      </h2>
                    </div>
                  </v-col>
                  <v-col cols="5" class="d-flex align-center justify-end">
                    <div class="d-flex align-center op-hover">
                      <span class="d-block caption text-right mr-3 mt-1 hov-opacity  extra-small"
                            @click.stop="prepareAssignProd('wohneinheit',wh.internalID,wh.name)"
                            style="line-height:1.5;font-weight: bold !important">
                        Produkte für <br> Wohneinheit
                      </span>
                      <v-img @click.stop="prepareAssignProd('wohneinheit',wh.internalID,wh.name)"
                             :src="require('@/assets/img/ico/hinzufuegen-box.svg')" alt=""
                             class="cursor-pointer " style="width:46px; height:auto;" max-width="46px">
                      </v-img>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-0" v-if="!disablePanelRendering">
                <!--<Scrollama @step-enter="stepEnterHandler">-->
                <v-row v-for="b in wh.children" :key="'b-'+b.internalID" class="pb-8" :data-bewname="b.name"
                       style="background: #fff">
                  <v-col cols="12" class="person-header">
                    <div>
                      <div class="px-6 person-header-inner">
                        <div class="d-flex align-center justify-space-between align-center py-3">
                          <div class="d-flex text-vertical-align  flex-grow-0">
                            <v-tooltip v-if="b.isDeleted " top class="body-2 py-1 px-2 ">
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                  <span class="text-h6 text-decoration-line-through" style="color: #bbb"
                                        :data-bewohnerid="b.internalID">{{ b.name }}</span>
                                  <span class="text-h6 font-weight-light pl-2">{{ b.zimmer }}</span>
                                </span>
                              </template>
                              <span>
                                Dieser Bewohner wurde am {{ moment(b.deleted_at).format('DD.MM.YY') }} gelöscht<br>und ist nur bis Ende Monat des Lösch-Datums verfügbar
                              </span>
                            </v-tooltip>
                            <span v-else>
                              <span class="text-h6 strong name" :style="b.isDeleted ? 'color: #bbb': ''"
                                    :class="b.isDeleted ? 'text-decoration-line-through' : ''"
                                    :data-bewohnerid="b.internalID">{{ b.name }}</span>
                              <span class="text-h6 font-weight-light room">{{ b.zimmer }}</span>
                            </span>

                            <v-btn :disabled="(residentArticlesByResidentID(datum.choosenMonthYear, b.internalID) && residentArticlesByResidentID(datum.choosenMonthYear, b.internalID).children.length == 0)" outlined color="black"
                                   class="xsmall-button ml-12 detail-button"
                                   @click="startTimeline(b.internalID)">
                              <div class="d-flex align-center px-1">
                                <v-img :class="(residentArticlesByResidentID(datum.choosenMonthYear, b.internalID) && residentArticlesByResidentID(datum.choosenMonthYear, b.internalID).children.length == 0) ? 'opacity-50' : ''"
                                       :src="require('@/assets/img/ico/kalender.svg')" alt=""
                                       max-width="16px"></v-img>
                                <span class="d-block mt-1 ml-1" style="line-height:1; font-size: 16px;">Zeitleiste / Detail-Bearbeitung</span>
                              </div>
                            </v-btn>

                            <div v-if="b.isDeleted" class="ml-8 d-flex">
                              <div>
                                <v-icon color="error" size="32" class="pt-1">mdi-information</v-icon>
                              </div>
                              <div class="error--text ml-4 pt-1" style="font-size: 14px;line-height: 1.3">
                                <b>Achtung: </b> Bewohner ist in diesem Monat ausgetreten.<br>
                                Bitte prüfen Sie den Verbrauch in der Zeitleiste.
                              </div>
                            </div>
                          </div>
                          <div @click="prepareAssignProd('bewohner',b.internalID,b.name)"
                               class="d-flex align-center hov-opacity">
                            <span class="d-block text-right mr-3 mt-1 caption cursor-pointer extra-small"
                                  style="line-height:1.5;font-weight: bold !important">
                              Produkte für <br> Bewohner
                            </span>
                            <v-img :src="require('@/assets/img/ico/hinzufuegen-box.svg')" alt=""
                                   class="cursor-pointer" style="width:46px; height:auto;" max-width="46px">
                            </v-img>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" class="mr-0 pr-0 pl-4">
                    <v-row class="pr-4">
                      <template v-if="!!residentArticlesByResidentID(datum.choosenMonthYear, b.internalID) && searchState == false">
                        <v-col cols="4"
                               v-for="(p,index) in residentArticlesByResidentID(datum.choosenMonthYear, b.internalID).children"
                               :key="'p-'+p.internalID">
                          <v-lazy>
                            <v-card class="mb-0 px-4 pb-1 py-6 pb-0" rounded elevation="0"
                                    style="min-height:245px;width: 100%;border: 1px #cccccc solid;">
                              <div class="d-block" style="position: relative">
                                <v-row>
                                  <v-col cols="3" class="my-0 py-0">
                                  <span v-if="bilder[p.internalID] ">
                                    <v-img
                                        :src="bilder[p.internalID]['Z_URL']+'?width=80'"
                                        height="60" max-width="90" contain></v-img>
                                  </span>
                                    <span v-else-if="p.bild">
                                    <v-img :src="p.bild"
                                           height="60" max-width="90" contain></v-img>
                                  </span>
                                    <span v-else>
                                    <v-img :src="require('@/assets/img/ico/sortimentsverwaltung-aktiv.svg')" alt=""
                                           width="35" height="60" contain></v-img>
                                  </span>
                                  </v-col>
                                  <v-col cols="7" class="my-0 py-0 pt-1"
                                         style="overflow: hidden;max-height: 120px; min-height: 100px">
                                    <div>
                                      <span class="body-2 heavy" v-html="getHighlightProductText(p.showID)"></span> <span
                                        v-if="p.ownartno">|</span>
                                      <span class="body-2 heavy" v-if="p.ownartno"
                                            v-html="getHighlightProductText(p.ownartno)"></span>
                                      <br>

                                      <div class="body-1 pt-0" style="font-size: 14px !important;">
                                        <div style=" max-height: 40px;" v-html="getHighlightProductText(p.name)"></div>
                                        <div class="body-2 caption mt-1" style="font-size: 11px !important;"
                                             v-if="p.zaehleinheit">pro {{ p.zaehleinheit }}
                                        </div>
                                        <div v-if="p.migel"
                                             style="background:#ccc;margin-top: 5px;display: inline-block; color: #fff; font-size: 12px;line-height:17px;padding: 2px 5px 0px 5px;font-weight: bold">
                                          MiGeL
                                        </div>
                                        <div v-if="p.geloescht === 1 || (p.nachrichten && p.nachrichten.length > 0) "
                                             :style="!p.migel ? 'padding-left: 0 !important' : ''"
                                             style="margin-top: 4px;display: inline-block; color: #fff; font-size: 12px;line-height:17px;padding: 2px 5px 0px 10px;font-weight: bold">
                                          <v-tooltip top class="body-2 py-1 px-2 ">
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-hover v-slot="{ hover }" open-delay="0">
                                                <v-icon :color="p.geloescht === 1 ? 'red' : 'orange'" v-bind="attrs"
                                                        size="22" v-on="on" style="margin-top: -2px;">
                                                  mdi-information-outline
                                                </v-icon>
                                              </v-hover>
                                            </template>
                                            <div>
                                            <span v-if="p.geloescht === 1">
                                              <span v-if="p.internalID.indexOf('SKU-') === -1" style="font-size: 13px;">Dieses Produkt ist nicht mehr im Puras Sortiment</span>
                                              <span v-else>Dieses Produkt wurde aus dem Sortiment gelöscht</span>
                                              <span v-if="p.ersatzartikel" style="font-size: 13px;"><br>Als Ersatzartikel empfehlen wir: {{ p.ersatzartikel }}</span>
                                              <br><br>
                                            </span>
                                              <div v-for="(n,index) in p.nachrichten">
                                                <span style="font-size: 13px;">{{ n.Titel_DE }}<br>{{ n.Text_DE }}</span>
                                              </div>
                                            </div>
                                          </v-tooltip>
                                        </div>
                                      </div>
                                    </div>
                                  </v-col>
                                  <v-col cols="2">
                                  <span class="float-right" style="margin-top:-30px;">
                                    <v-hover v-if="moment(datum.choosenMonthYear).format('YYYYMM') >= moment().format('YYYYMM')"
                                             v-slot="{ hover }" open-delay="0">
                                      <v-img class="mt-6 cursor-pointer"
                                             @click="deleteProdFromBewohner(p, b.internalID,'delete')"
                                             :src="hover ? require('@/assets/img/ico/loeschen-hover.svg') : require('@/assets/img/ico/loeschen.svg')"
                                             alt="" max-width="28"></v-img>
                                    </v-hover>
                                    <v-hover v-else
                                             v-slot="{ hover }" open-delay="0">
                                      <v-img class="mt-6 cursor-pointer"
                                             @click="deleteProdFromBewohner(p, b.internalID,'reset')"
                                             :src="hover ? require('@/assets/img/ico/radiergummi-aktiv.svg') : require('@/assets/img/ico/radiergummi.svg')"
                                             alt="" width="22" height="22"></v-img>
                                    </v-hover>
                                  </span>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" class="pb-0 mb-0 pt-1">
                                  </v-col>
                                  <v-col cols="8" class="py-0 pt-2">
                                    <v-card v-if="p.inkosync !== 1" class="mb-6 pb-0 mb-0" elevation="0" style="border: none;padding: 3px 10px 0 10px;" :style="p.currPlandaten.intervall != 0 ? 'background-color: #F4EDE780 !important;border: 1px #fff solid;' : 'background: #fff !important; border: 1px #ccc solid;'">
                                      <v-row class="pb-0 mb-0  pt-2 intervall-box">
                                        <v-col :cols="p.currPlandaten.intervall != 0 ? '8' : '12'" class="mb-0 pb-0">
                                          <v-select style="font-size: 14px;" @change="updateInterval(b.internalID, p)" :items="intervall" label="Planung" item-text="name" item-value="key"
                                                    v-model="p.currPlandaten.intervall"></v-select>
                                        </v-col>
                                        <v-col cols="4" class="py-0 pt-3 mb-0" v-if="p.currPlandaten.intervall != 0">
                                          <v-text-field style="font-size: 14px;" min="1" oninput="validity.valid||(value='');" max="1000" :rules="intervalRules" label="Anzahl" @input="updateInterval(b.internalID, p)" class="text-right" type="number" v-model="p.currPlandaten.count"></v-text-field>
                                        </v-col>
                                      </v-row>
                                    </v-card>
                                    <v-card v-else elevation="0" style="margin-top: 4px;border: none; height: 84px;padding: 3px 10px 0 10px;background: #fff !important; border: 1px #ccc solid;">
                                      <v-row class="pb-0 mb-0 pt-3 intervall-box">
                                        <v-col>
                                        <span>
                                          <v-icon>
                                            mdi-calendar-sync-outline
                                          </v-icon>
                                          <span class="caption small">
                                            Inkoplaner-Synchronisation
                                          </span>
                                        </span>
                                        </v-col>
                                      </v-row>
                                    </v-card>
                                  </v-col>
                                  <v-col cols="3" style="position: relative; padding-top: 21px;">
                                  <span class="float-right text-right">
                                    <div style="font-size: 15px; color: #6A6868;">
                                      Zählstand
                                    </div>
                                    <span class="pt-4 font-weight-light" v-if="verbrauchTotals[b.internalID+'-'+p.internalID]" style="font-size: 32px;margin-top: 5px;">
                                      {{ verbrauchTotals[b.internalID + '-' + p.internalID] }}
                                    </span>
                                    <span v-else class="font-weight-light" style="font-size: 32px;">
                                      0
                                    </span>
                                  </span>
                                    <div style="position: absolute; top: 33px;right:-20px;">
                                    <span class="float-right" v-if="moment(datum.choosenMonthYear).format('YYYYMM') == moment().format('YYYYMM')">
                                      <v-tooltip top class="body-2 py-1 px-2 ">
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-hover v-slot="{ hover }" open-delay="0">
                                            <v-img v-bind="attrs" v-on="on" @click="addProdToday(p.internalID,b.internalID,'add')" class="mt-5 ml-4 add-btn cursor-pointer" :src="require('@/assets/img/ico/plus-aktiv.svg')" alt="" width="24" height="24"></v-img>
                                          </v-hover>
                                        </template>
                                        <span style="font-size: 13px;">+1 für heute, {{ moment().format('D.M') }}, hinzufügen </span>
                                      </v-tooltip>
                                    </span>
                                      <span v-else class="float-right">
                                      <v-hover v-slot="{ hover }" open-delay="0">
                                        <v-img contain @click="startTimeline(b.internalID)" class="mt-4 ml-4  cursor-pointer" :src="hover ?  require('@/assets/img/ico/kalender-hover.svg') : require('@/assets/img/ico/kalender.svg')" alt="" width="20" height="20"></v-img>
                                      </v-hover>
                                    </span>
                                    </div>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-card>
                          </v-lazy>
                        </v-col>
                      </template>
                      <v-col cols="4" v-else>
                        <v-progress-circular color="primary" indeterminate></v-progress-circular>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <!--</Scrollama>-->
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div v-if="search != '' && srchHits == 0" class="mt-12">
            Keine Ergebnisse mit dem Suchbegriff "{{ search }}"
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="addProductModal" max-width="1100" style="overflow-y:auto;">
      <product-chooser @confirmedAssignProduct="confirmedAssignProduct" @closeProdChooser="addProductModal = false" :idfilter="mySortimentIDFilter" :prodinfos="prodInfos" :producttarget="productTarget" :productcategories="productCategories"></product-chooser>
    </v-dialog>
    <v-dialog v-model="timelineModal" persistent max-width="1700" style="width:85vw">
      <product-timeline :key="'tl'+timelineKey" @closeTimeModal="closeTimeModal" :datum="datum" :b="choosenTimelineBewohner" :usedprop="choosenTimelineBewohnerUsed"></product-timeline>
    </v-dialog>
    <v-dialog v-model="bewohnerDeletedInfoModal" max-width="500" style="overflow-y:auto;">
      <v-card flat class="px-4 pb-0">
        <v-card-title class="headline text-h5 py-8" style="position: sticky;top: 0px;width: 100%; background: #fff;z-index: 8">
          <div class="op-hover" style="position:absolute;right: 26px; top: 32px;z-index:999">
            <img :src="require('@/assets/img/ico/schliessen.svg')" @click="bewohnerDeletedInfoModal = false" width="25px" height="25px" max-width="25px"
                 max-height="25px" class="mx-auto cursor-pointer" contain="contain">
          </div>
          <span class="dialog-title">Bewohner ausgetreten</span>
        </v-card-title>
        <v-card-text class="body-1">
          <v-row>
            <v-col cols="12">
              Der Bewohner ist in diesem Monat ausgetreten. Bitte prüfen Sie den Verbrauch und passen Sie diesen an.
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn class="float-right" color="primary" depressed @click="bewohnerDeletedInfoModal = false">OK</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import httpClient from '../utils/httpClient'
import ProductCaption from '../components/products/ProductCaption'
import ProductChooser from '../components/products/ProductChooser'
import ProductTimeline from '../components/products/ProductTimeline'
import { mapGetters } from 'vuex'

import Intersect from 'vue-intersect'

//import 'intersection-observer'
//import Scrollama from 'vue-scrollama'

import moment from 'moment'
import tr from 'vue2-datepicker/locale/es/tr'
import ca from 'vue2-datepicker/locale/es/ca'

export default {
  name: 'Strichliste',
  components: { ProductCaption, ProductChooser, ProductTimeline, Intersect/*Scrollama*/ },
  data () {
    return {
      msg: '',
      intervalRules: [
        v => !!v || '',
        v => (v && v >= 1) || '',
        v => (v && v <= 999) || '',
      ],
      timelineKey: 0,
      searchTypeTimeout: false,
      choosenTimelineBewohner: false,
      choosenTimelineBewohnerUsed: false,
      addProductModal: false,
      bewohnerDeletedInfoModal: false,
      timelineModal: false,
      searchTimeout: null,
      productCategories: [],
      fetchedResidents: [],
      verbrauchTotals: [],
      mySortimentIDFilter: [],
      panels: [],
      panelsCache: [],
      disablePanelRendering: false,
      prodInfos: [],
      activeAcc: [],
      search: '',
      searchTarget: 'bewohner',
      srchHits: 0,
      searchState: false,
      loading: true,
      productTarget: {
        'targetType': 'bewohner',
        'internalID': 0,
        'prodID': 0,
        'monthYear': 0,
        'caption': ''
      },
      intervall: [
        {
          key: 0,
          name: 'kein Intervall'
        },
        {
          key: 1,
          name: 'täglich'
        }, {
          key: 7,
          name: 'wöchentlich'
        }, {
          key: 28,
          name: 'monatlich'
        }
      ],
      moment: moment,
      //initial per JS setzen aber on mount per PHP request holen damit das Datum sicherlich überall gleich ist & nicht von Client abhängig ist
      datum: {
        actualMonthYear: moment().startOf('month').format('YYYY-MM-DD'),
        choosenMonthYear: moment().startOf('month').format('YYYY-MM-DD'),
        daysInMonth: moment().daysInMonth()
      },
    }
  },

  computed: {
    ...mapGetters({
      'residentArticlesByResidentID': 'tree/residentData',
      'wohneinheitenMitBewohnerBase': ['tree/livingUnitsWithResidents'],
      'livingUnitsWithResidents': ['tree/livingUnitsWithResidents'],
      'getLivingUnitData': ['tree/livingUnitData']
    }),

    bilder: {
      get () {
        return this.$store.state['bilder'].produktbilder
      }
    },

    srchtxt () {

      if (this.searchTarget == 'produkte') {
        return 'Suche nach Produkt (Name, ArtikelNr)'
      } else {
        return 'Suche nach Bewohner (Zimmer, Name)'
      }
    },

    searchIsActive () {
      return this.search && this.search.length > 0
    },

    dateTree () {
      return this.$store.getters['tree/dataTree'](this.datum.choosenMonthYear)
    },

    filteredLivingUnitsWithResidents () {
      if (!this.dateTree) {
        return []
      }

      if (this.searchIsActive) {
        this.disablePanelRendering = true
      }

      var self = this

      if (!this.$refs.bewSearch) {
        return this.sortTree(this.dateTree)
      }

      if (!this.searchIsActive) {
        self.searchState = false

        // setTimeout(() => {
        //   self.disablePanelRendering = false
        // }, 100)

        return this.sortTree(this.livingUnitsWithResidents)
      }

      self.panels = []

      self.searchState = true

      var search = self.search
      self.srchHits = 0

      let whb = JSON.parse(JSON.stringify({ children: self.dateTree }))

      let whbs = whb.children.filter(function (wh) {

        var bew = wh.children.filter(function (b) {

          //auch nach Artikelnamen filtern
          if (self.searchTarget == 'produkte' && b.children) {
            var prod = b.children.filter(function (p) {
              return (
                  p.name.toString().toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                  p.showID.toString().toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                  (p.ownartno && p.ownartno.toString().toLowerCase().indexOf(search.toLowerCase()) > -1)
              )
            })

            return prod.length > 0

          } else {
            try {
              return (
                  b.zimmer === null || (
                      b.name.toString().toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                      b.zimmer.toString().toLowerCase().indexOf(search.toLowerCase()) > -1
                  )
              )
            } catch (e) {

            }
          }
        })

        self.srchHits += bew.length

        if (bew.length > 0) {
          wh.children = bew
        } else {
          wh.children = []
        }
        return wh
      })

      // self.searchShowProdTimeout = setTimeout(function () {
      self.searchState = false
      // }, 800)

      for (var i = 0; i < self.wohneinheitenMitBewohnerBase.children.length; i++) {
        self.panels.push(i)
      }

      if (this.searchIsActive) {
        setTimeout(() => {
          self.disablePanelRendering = false
        }, 100)
      }

      return { children: this.sortTree(whbs) }
    }
  },

  methods: {
    sortTree(tree) {
      for (var i in tree.children) {
        tree.children[i].children = this.sortResidents(tree.children[i].children)
      }

      return tree
    },

    sortResidents(residents) {
      return residents.sort((a, b) => {
        if(!a.zimmer || !b.zimmer) {
          return 0
        }

        const zimmerA = a.zimmer.toLowerCase()
        const zimmerB = b.zimmer.toLowerCase()

        const zimmerAParsed = zimmerA.match(/(\d+|\D+)/g)
        const zimmerBParsed = zimmerB.match(/(\d+|\D+)/g)

        for (let i = 0; i < Math.max(zimmerAParsed.length, zimmerBParsed.length); i++) {
          const partA = zimmerAParsed[i] || ''
          const partB = zimmerBParsed[i] || ''

          const numA = parseInt(partA, 10)
          const numB = parseInt(partB, 10)

          if (!isNaN(numA) && !isNaN(numB)) {
            if (numA < numB) return -1
            if (numA > numB) return 1
          } else {
            if (partA < partB) return -1
            if (partA > partB) return 1
          }
        }

        // If zimmer is the same, sort by name
        const nameA = a.name.toLowerCase()
        const nameB = b.name.toLowerCase()

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0
      })
    },

    setSearch (e) {
      clearTimeout(this.searchTimeout)

      this.searchTimeout = setTimeout(() => {
        this.search = e.target.value
      }, 400)
    },

    resetSearch () {
      this.disablePanelRendering = true
      this.panels = this.panelsCache
      this.search = ''

      setTimeout(() => {
        this.disablePanelRendering = false
      }, 100)
    },

    getHighlightProductText (textVal) {

      if (!this.searchTarget == 'produkte' || !this.search || this.search == '') {
        return textVal
      }

      try {
        return textVal.replace(new RegExp(this.search, 'gi'), match => {
          return '<span class="highlightText">' + match + '</span>'
        })
      } catch (e) {
        return textVal
      }

    },

    stepEnterHandler ({ element, index, direction }) {
      /*console.log({ element, index, direction });
      console.log(element.dataset.bewname)*/
    },

    async addProdToday (prodID, bewohnerID, editmode) {
      const res = await httpClient({
        url: process.env.VUE_APP_API_CHECKLIST + 'add/product-to-date',
        method: 'post',
        data: {
          choosenMonthYear: this.datum.choosenMonthYear,
          day: false,
          counter: false,
          prodID: prodID,
          bewohnerID: bewohnerID,
          editmode: editmode
        }
      })

      if (res.type == 'success') {
        this.refreshTotals()
      }
    },

    closeTimeModal () {
      this.refreshTotals()
      this.timelineModal = false
    },

    async refreshTotals () {
      const resTotals = await httpClient({
        url: process.env.VUE_APP_API_CHECKLIST + 'get/verbrauch-totals',
        method: 'post',
        data: { choosenMonthYear: this.datum.choosenMonthYear }
      })

      if (resTotals.type == 'success') {
        this.verbrauchTotals = resTotals.verbrauchTotals
      }
    },

    setActiveAccDelay (n) {
      // a delay is required to cache current open panels
      setTimeout(() => {
        this.panelsCache = this.panels
      }, 50)

      var self = this
      var arrIndex = self.activeAcc.indexOf(n)
      if (arrIndex == -1) {
        self.activeAcc.push(n)
      } else {
        self.activeAcc.splice(arrIndex, 1)
      }
    },

    /**
     *
     * @param residentId
     * @param article
     */
    updateInterval (residentId, article) {
      this.$store.dispatch('tree/updateInterval', {
        dateString: this.datum.choosenMonthYear,
        residentId: residentId,
        article: article,
      })
          .then(response => {
            if (response.type === 'success') {
              this.refreshTotals()
            }
          })
    },

    /**
     * @deprecated use updateInterval instead
     *
     * @param b
     * @param articles
     * @returns {Promise<void>}
     */
    // async addIntervals (b, articles) {
    //   var self = this
    //
    //   b.children = articles
    //
    //   let res = await httpClient({
    //     url: process.env.VUE_APP_API_CHECKLIST + 'save/plandaten',
    //     method: 'post',
    //     data: { bewohnerWithProdukte: b, choosenMonthYear: self.datum.choosenMonthYear }
    //   })
    //
    //   if (res.type == 'success') {
    //     this.refreshTotals()
    //   }
    // },

    /**
     *
     * @param residentId
     * @returns {Promise<void>}
     */
    async startTimeline (residentId) {

      const resident = this.$store.getters['tree/residentData'](this.datum.choosenMonthYear, residentId)

      httpClient.post(process.env.VUE_APP_API_CHECKLIST + 'get/verbrauch-resident-tree', {
        choosenMonthYear: this.datum.choosenMonthYear,
        bewohnerID: residentId
      })
          .then(response => {
            this.choosenTimelineBewohnerUsed = response
            this.choosenTimelineBewohner = resident
              if (resident.isDeleted) {
                this.bewohnerDeletedInfoModal = true
              }

            this.timelineKey++
            this.timelineModal = true
          })


      // const res = await httpClient({
      //   url: process.env.VUE_APP_API_CHECKLIST + 'get/verbrauch-resident-tree',
      //   method: 'post',
      //   data: { choosenMonthYear: this.datum.choosenMonthYear, bewohnerID: bewohnerID }
      // })
      //
      // if (res.type == 'success') {
      //   let b = res.tree.children[0].children[0]
      //   this.choosenTimelineBewohnerUsed = res
      //   this.choosenTimelineBewohner = b
      //   if (b.isDeleted) {
      //     this.bewohnerDeletedInfoModal = true
      //   }
      // }
      //
      // this.timelineKey++
      // this.timelineModal = true
    },

    async deleteProdFromBewohner (prod, bewohnerID, mode) {

      var prodId = prod.internalID
      var msg

      if (mode === 'delete') {
        if (prod.inkosync === 1) {
          msg = 'Bei diesem Produkt handelt es sich um eine Synchronisation aus dem Inkoplaner. Wenn Sie das Produkt im Inkoplaner nicht ebenfalls gelöscht haben, wird es in einigen Stunden wieder erscheinen. Es werden alle zukünftigen Einträge gelöscht. Einträge in der Vergangenheit bleiben bestehen.'
        } else {
          msg = 'Produkt auf diesem Bewohner wirklich löschen? Es werden alle zukünftigen Einträge gelöscht. Einträge in der Vergangenheit bleiben bestehen.'
        }
      } else {
        msg = 'Möchten Sie alle Einträge dieses Monats auf dem Produkt löschen? Das Produkt selber bleibt bestehen.'
      }

      this.$confirm(msg, {
        title: 'Achtung',
        buttonTrueText: 'Löschen',
        buttonFalseText: 'Abbrechen'
      }).then(async confirmed => {
        if (confirmed) {
          this.$store.dispatch('tree/removeArticle', {
            prodId: prodId,
            bewohnerID: bewohnerID,
            dateString: this.datum.choosenMonthYear
          })
              .then(response => {
                if (response.type == 'success') {
                  if (mode !== 'delete') {
                    this.refreshTotals()
                  }
                }
              })
        }
      })
    },

    prepareAssignProd (targetType, internalID, caption) {

      this.productTarget.targetType = targetType
      this.productTarget.internalID = internalID

      if (caption == 'all') {
        this.productTarget.caption = 'Produkt an allen Wohneinheiten und Bewohnern zuweisen'
      } else if (targetType == 'bewohner') {
        this.productTarget.caption = 'Produkt <span class="primary--text">' + caption + '</span> zuweisen'
      } else if (targetType == 'wohneinheit') {
        this.productTarget.caption = 'Produkt der Wohneinheit <span class="primary--text">' + caption + '</span> zuweisen'
      }
      this.addProductModal = true
    },

    async changeChoosenDate (addOrSubOrToday) {
      if (addOrSubOrToday == 'sub') {
        this.datum.choosenMonthYear = moment(this.datum.choosenMonthYear).subtract(1, 'month').format('YYYY-MM-DD')
      } else if (addOrSubOrToday == 'add') {
        this.datum.choosenMonthYear = moment(this.datum.choosenMonthYear).add(1, 'month').format('YYYY-MM-DD')
      } else if (addOrSubOrToday == 'today') {
        this.datum.choosenMonthYear = moment().startOf('month').format('YYYY-MM-DD')
      }

      this.datum.daysInMonth = moment(this.datum.choosenMonthYear).daysInMonth()

      this.$store.dispatch('tree/fetchWholeTree', this.datum.choosenMonthYear)
          .then(() => {
            this.refreshTotals()
          })
    },

    showDefaultWH () {
      let self = this
      this.livingUnitsWithResidents.children.forEach(function (e, k) {
        if (e.internalID === self.$store.state.benutzer.info.default_wh) {
          self.setActiveAccDelay(k)
          self.panels.push(k)
        }
      })
    },

    async assignProd (prodID) {
      if (this.productTarget.targetType != 'bewohner') {
        this.$confirm('Dieses Produkt wird mehreren Bewohnern automatisch zuegordnet. Möchten Sie weiterfahren?', {
          title: 'Achtung',
          buttonTrueText: 'Hinzufügen',
          buttonFalseText: 'Abbrechen'
        }).then(async confirmed => {
          if (confirmed) {
            this.confirmedAssignProduct(prodID)
          }
        })
      } else {
        this.confirmedAssignProduct(prodID)
      }
    },

    async confirmedAssignProduct (prodID) {
      this.productTarget.prodID = prodID
      this.productTarget.monthYear = this.datum.choosenMonthYear

      this.$store.dispatch('tree/assignArticle', {
        dateString: this.productTarget.monthYear,
        prodId: this.productTarget.prodID,
        targetType: this.productTarget.targetType,
        internalID: this.productTarget.internalID,
      })
          .then(response => {
            if (response.type == 'success') {
              this.refreshTotals()
            }
          })
    },

    /**
     *
     * @param livingUnitId
     * @param dateString
     */
    fetchLivingUnitArticles (livingUnitId, dateString) {
      if (this.getLivingUnitData(livingUnitId, dateString).length === 0) {
        this.$store.dispatch('tree/fetchLivingUnitTree', { livingUnitId: livingUnitId, dateString: dateString })
      }
    }

  },

  async mounted () {

    await this.$store.dispatch('tree/fetchLivingUnits', this.datum.choosenMonthYear).then(() => {
      this.showDefaultWH()
    })

    const resDatum = await httpClient({
      url: process.env.VUE_APP_API_SHARED + 'get/get-global-infos',
      method: 'get'
    })

    if (resDatum.type == 'success') {
      this.datum = resDatum.datuminfo
    }

    const resCategories = await httpClient({
      url: process.env.VUE_APP_API_SHARED + 'get/categories-sort-array',
      method: 'get'
    })

    if (resCategories.type == 'success') {
      this.productCategories = resCategories.categories
    }

    const resInfo = await httpClient({
      url: process.env.VUE_APP_API_SHARED + 'get/prodinfo',
      method: 'get'
    })

    if (resInfo.type == 'success') {
      this.prodInfos = resInfo.infos
    }

    const resSortiment = await httpClient({
      url: process.env.VUE_APP_API_SHARED + 'get/mein-sortiment',
      method: 'get'
    })

    if (resSortiment.type == 'success') {
      this.mySortimentIDFilter = resSortiment.mysortimentFilter
    }

    this.loading = false

    this.refreshTotals()

  },

  async created () {
    let res = await httpClient({
      url: process.env.VUE_APP_API_SHARED + 'get/product-images',
      method: 'GET'
    })
    this.$store.commit('bilder/setProduktBilder', res.produktbilder)
    this.$store.dispatch('tree/fetchWholeTree', this.datum.choosenMonthYear)
  }
}
</script>
<style lang="scss">
@import "./src/scss/strichliste";
</style>
