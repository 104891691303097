<template>
  <svg width="10px" height="16px" viewBox="0 0 10 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>inko-grad-leicht</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="inko-grad-leicht" transform="translate(0.249000, 0.410000)" fill="#F38B1C" fill-rule="nonzero">
        <path d="M5.1,1.7 C6.5,3.9 8.5,7.7 8.5,10 C8.5,12 6.8,13.6 4.8,13.6 L4.7,13.6 L4.6,13.6 C2.7,13.6 1,12 1,10 C1,7.6 3.5,3.8 5.1,1.7 M5.2,0 C5.2,0 0,6.1 0,10 C0,12.6 2.1,14.6 4.7,14.6 C4.7,14.6 4.8,14.6 4.8,14.6 C4.8,14.6 4.9,14.6 4.9,14.6 C7.4,14.6 9.5,12.6 9.6,10 C9.5,6.3 5.2,0 5.2,0 L5.2,0 Z" id="Pfad_2567_00000116947506124849047620000016654838389363640744_"></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'DripOutlinedIcon'
}
</script>

<style scoped>

</style>