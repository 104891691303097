<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="13.87" height="15.3" viewBox="0 0 13.87 15.3">
    <defs>
      <clipPath id="clip-path">
        <rect width="13.87" height="15.3" transform="translate(0 0)" fill="#6a6868"/>
      </clipPath>
    </defs>
    <g>
      <path d="M6.514,14.01h-.2a3.65,3.65,0,0,1-3.6-3.6,8.389,8.389,0,0,1,.7-2.924l-.8-.635a9.58,9.58,0,0,0-.9,3.559,4.61,4.61,0,0,0,4.7,4.6h.2a4.675,4.675,0,0,0,3.8-1.976L9.6,12.382A3.743,3.743,0,0,1,6.514,14.01" fill="#6a6868"/>
      <path d="M13.87,13.457.681,3.013,0,3.858,13.189,14.3Z" fill="#6a6868"/>
      <path d="M6.494,2.11c1.24,1.949,3.194,5.148,3.626,7.453L11.3,10.5c0-.03.011-.058.012-.088-.1-3.7-4.819-10-4.819-10A37.445,37.445,0,0,0,3.767,4.533l.768.607A25.733,25.733,0,0,1,6.494,2.11" fill="#6a6868"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'DripCancledIcon'
}
</script>

<style scoped>

</style>