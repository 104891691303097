<template>
  <v-form v-model="valid" ref="form">
    <div class="grid">
      <div class="custom-box">
        <h4>Pflegeinstitution</h4>

        <div>
          <v-text-field
              v-model="editablePrescription.institution.email"
              type="email"
              label="E-Mail"
              :rules="[rules.required, globalRules.ruleEmail]"
              :disabled="disabled"
          />
        </div>
      </div>

      <div class="custom-box">
        <h4>Rezeptierende Stelle (Arzt)</h4>
        <div class="doc-details">
          <v-text-field
              v-model="editablePrescription.doc.name"
              label="Vor- und Nachname"
              :rules="[rules.required]"
              :disabled="disabled"
          />

          <v-text-field
              v-model="editablePrescription.doc.email"
              label="E-Mail"
              :rules="[rules.required, globalRules.ruleEmail]"
              :disabled="disabled"
          />

          <v-text-field
              v-model="editablePrescription.doc.zsr"
              label="ZSR"
              :rules="[rules.required, globalRules.ruleZSR]"
              :disabled="disabled"
          />
        </div>
      </div>

      <div class="custom-box cols-2">
        <h4>Patient*in</h4>

        <div class="patient-details">
          <v-text-field
              v-model="editablePrescription.patient.firstname"
              label="Vorname"
              readonly
              disabled
          />

          <v-text-field
              v-model="editablePrescription.patient.lastname"
              label="Name"
              readonly
              disabled
          />

          <v-text-field
              v-if="bewohnerData.geburtsdatum"
              :value="asMoment(editablePrescription.patient.birthdate).format('DD.MM.YYYY')"
              label="Geburtsdatum"
              readonly
              disabled
          >
            <template v-slot
          </v-text-field>
          <div
              v-else
              class="mt-3"
          >
            <CustomDatePicker
                label="Geburtsdatum"
                :formValues="editablePrescription.patient"
                name="birthdate"
                type="birthday"
                :rules="[rules.required,globalRules.ruleBefore(editablePrescription.patient.birthdate, asMoment().format('YYYY-MM-DD'), 'Das Geburtsdatum muss in der Vergangenheit liegen')]"
                :max-date="asMoment().format('YYYY-MM-DD')"
                :start-date="defaultBirthdayStartDate"
            />
          </div>

          <v-select
              v-model="editablePrescription.patient.sex"
              label="Geschlecht"
              readonly
              disabled
              :items="[
                { text: 'männlich', value: 'm' },
                { text: 'weiblich', value: 'w' }
            ]"
          />

          <v-text-field
              v-model="editablePrescription.patient.insuranceNumber"
              label="Versichertennummer"
              :rules="[rules.required]"
              :disabled="disabled"
          />

          <div class="cols-2">
            <KrankenkasseSelectDialog
                :bewohner='bewohnerData'
                :selected='editablePrescription.patient.krankenkassen_i'
                :required='true'
                @choose="setKrankenkasse($event)"
            >
              <template #button="slotProps">
                <v-autocomplete
                    label="Krankenkasse"
                    class="custom-disabled overflow-select"
                    v-model="editablePrescription.patient.krankenkassen_id"
                    :items="krankenkassen"
                    :readonly="true"
                    clearable
                    clear-icon="mdi-close"
                    append-icon=""
                    item-text="Versicherer_Name"
                    item-value="ID"
                    @click="slotProps.open()"
                    variant="underlined"
                    :disabled="disabled"
                    :rules="[rules.required]"
                >
                  <template v-slot:selection="data">
                    {{ data.item.Versicherer_Name }}
                  </template>
                  <template v-slot:append>
                    <img width="20" height="20" :src="require('@/assets/img/ico/suche.svg')"
                         style="position:relative; top: 1px"
                         class="cursor-pointer" @click="slotProps.open()">
                  </template>
                </v-autocomplete>
              </template>
            </KrankenkasseSelectDialog>
          </div>

          <v-text-field
              :value="selectedKrankenkasse? `${selectedKrankenkasse.PLZ} ${selectedKrankenkasse.Ort}`: ''"
              label="Adresse Krankenkasse"
              readonly
              disabled
          />
        </div>
      </div>

      <div class="custom-box cols-2">
        <h4>Diagnose / Therapieverordnung</h4>

        <div class="diagnose-details">
          <v-textarea
              v-model="editablePrescription.diagnose.text"
              placeholder="Diagnose"
              no-resize
              :rules="[rules.required]"
              :disabled="disabled"
          ></v-textarea>

          <div>
            <custom-date-picker
                label="Verordnungdatum"
                name="date"
                :form-values="editablePrescription.diagnose"
                :rules="[rules.required]"
                :validate-on-blur="true"
            />
          </div>

          <v-radio-group
              v-model="editablePrescription.diagnose.type"
              row
              :rules="[rules.required]"
          >
            <v-radio
                label="Krankheit"
                value="Krankheit"
                :disabled="disabled"
            />
            <v-radio
                label="Unfall"
                value="Unfall"
                :disabled="disabled"
            />
          </v-radio-group>
        </div>
      </div>
    </div>
  </v-form>
</template>

<script>
import CustomDatePicker from '@/components/form/CustomDatePicker.vue'
import { globalRules } from '@/composables/rules'
import KrankenkasseSelectDialog from '@/components/Bewohner/Bewohnerverwaltung/dialogs/KrankenkasseSelectDialog.vue'
import { krankenkassenGetters } from '@/components/observables/krankenkassen'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'MedicalPrescriptionGeneratorGeneral',
  components: { KrankenkasseSelectDialog, CustomDatePicker },
  emits: ['input'],

  props: {
    editablePrescription: {
      type: Object,
      required: false,
    },
    bewohnerData: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      defaultBirthdayStartDate: moment().month(1).day(1).subtract(moment().year() % 10, 'year').subtract(80, 'year'),
      globalRules: globalRules,
      valid: false,
      rules: {
        required: value => !!value || 'Dieses Feld wird benötigt',
      }
    }
  },

  computed: {
    ...krankenkassenGetters,

    selectedKrankenkasse () {
      return this.krankenkassen.find(krankenkasse => krankenkasse.ID === this.editablePrescription.patient.krankenkassen_id)
    },
  },

  methods: {
    setKrankenkasse (krankenkassenID) {
      this.editablePrescription.patient.krankenkassen_id = krankenkassenID
    },

    asMoment (date = null, locale = 'de') {
      return date ? moment(date).locale(locale) : moment().locale(locale)
    },

    validate () {
      this.$refs.form.validate()
      this.$emit('input', this.valid)
    },
  },

  watch: {
    editablePrescription: {
      handler: function () {
        this.$emit('input', this.valid)
      },
      deep: true,
    },
  },
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  margin-block-end: 40px;

  .cols-2 {
    grid-column: span 2;
  }
}

.doc-details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 4rem;
  row-gap: .2rem;
}

.patient-details {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 4rem;
  row-gap: .2rem;
}

.diagnose-details {
  display: grid;
  grid-template-columns: 3fr 1fr;
  column-gap: 4rem;
  row-gap: .2rem;
}

::v-deep {
  .v-select__slot {
    height: 32px;

    input {
      display: none;
    }
  }

  .v-textarea {
    margin-top: 0;
    padding-top: 0;

    textarea {
      height: 80px;
      padding: .5rem 1rem;
      background-color: #ffffff;
      border-radius: 10px;
    }

    .v-input__slot::before,
    .v-input__slot::after, {
      border: none;
    }

    .v-text-field__details {
      margin-left: 1rem;
    }
  }
}
</style>