<template>
  <div>
    <v-data-table :headers="headers"
                  :items="getAllAddresses()"
                  hide-default-footer
                  item-value="id"
                  class="custom-data-table custom-padding custom-selectable">
      <template v-slot:bottom>
      </template>
      <template v-slot:item="{ item }">
        <v-hover v-slot="{hover}">
          <tr class='table-row cursor-pointer'
              :class="{selected: formValues.anschrift == item.id, hover: hover}"
              @click="selectAnschrift(item)">
            <td width="50px" class="v-data-table__td v-data-table-column--align-end" style="padding-left: 16px;">
                  <img
                          :src="formValues.anschrift == item.id ? require('@/assets/img/ico2023/kreis-ausgewaehlt.svg') : require('@/assets/img/ico2023/kreis-leer.svg')" class="select-icon"></img>
            </td>
            <td>{{ item.Firma }}</td>
            <td> {{ item.Zimmer }}</td>
            <td> {{ item.Strasse + " " + item.HausNr }}</td>
            <td>{{ item.PLZ }}</td>
            <td>{{ item.Ort }}</td>
            <td class="text-right">
              <template v-if="['heim', 'wohneinheit'].indexOf(item.id) == -1">
                               <span class="editIcon">
                                           <AnschriftDialog @save="editAnschrift($event)"
                                                            @delete="deleteCustomAnschrift(item.id)"
                                                            :bewohner="formValues"
                                                            :edit="true"
                                                            :anschrift="item">
                                            <template #button="slotProps">
                                              <v-hover v-slot="{hover}">
                                               <span
                                                   class="cursor-pointer text-center align-center"
                                                   @click="openEditDialog(slotProps, $event)">
                                               <v-img
                                                   :src="hover ? require('@/assets/img/ico/bearbeiten-aktiv.svg') : require('@/assets/img/ico/bearbeiten.svg')"
                                                   max-width="18" class="icon"></v-img>
                                              </span>
                                              </v-hover>
                                            </template>
                                          </AnschriftDialog>
                                      </span>
                <!--                                <span class="deleteIcon">-->
                <!--                                        <v-hover v-slot="{hover}">-->
                <!--                                           <span-->
                <!--                                                   class="cursor-pointer text-center align-center"-->
                <!--                                                   @click="deleteCustomAnschrift(item.id, $event)">-->
                <!--                                           <v-img :src="hover ? require('@/assets/img/ico/loeschen-hover.svg') : require('@/assets/img/ico/loeschen.svg')"-->
                <!--                                                  width="28" class="icon"></v-img>-->
                <!--                                          </span>-->
                <!--                                        </v-hover>-->
                <!--                                  </span>-->
              </template>
            </td>
          </tr>
        </v-hover>
      </template>
      <template v-slot:item.inkograd="{ item }">
        <span class="inko-text">{{ getByGrade(item.inkograd).name }}</span>
        <span class="inko-icon"><InkoGradIndicator :inkograd="item.inkograd"
                                                   :tooltip="false"></InkoGradIndicator></span>
      </template>
      <template v-slot:item.start="{ item }">
        {{ asMoment(item.start).format('MMM YYYY') }}
      </template>
      <template v-slot:item.actions="{ item }">

      </template>
      <template v-slot:no-data>
        <p class="no-data">Keine Addressen erfasst</p>
      </template>
    </v-data-table>
    <v-row>
      <v-col>
        <AnschriftDialog @save="addCustomAnschrift($event)" :bewohner="formValues">
          <template #button="slotProps">
            <div class="add-icon-container" @click="slotProps.open()">
              <v-btn small fab light depressed color="primary" class="addbtn">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <span class="text">Abweichende Anschrift
                            erfassen</span>
            </div>
          </template>
        </AnschriftDialog>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import {wohneinheitenGetters} from "@/components/observables/wohneinheiten";
import {anschriftenActions, anschriftenGetters} from "@/components/observables/anschriften";
import AnschriftDialog from "../dialogs/AnschriftDialog";
import ConfirmDialog from "@/components/shared/ConfirmDialog.vue";
import InkoGradIndicator from "@/components/inkograd/InkoGradIndicator.vue";

export default {
  name: 'Anschrift',
  components: {InkoGradIndicator, ConfirmDialog, AnschriftDialog},
  props: {
    formValues: {
      required: true
    }
  },
  data() {
    return {
      headers: [
        {text: '', value: 'select', align: 'start', sortable: false},
        {text: 'Anschrift', value: 'Firma', align: 'start', sortable: false},
        {text: 'Zimmer', value: 'Zimmer', align: 'start', sortable: false},
        {text: 'Strasse', value: 'Strasse', align: 'start', sortable: false},
        {text: 'PLZ', value: 'PLZ', align: 'start', sortable: false},
        {text: 'Ort', value: 'Ort', align: 'start', sortable: false},
        {text: '', value: 'actions', align: 'end', sortable: false},
      ],
    }
  },

  computed: {
    ...wohneinheitenGetters,
    ...anschriftenGetters,
    wohneinheit() {
      return (this.formValues && this.formValues.wohneinheitenID) ? this.wohneinheiten.find(wh => wh.id === +this.formValues.wohneinheitenID) : null;
    }
  },
  methods: {
    ...anschriftenActions,

    selectAnschrift(anschrift) {
      if (anschrift) {
        switch (anschrift.id) {
          case 'wohneinheit':
            this.formValues.anschrift = "wohneinheit";
            break;
          case'heim':
            this.formValues.anschrift = "heim";
            break;
          default:
            this.formValues.anschrift = anschrift.id;
        }
      } else {
        this.formValues.anschrift = "heim";
      }

    },

    getDefaultWohneinheitAddress(defaultHeimAddress) {
      let address = {};
      //check if wohnheinheit address is same than heimaddress
      if (defaultHeimAddress) {
        let wohneinheitStrasse = this.wohneinheit.Strasse ? this.wohneinheit.Strasse.toLowerCase().trim() : null;
        let wohneinheitPLZ = this.wohneinheit.PLZ ? this.wohneinheit.PLZ.toLowerCase().trim() : null;
        let wohneinheitOrt = this.wohneinheit.Ort ? this.wohneinheit.Ort.toLowerCase().trim() : null;
        let defaultAdresse = '';
        if (defaultHeimAddress.HausNr) {
          defaultAdresse = defaultHeimAddress.Strasse.toLowerCase().trim() + " " + defaultHeimAddress.HausNr.trim()
        } else {
          defaultAdresse = defaultHeimAddress.Strasse.toLowerCase().trim()
        }
        if (
            defaultAdresse === wohneinheitStrasse &&
            defaultHeimAddress.PLZ.toLowerCase().trim() == wohneinheitPLZ &&
            defaultHeimAddress.Ort.toLowerCase().trim() === wohneinheitOrt
        ) {
          return null;
        }
      }
      //if not, get wohneinheit address
      if (this.wohneinheit && this.wohneinheit.Strasse) {
        address.id = "wohneinheit";
        address.Firma = this.wohneinheit.Firma;
        if (this.formValues.zimmer) {
          address.Zimmer = this.formValues.zimmer;
        }
        address.Strasse = this.wohneinheit.Strasse;
        address.HausNr = this.wohneinheit.HausNr ? this.wohneinheit.HausNr : "";
        address.PLZ = this.wohneinheit.PLZ;
        address.Ort = this.wohneinheit.Ort;
        return address;
      }
      return null;
    },
    getDefaultHeimAddress() {
      if (this.$store.state.benutzer.info.anschrift) {
        let address = {};
        let anschrift = this.$store.state.benutzer.info.anschrift;
        if (anschrift.Strasse) {
          address.id = "heim";
          address.Firma = anschrift.NameFirma;
          if (this.formValues.zimmer) {
            address.Zimmer = this.formValues.zimmer;
          }
          address.Strasse = anschrift.Strasse;
          address.HausNr = anschrift.HausNr ? anschrift.HausNr : "";
          address.PLZ = anschrift.PLZ;
          address.Ort = anschrift.Ort;

          return address;
        }
      }
      return null;
    },

    getCustomAddresses() {
      let addresses = [];
      this.anschriften.forEach(anschrift => {
        let address = {};
        address.id = anschrift.id;
        address.Firma = anschrift.Firma;
        address.Strasse = anschrift.Strasse;
        address.HausNr = anschrift.HausNr ? anschrift.HausNr : "";
        address.PLZ = anschrift.PLZ;
        address.Ort = anschrift.Ort;

        addresses.push(address);
      })
      return addresses;
    },

    getAllAddresses() {
      let addresses = [];
      let defaultHeimAddresse = this.getDefaultHeimAddress();
      if (defaultHeimAddresse) {
        addresses.push(defaultHeimAddresse)
      }
      let defaultWohnheinheitAddresse = this.getDefaultWohneinheitAddress(defaultHeimAddresse);
      if (defaultWohnheinheitAddresse) {
        addresses.push(defaultWohnheinheitAddresse)
      }
      let customAddresses = this.getCustomAddresses();
      if (customAddresses.length > 0) {
        addresses = addresses.concat(customAddresses);
      }
      return addresses;
    },

    addCustomAnschrift(event) {
      this.addAnschrift(event, this.formValues.bewohnerID).then(response => {
        this.selectAnschrift(response.anschrift);
      })
    },

    openEditDialog(slotProps, event) {
      event.preventDefault();
      event.stopPropagation();
      slotProps.open();
    },

    editAnschrift(anschrift) {
      this.updateAnschrift(anschrift, this.formValues.bewohnerID).then(response => {
        this.selectAnschrift(response.anschrift);
      })

    },

    deleteCustomAnschrift(id) {
      this.deleteAnschrift(id, this.formValues.bewohnerID).then(response => {
        if (this.formValues.anschrift == id) {
          this.selectAnschrift(null);
        }
      })
      return false;
    }
  }
}

</script>

<style scoped lang="scss">
* {
  font-family: "Avenir LT Std";
  font-size: 16px;
  color: #000000;
}

.deleteIcon {
  display: block;
  float: left;
}

.editIcon {
  display: block;
  float: right;
  padding-right: 5px;
}

strong {
  font-family: "Avenir LT Std Heavy";
  font-size: 16px;
  color: #6e6e6e;
}

.open-dialog-btn {
  text-decoration: underline;
  cursor: pointer;
  font-family: "Avenir LT Std Heavy";
  font-size: 16px;
}


.add-icon-container {
  cursor: pointer;
  margin-top: 20px;

  .v-btn {
    display: block;
    float: left;
  }

  .text {
    display: block;
    padding: 2px 0px;
    float: left;
    width: 150px;
    font-size: 14px;
    font-weight: bold;
    margin-left: 8px;
  }
}

</style>
