import httpClient from '../../utils/httpClient'

export const smartLogisticSettings = {
  namespaced: true,

  state: {
    model: 'A',
    allowImmediateDelivery: false,
    livingUnitSettings: [
      {'name': 'Wohnung 1'},
    ],

    blueprint:{
      model: [
        {
          label: 'A - ohne Logistik',
          value: 'a'
        },
        {
          label: 'B - Intralogistik',
          value: 'b'
        },
        {
          label: 'C - Externe Bestellung',
          value: 'c'
        }
      ],
    }
  },

  getters: {},

  actions: {
    async fetchLivingUnitsWithSettings ({ commit }) {
      const data = await httpClient({
        url: process.env.VUE_APP_API_LOGISTICS + 'living-unit-settings',
        method: 'get'
      })
      commit('setLivingUnitSettings', data)
    },
  },

  mutations: {
    setLivingUnitSettings (state, data) {
      state.blueprint.livingUnitSettings = data
    },
  },
  persist: false
}
