<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <template v-slot:activator="{}">
      <span @click="open" style="cursor: pointer">
        <slot name="button"/>
      </span>
    </template>

    <v-card v-if="bewohner">
      <v-card-title class="headline">
        {{ bewohner.vorname }} {{ bewohner.name }}
      </v-card-title>
      <v-card-text class="body-2">
        <v-row>
          <v-col>
            <slot/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-date-picker
                v-model="date"
                width="270"
                :max="new Date().toISOString().slice(0, 10)"
                first-day-of-week="1"
                locale="ch-de"
                :show-week="false"
                flat
                color="primary"
                :show-current="true"
            ></v-date-picker>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="date-select-actions">
        <v-btn icon  @click="reset">
          <v-hover v-slot="{ hover }" open-delay="0">
            <v-img v-if="hover" :src="require('@/assets/img/ico/loeschen-hover.svg')" alt="" max-width="30" class="mt-4 cursor-pointer"></v-img>
            <v-img v-else :src="require('@/assets/img/ico/loeschen.svg')" alt="" max-width="30" class="mt-4 cursor-pointer"></v-img>
          </v-hover>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="danger" depressed @click="close">Abbrechen</v-btn>
        <v-btn color="primary" depressed @click="save">Speichern</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {bewohnerActions, bewohnerMutations} from "../observables/bewohner";

export default {
  name: 'BewohnerDatepickerModal',
  props: {
    bewohner: {
      type: Object,
      required: true
    },
    dateField: {
      type: String,
      required: true
    },

    hideReset: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      dialog: false,
      date: null,
    }
  },

  methods: {
    ...bewohnerActions,

    open() {
      this.dialog = true
    },

    close() {
      this.dialog = false
    },

    save() {
      this.close()

      // set date
      this.bewohner[this.dateField] = this.date

      this.saveBewohner(this.bewohner)
    },

    reset() {
      this.date = null
    }
  },


  watch: {
    dialog() {
      if (this.dialog && this.bewohner[this.dateField]) {
        const date = this.$moment(this.bewohner[this.dateField])

        this.date = date.format('YYYY-MM-DD')
      } else {
        this.date = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.date-select-actions {
  .v-image {
    margin: 0px !important;
  }
}
</style>