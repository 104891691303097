<template>
    <v-dialog
            v-model="show"
            persistent
            width="1350"
            content-class="custom-dialog"
    >
        <template v-slot:activator="{ props }">
            <slot name="button" :open="open"></slot>
        </template>

        <v-card class="main">
            <header>
                <div class="title">
                    <h1>Arzt auswählen
                        <template v-if="bewohner.vorname || bewohner.name"> - <span class="primary--text">{{
                            bewohner.vorname + " " + bewohner.name
                            }}</span>
                        </template>
                    </h1>
                </div>
                <div class="close" @click="cancel()"><img :src="require('@/assets/img/ico/schliessen.svg')" width="25">
                </div>
            </header>
            <v-divider></v-divider>
            <div class="content">
                <v-row>
                    <v-col cols="3" class="mb-4">
                        <v-text-field
                                v-model="search"
                                label="Suche"
                                single-line
                                variant="underlined"
                                clearable
                                clear-icon="mdi-close"
                                hide-details
                        >
                            <template v-slot:append>
                                <img width="20" height="20" :src="require('@/assets/img/ico/suche.svg')" style="position:relative; top: 1px"
                                     class="cursor-pointer">
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="scroll-container">
                        <v-data-table :headers="headers"
                                      :items="aerzte"
                                      item-value="id"
                                      :search="search"
                                      :disable-pagination="true"
                                      hide-default-footer
                                      class="custom-data-table custom-selectable">

                            <template v-slot:item="{ item }">
                                <v-hover v-slot="{hover}">
                                    <tr class='table-row cursor-pointer'
                                        :class="{selected: selectedRow == item.ID, hover: hover}"
                                        @click="rowClick(item)">
                                        <td width="50px" class="v-data-table__td v-data-table-column--align-end">
                                            <img :src="selectedRow == item.ID ? require('@/assets/img/ico2023/kreis-ausgewaehlt.svg') : require('@/assets/img/ico2023/kreis-leer.svg')"
                                                class="select-icon"></img>
                                        </td>
                                        <td width="50px">
                                            <v-tooltip bottom v-if="item.default == 1">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon color="primary">mdi-account-star
                                                    </v-icon>
                                                </template>
                                                <span>Standard</span>
                                            </v-tooltip>
                                        </td>
                                        <td>{{ item.name }}</td>
                                        <td> {{
                                            item.strasse + " " + (item.hausnr ? item.hausnr : "")
                                            }}
                                        </td>
                                        <td>{{ item.plz }}</td>
                                        <td>{{ item.ort }}</td>
                                        <td>{{ item.zsr }}</td>
                                        <td>{{ item.gln }}</td>
                                    </tr>
                                </v-hover>

                            </template>
                            <template v-slot:no-data>
                                Es wurde kein Arzt gefunden.
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </div>
            <v-card-actions>
                <v-btn color="primary" elevation="0" variant="flat" rounded @click="close()">Auswahl übernehmen</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {aerzteGetters} from "@/components/observables/aerzte";

export default {
    name: 'ArztSelectDialog',
    emit: ['choose'],
    props: {
        bewohner: {
            required: false,
        },
        selected: {
            required: false
        }
    },
    data() {
        return {
            show: false,
            search: '',
            selectedRow: null,
            page: 1,
            headers: [
                {text: '', value: 'action', align: 'end', sortable: false},
                {text: '', value: 'default', align: 'start'},
                {text: 'Name', value: 'name', align: 'start'},
                {text: 'Strasse', value: 'strasse', align: 'start'},
                {text: 'PLZ', value: 'plz', align: 'start'},
                {text: 'Ort', value: 'ort', align: 'start'},
                {text: 'ZSR', value: 'zsr', align: 'start'},
                {text: 'GLN', value: 'gln', align: 'start'},
            ]
        }
    },
    computed: {
        ...aerzteGetters
    },
    methods: {
        open() {
            this.show = true

            if (this.selected) {
                this.selectedRow = this.selected
            } else {
                this.selectedRow = null;
            }
        },

        close() {
            if (this.selectedRow) {
                this.select(this.selectedRow)
            } else {
                this.select(null)
            }
        },

        cancel() {
            this.show = false
            this.$emit('cancel')
        },

        save() {
            this.show = false
        },

        select(id) {
            this.$emit('choose', id);
            this.show = false;
        },

        rowClick(item) {
            this.selectedRow = this.selectedRow == item.ID ? null : item.ID;
            if (this.selectedRow) {
                this.select(this.selectedRow)
            }
        }
    }
}
</script>

<style scoped lang="scss">
.main {
  padding: 40px 50px 23px 50px;
}

.title {
  h1 {
    margin: 0;
    margin-top: 0 !important;
    font-family: "Avenir LT Std";
    font-weight: 300 !important;
    font-size: 33px !important;
    line-height: 43px;
    margin-bottom: 30px;
  }
}

.close {
  position: absolute;
  top: 30px;
  right: 40px;
  cursor: pointer;
}

.v-alert {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.v-card-text {
  padding-left: 0 !important;
}

.hidden {
  opacity: 0;
  height: 0;
  overflow: hidden;
  padding: 0 !important;
  margin: 0 !important;
}

.stepper {
  float: left;
  width: 100%;
  padding-top: 20px;
  margin-bottom: 20px;
  font-family: "Avenir LT Std Heavy";
  font-size: 16px;
}

.previous-step-action, .next-step-action {
  font-family: "Avenir LT Std Heavy";
  font-size: 16px;
  cursor: pointer;

  img {
    top: 9px;
    position: relative;
  }
}

.previous-step-action {
  float: left;

  img {
    margin-right: 7px;
  }
}

.next-step-action {
  float: right;

  img {
    margin-left: 7px;
  }
}

.custom-tabs {
  margin: 0 -10px 20px;

  &::v-deep {
    .v-slide-group__next, .v-slide-group__prev {
      display: none !important;
    }
  }

  .v-tab {
    border: 1px solid black;
    border-radius: 10px !important;
    border-width: 0;
    margin-right: 10px;
    padding: 0 10px;
    font-size: 16px;
    font-family: "Avenir LT Std Heavy";
    height: 39px;

    &:last-of-type {
      margin-right: 0;
    }

    &.v-tab--selected {
      border-width: 1px;
    }

    &::v-deep {
      .v-btn__content {
        .v-tab__slider {
          opacity: 0 !important;
        }
      }
    }
  }
}

.content {
  margin-top: 30px;
  margin-bottom: 30px;
}


.v-btn--rounded {
  border-radius: 10px;
}

.deactiveIcon {
  display: block;
  float: left;
  flex: none;
  cursor: pointer;
}


tr {
  &.selected, &.hover {
    > td {
      background: #F4EDE7 !important;
      border-top-color: transparent !important;

      &:first-child {
        border-radius: 10px 0 0 10px !important;
        border-bottom-left-radius: 10px !important;
      }

      &:last-child {
        border-radius: 0 10px 10px 0 !important;
        border-bottom-right-radius: 10px !important;
      }
    }

    + tr > td {
      border-top-color: transparent !important;
    }
  }
}


.scroll-container {
    overflow: hidden;
    overflow-y: auto;
    max-height: 400px;
}


</style>
