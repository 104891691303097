<template>
    <div>
        <div class="file-hint">Dateiformat JPG, PNG oder EPS, maxmiale Grösse XY.</div>
        <label for="file-upload" class="custom-file-upload" ref="fileUpload">
            Dokument hierhin ziehen oder <span>hochladen</span>
        </label>
        <input
                id="file-upload"
                ref="uploader"
                type="file"
                accept="image/jpeg, image/png, .eps"
                @change="$emit('change', $event)">
    </div>
</template>

<script>
import {globalRules} from "@/composables/rules";
import ConfirmDialog from "@/components/shared/ConfirmDialog.vue";
import httpClient from "@/utils/httpClient";
import kunde from "@/components/observables/kunde";
import {mapGetters} from "vuex";

export default {
    name: 'LogoUpload',
    components: {},
    emits: ['change'],
    props: {
        logo: {
            required: false
        }
    },
    data() {
        return {}
    },
    mounted() {
        let fileUpload = this.$refs.fileUpload;
        let fileInput = this.$refs.uploader;

        fileUpload.addEventListener('dragover', (e) => {
            e.preventDefault();
            fileUpload.classList.add('dragging');
        });

        fileUpload.addEventListener('dragleave', () => {
            fileUpload.classList.remove('dragging');
        });

        fileUpload.addEventListener('drop', (e) => {
            e.preventDefault();
            fileUpload.classList.remove('dragging');
            const files = e.dataTransfer.files;
            // Setze das File-Objekt auf das 'files'-Attribut des Input-Elements
            fileInput.files = files

            // Optional: Auslösen des 'change'-Ereignisses, um den Event-Listener zu aktivieren
            fileInput.dispatchEvent(new Event('change'));
        })
    }
}
</script>

<style scoped lang="scss">

.file-hint {
    font-size: 18px;
    margin-bottom: 1rem;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    background: #F9F6F3 0% 0% no-repeat padding-box;
    border: 1px dashed #BAB6B6;
    border-radius: 10px;
    opacity: 1;
    width: 100%;
    padding: 40px;
    clear: both;
    display: block;
    text-align: center;
    font-style: italic;
    color: #BAB6B6;
    font-size: 18px;
    cursor: pointer;

    span {
        color: #CE20A8;
        text-decoration: underline;
    }

    &.dragging {
        border: 1px solid #BAB6B6;
    }
}
</style>
