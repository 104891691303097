<template>
<v-dialog
    v-model="dialog"
    persistent
    width="1100"
>
  <template v-slot:activator="{ props }">
    <slot name="button" :open="open"></slot>
  </template>

  <v-card v-if="dialog">
    <v-card-title class="mb-12">
        <h1>{{ title }}</h1>
    </v-card-title>

    <v-card-text class="text-center">
      <AssetPreview :asset="asset" v-if="isImage" />
      <AssetDownloader :asset="asset" v-else @done="close" />
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" @click="close">Schliessen</v-btn>
    </v-card-actions>
  </v-card>

</v-dialog>
</template>

<script>
import AssetPreview from '@/components/assets/AssetPreview.vue'
import AssetDownloader from '@/components/assets/AssetDownloader.vue'

export default {
  name: 'AssetPreviewDialog',
  components: { AssetDownloader, AssetPreview },
  props: {
    asset: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      dialog: false
    }
  },

  computed: {
    isImage () {
      return this.asset.mime_type.startsWith('image')
    }
  },

  methods: {
    /**
     *
     */
    open () {
      this.dialog = true
    },

    /**
     *
     */
    close () {
        this.dialog = false
    },
  }
}
</script>