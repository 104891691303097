<template>
    <div>
        <v-row>
            <v-col cols="10" class="pb-0">
                <p class="fs-18 mb-0 font-weight-bold">Jährlicher Pauschalbetrag Fixierhosen und Krankenunterlagen</p>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="5" class="py-0">
                <v-text-field label="Pro Jahr in CHF" variant="underlined"
                              v-model="formValues.fixierhose_betrag"></v-text-field>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'Fixierhose',
    props: {
        formValues: {
            required: true
        },
    }
}
</script>

<style scoped lang="scss">

</style>

