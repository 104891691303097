<template>
  <div style="position:relative" id="historymodal">
    <div class="op-hover" style="position:absolute;right: 10px; top: 10px;z-index:999">
      <v-icon x-large color="primary" @click="$emit('closeSalesHistoryModal')">
        mdi-close
      </v-icon>
    </div>
    <v-card flat class="px-4 pb-16">
      <v-card-title class="py-8 pb-0 d-block">
        <v-row>
          <v-col cols="2" class="my-0 py-0">
            <v-lazy>
                <span v-if="bilder[prod.artnr] ">
                  <v-img
                      :src="bilder[prod.artnr]['Z_URL']+'?width=80'"
                      height="60" max-width="90" contain></v-img>
                </span>
              <span v-else>
                <v-img :src="require('@/assets/img/ico/sortimentsverwaltung-aktiv.svg')" alt=""
                       width="35" height="60" contain></v-img>
              </span>
            </v-lazy>
          </v-col>
          <v-col cols="5" class="my-0 py-0 pt-1" style="overflow: hidden;max-height: 120px; min-height: 100px">
            <product-caption :artnr="prod.showID" :name="prod.name"  :verpackung="prod.verpackung"
                             :ownartno="prod.ownartno"></product-caption>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="body-1">
        <div class="text-h5 mb-4">
          Bestellhistorie
        </div>
        <table v-if="history.length > 0" cellpadding="0" cellspacing="0" border="0" id="history-table">
          <tr>
            <th style="padding-left: 10px;">Bestelldatum</th>
            <th>AuftragsNr</th>
            <th>Bestellart</th>
            <th>Bestellt durch</th>
            <th>bestellt</th>
            <th>geliefert</th>
          </tr>
          <tr v-for="(p,index) in history" :key="'h-'+index" :class="index%2==0 ? 'odd' : ''">

            <td style="padding-left: 10px;">
              {{ moment(p.Datum).format('DD.MM.Y') }}
            </td>
            <td>
              {{ p.DokumentNrAUF }}
            </td>
            <td>
              {{ p.BestellartBezeichnung }}
            </td>
            <td>
              {{ p.IhrZ }}
            </td>
            <td style="text-align: right;">
              {{ p.Menge }}
            </td>
            <td style="text-align: right;">
              {{ p.MengeGeliefert }}
            </td>
          </tr>
        </table>
        <div v-else>
          Das Produkt wurde seit dem {{ moment(startdate).format('DD.MM.Y') }} nicht bei uns bestellt.
        </div>

      </v-card-text>
      <v-card-actions style="position:absolute; bottom: 10px; right: 10px">
        <v-spacer></v-spacer>
        <v-btn depressed @click="$emit('closeSalesHistoryModal')">Fenster schliessen</v-btn>
      </v-card-actions>

    </v-card>
  </div>

</template>
<script>
import httpClient from "../../utils/httpClient";
import ProductCaption from "../products/ProductCaption";
import moment from 'moment'

export default {

  components: {ProductCaption},

  name: 'ProductSaleshistory',

  props: ['prod','startdate'],

  data() {
    return {
      moment: moment,
      history: []
    }
  },

  methods: {},

  computed: {

    bilder: {
      get() {
        return this.$store.state['bilder'].produktbilder
      }
    }
  },

  async created() {

    const res = await httpClient({
      url: process.env.VUE_APP_API_SHARED + 'get/saleshistorie',
      method: 'post',
      data: {artnr: this.prod.artnr}
    })

    if (res.type == 'success') {
      this.history = res.history;
    }
  }
}
</script>
<style lang="scss">
  #historymodal{

    .odd{
      background-color: #fdf9f7;
    }

    #history-table{
      td,th{
        padding: 3px 15px 3px 0;
        text-align: left;
      }

      td{
        padding-bottom: 10px;
        padding-top: 10px;
      }

      th{
        font-weight: normal !important;
        font-size: 13px;
      }
    }
  }


</style>