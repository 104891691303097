import {wohneinheitenMutations} from "../../components/observables/wohneinheiten";
import httpClient from "@/utils/httpClient";

export const benutzer = {
    namespaced: true,
    state: {
        id: false,
        authToken: false,
        info: {
            name: '',
            vorname: '',
            email: '',
            AdressNrADR: '',
            anschrift: null,
            erpSyncActive: false,
        },
        settings: {
            erp: 'none',
            syncBewohner: false,
            syncLeistungen: false,
            inkosync: 0,
        }
    },
    getters: {
        settings: (state) => {
            return state.settings
        },
        info: (state) => {
            return state.info
        },
        isErpSyncActive: (state) => {
            return state.info.erpSyncActiv != undefined && state.info.erpSyncActiv != 0 && state.info.AdressNrADR != 102228 ? true : false;
        },

        isBewohnerSyncActive: (state) => {
            return state.settings.syncBewohner != undefined && state.settings.syncBewohner != 0 ? true : false;
        }
    },

    actions: {
        setID({commit}, value) {
            commit("setID", value);
        },
        setAuthToken({commit}, value) {
            commit("setAuthToken", value);
        },
        setInfo({commit}, value) {
            commit("setInfo", value);
        },

        /**
         * load settings
         * @param commit
         */
        loadSettings({commit}) {
            commit('system/addToLoadingQueue', 'loadSettings', {root: true})

            httpClient({
                url: process.env.VUE_APP_API_SHARED + 'settings',
                method: 'get',
            }).then(function (response) {
                commit('setSettings', response.data)
                commit('system/removeFromLoadingQueue', 'loadSettings', {root: true})
            })
        },

        /**
         * set erp data
         * @param commit
         * @param erp
         */
        setErp({commit}, erp) {
            commit('system/addToLoadingQueue', 'setErp', {root: true})

            httpClient({
                url: process.env.VUE_APP_API_SHARED + 'settings/erp',
                method: 'post',
                data: {erp: erp}
            }).then(function (response) {
                commit('system/removeFromLoadingQueue', 'setErp', {root: true})

                commit('setSettings', response.data)
            })
        },

        setZuschlag({commit}, zuschlag) {
            httpClient({
                url: process.env.VUE_APP_API_SHARED + 'settings/zuschlag',
                method: 'post',
                data: {zuschlag: zuschlag ? zuschlag : 0}
            }).then(function (response) {

                commit('setSettings', response.data)
            })
        },

        setLeistungsnummerNebenleistungen({commit}, leistungsnummerNebenleistungen) {
            httpClient({
                url: process.env.VUE_APP_API_SHARED + 'settings/leistungsnummer-nebenleistungen',
                method: 'post',
                data: {leistungsnummerNebenleistungen: leistungsnummerNebenleistungen ? leistungsnummerNebenleistungen : ""}
            }).then(function (response) {

                commit('setSettings', response.data)
            })
        },

        setMinKrankenkasse({commit}, minKrankenkasse) {
            httpClient({
                url: process.env.VUE_APP_API_SHARED + 'settings/min-krankenkasse',
                method: 'post',
                data: {minKrankenkasse: minKrankenkasse ? minKrankenkasse : 0}
            }).then(function (response) {

                commit('setSettings', response.data)
            })
        },

        setIsVerrechenbarBewohner({commit}, isVerrechenbarBewohner) {
            httpClient({
                url: process.env.VUE_APP_API_SHARED + 'settings/is-verrechenbar-bewohner',
                method: 'post',
                data: {isVerrechenbarBewohner: isVerrechenbarBewohner}
            }).then(function (response) {

                commit('setSettings', response.data)
            })
        },

        setIsPurasDirectLive({commit}, isPurasDirectLive) {
            httpClient({
                url: process.env.VUE_APP_API_SHARED + 'settings/is-puras-direct-live',
                method: 'post',
                data: {isPurasDirectLive: isPurasDirectLive}
            }).then(function (response) {

                commit('setSettings', response.data)
            })
        },

        setSyncBewohner({commit}, syncBewohner) {
            httpClient({
                url: process.env.VUE_APP_API_SHARED + 'settings/syncBewohner',
                method: 'post',
                data: {syncBewohner: syncBewohner}
            }).then(function (response) {

                commit('setSettings', response.data)
            })
        },

        setNotificationEmails({commit}, notificationEmails) {
            httpClient({
                url: process.env.VUE_APP_API_SHARED + 'settings/notification-emails',
                method: 'post',
                data: {notificationEmails: notificationEmails}
            }).then(function (response) {

                commit('setSettings', response.data)
            })
        },



        setSyncLeistungen({commit}, syncLeistungen) {
            httpClient({
                url: process.env.VUE_APP_API_SHARED + 'settings/syncLeistungen',
                method: 'post',
                data: {syncLeistungen: syncLeistungen}
            }).then(function (response) {

                commit('setSettings', response.data)
            })
        },

        setInkosync({commit}, inkosync) {
            httpClient({
                url: process.env.VUE_APP_API_SHARED + 'settings/inkosync',
                method: 'post',
                data: {inkosync: inkosync}
            }).then(function (response) {
            })
        },
    },

    mutations: {
        setID(state, val) {
            state.id = val;
        },
        setAuthToken(state, val) {
            state.authToken = val;
        },
        setInfo(state, val) {
            state.info = val;
        },

        /**
         * set settings
         * @param state
         * @param settings
         */
        setSettings(state, settings) {
            state.settings = settings
        }
    }
};
