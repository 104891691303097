export const loader = {
  namespaced: true,
  state: {
    loading: false,
    msg: '',
    showmsg: false,
    msgtype: 'error'
  },
  getters: {
    loading: (state) => state.loading
  },
  actions: {
    show({ commit }) {
      commit("show");
    },
    setmsg({ commit },msg) {

      commit("setmsg",msg);
    },
    hide({ commit }) {
      commit("hide");
    },
    setmsgvisible({commit},val){
      commit("setmsgvisible",val)
    },
    setmsgtype({commit},val){
      commit("setmsgtype",val)
    },
  },
  mutations: {
    show(state) {
      state.loading = true;
    },
    hide(state) {
      state.loading = false;
    },
    setmsg(state,msg){
      state.msg = msg
    },
    setmsgvisible(state,val){
      state.showmsg = val;
    },
    setmsgtype(state,val){
      state.msgtype = val;
    }
  }
};