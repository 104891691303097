<template>
    <div v-if="headers">
        <v-data-table  :headers="headers"
                      :items="therapies"
                      hide-default-footer
                      item-value="id" class="pb-8 custom-data-table custom-selectable">
            <template v-slot:bottom>

            </template>
            <template v-slot:item.start="{item}">
                <div class="therapie-line-item" v-for="therapie in item.therapien">
                    {{ therapie.start_show }}
                </div>
                <div class="therapie-line-item empty">–</div>
            </template>
            <template v-slot:item.end="{item}">
                <template v-for="therapie in item.therapien">
                    <div class="therapie-line-item" v-if="therapie.end_show">
                        {{ therapie.end_show }}
                    </div>
                    <div class="therapie-line-item empty" v-if="!therapie.end_show">–</div>
                </template>

                <div class="therapie-line-item empty">–</div>
            </template>
            <template v-slot:item.actions="{ item }">
                <div class="therapie-line-item" v-for="therapie in item.therapien">
                    <ConfirmDialog title="Therapie löschen" @confirm="del(therapie.id)">
                        <template #button>
                            <v-hover v-slot="{ hover }">
                                    <v-img :src="hover ? require('@/assets/img/ico/loeschen-hover.svg') : require('@/assets/img/ico/loeschen.svg')"
                                           width="28" class="icon"></v-img>
                            </v-hover>
                        </template>
                        Möchten Sie diese Therapie wirklich löschen?
                    </ConfirmDialog>
                </div>
                <div class="therapie-line-item">
                    <DateRangePicker v-if="!item.boolean"
                       :start-date="getStartDate(item.category_id)"
                       :min-date="getMinDate(item.category_id)"
                       tooltip="Therapie hinzufügen"
                       @update:modelValue="add(item.category_id, $event.start_date, $event.end_date)"></DateRangePicker>
                    <YearDateRangePicker v-if="item.boolean"
                       :start-date="getStartDate(item.category_id, true)"
                       :min-date="getMinDate(item.category_id, true)"
                       :year-picker="true"
                       tooltip="Therapie hinzufügen"
                       @update:modelValue="add(item.category_id, $event.start_date, $event.end_date)"></YearDateRangePicker>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {migelPauschalKategorienGetters} from "@/components/observables/migelPauschalKategorien";
import {bewohnerTherapienActions, bewohnerTherapienGetters} from "@/components/observables/bewohner-therapien";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import DateRangePicker from "@/components/form/DateRangePicker";
import YearDateRangePicker from "@/components/form/YearDateRangePicker";
import moment from "moment";

export default {
    name: 'Therapien',
    components: {ConfirmDialog, DateRangePicker, YearDateRangePicker},
    props: {
        formValues: {
            required: true
        },
    },
    data() {
        return {
            headers: [
                {text: 'Therapien', value: 'therapie', align: 'start', sortable: false},
                {text: 'von', value: 'start', align: 'start', sortable: false},
                {text: 'bis', value: 'end', align: 'start',sortable: false},
                {text: '', value: 'actions', align: 'end',sortable: false},
            ]
        }
    },
    methods: {
        ...bewohnerTherapienActions,
        del(therapieId) {
            this.deleteTherapie(therapieId)
        },

        add(migelPauschalKategorieId, start_date, end_date = false) {
            this.addTherapie({
                migel_pauschal_kategorie_id: migelPauschalKategorieId,
                start: moment(start_date).format("YYYY-MM-DD"),
                end: end_date ? moment(end_date).format("YYYY-MM-DD") : null,
            }, this.formValues.bewohnerID)
        },

        getMinDate(categoryId, yearly = false) {
            let unit = yearly ? 'Year' : 'Month';
            let format = yearly ? 'YYYY' : 'MMM YYYY'
            let therapie = this.therapies.find(therapie => therapie.category_id == categoryId);
            if (therapie && therapie.therapien.length > 0) {
                if (therapie.therapien[therapie.therapien.length - 1].end) {
                    return moment(therapie.therapien[therapie.therapien.length - 1].end, format).startOf(unit).add('1', unit);
                } else {
                    return moment(therapie.therapien[therapie.therapien.length - 1].start, format).startOf(unit).add('1', unit);
                }
            }
            return null;
        },

        getStartDate(categoryId, yearly = false) {
            let unit = yearly ? 'Year' : 'Month';
            if (this.getMinDate(categoryId, yearly)) {
                return this.getMinDate(categoryId, yearly)
            }
            return moment().startOf(unit);
        },

        getTherapiesByBewohnerIdAndMigelKategorie(bewohnerId, categorieId) {
            return !this.bewohnerTherapies ? [] : this.bewohnerTherapies.filter(t => t.bewohner_id === +bewohnerId && t.migel_pauschal_kategorie_id === +categorieId)
        },

    },
    computed: {
        ...migelPauschalKategorienGetters,
        ...bewohnerTherapienGetters,
        therapies: {
            get() {
                return this.migelPauschalKategorien.filter(therapie => {
                  return therapie.KategorieID_Pauschale !== 'INKO'
                }).map(category => {
                    let format = category.MiGeL_Einheit === 'pro Kalenderjahr' ? 'YYYY' : 'MMM YYYY'
                    return {
                        category_id: category.id,
                        therapie: category.Kategoriebezeichnung,
                        x: this.getTherapiesByBewohnerIdAndMigelKategorie(this.formValues.bewohnerID, category.id),
                        therapien: this.getTherapiesByBewohnerIdAndMigelKategorie(this.formValues.bewohnerID, category.id).map(therapie => {
                            return {
                                id: therapie.id,
                                start_show: moment(therapie.start).locale('de').format(format),
                                start: moment(therapie.start).format(format),
                                end_show: therapie.end ? moment(therapie.end).locale('de').format(format) : null,
                                end: therapie.end ? moment(therapie.end).format(format) : null
                            }
                        }),
                        boolean: category.MiGeL_Einheit === 'pro Kalenderjahr'
                    }
                })
            }
        }
    }
}
</script>

<style scoped lang="scss">
.therapie-line-item {
  padding: 7px 0;

  &.empty {
    color: #ACACAC;
  }
    ::v-deep {
        > div:not(.v-dialog__container), > span {
            display: inline-block;
        }
    }
}

.icon {
  cursor: pointer;
}

::v-deep {
  .v-data-table__wrapper td {
    font-size: 16px !important;
      line-height: 26px;
  }
}
</style>
