<template>
  <div>
    <img :src="base64Image" v-if="base64Image" />
    <v-progress-circular
        v-else
        color="primary"
        indeterminate
    />
  </div>
</template>

<script>
import Loader from '@/components/layout/Loader.vue'
import httpClient from '@/utils/httpClient'

export default {
  name: 'AssetPreview',
  components: { Loader },
  props: {
    asset: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      base64Image: null
    }
  },

  methods: {
    loadAsset () {
      httpClient.get(`${process.env.VUE_APP_API_SHARED}asset/${this.asset.id}`)
          .then(response => {
            this.base64Image = response
          })
    }
  },

  created () {
    this.loadAsset()
  }
}
</script>

<style lang="scss" scoped>
img {
  max-width: 100%;
  max-height: 500px;
}
</style>