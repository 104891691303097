<template>
  <div v-if="carts.length > 0" class="mb-8">
    <div class="mb-4 px-4 d-flex rounded-lg greenlight" v-if="!isAnyFilterActive">
      <h2 class="font-weight-medium pt-3 pb-2">
        {{ wohneinheitenName }}
      </h2>
    </div>

    <div>
      <div class="d-flex header align-end b-bottom" style="gap: 20px; height: 55px;"  v-if="!isAnyFilterActive || index === 0">
        <div style="flex-shrink: 0; flex-basis: 50px; align-items: center;">
          <v-checkbox
              v-if="!isAnyFilterActive"
              v-model="allSelected"
              @click="toggleWhCards"
              hide-details
              class="pt-0 mt-0 pb-2"
          />
        </div>
        <div
            v-for="(col,index) in dataCols"
            :key="'header_index_'+index"
            style="flex-shrink: 0; align-items: center;"
            :style="{ 'flex-basis': col.width, 'text-align': col.text, 'padding-bottom':'6px' }"
        >
          <b>{{ col.title }}</b>
        </div>
      </div>

      <OverviewDataRow
          v-for="cart in filteredCarts"
          :key="cart.bewohner_id"
          :cart="cart"
          :showType="showType"
      />
    </div>
  </div>
</template>

<script>
import {rowConfig} from "@/config/DirektabrechnungOverviewConfig";
import OverviewDataRow from "@/components/direktabrechnung/OverviewDataRow.vue";
import {activeCards} from "@/composables/direktabrechnung";
import {mapGetters} from "vuex";

export default {
  name: 'OverviewWohneinheitContainer',
  components: {OverviewDataRow},

  data() {
    return {
      allSelected: true
    }
  },

  props: {
    wohneinheitId: {
      type: Number,
      required: true
    },
    showType: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      isSearchActive: 'direktabrechnung/isSearchActive',
      searchTerm: 'direktabrechnung/searchTerm',
      filters: 'direktabrechnung/filters'
    }),

    isAnyFilterActive() {
      return this.isSearchActive || this.filters.errorsOnly || !!this.filters.status
    },

    dataCols() {
      return rowConfig[this.showType]
    },

    selectedBewohner() {
      return this.$store.state['direktabrechnung'].selectedBewohner
    },

    carts() {
      return this.$store.state.direktabrechnung.carts.filter(c => c.bewohner.wohneinheitenID === this.wohneinheitId)
    },

    filteredCarts()  {
      var carts = this.carts

      if (this.isSearchActive) {
        carts = carts.filter(c => c.bewohner.name.toLowerCase().includes(this.searchTerm) || c.bewohner.vorname.toLowerCase().includes(this.searchTerm) || c.bewohner.zimmer.toLowerCase().includes(this.searchTerm))
      }

      if(this.filters.errorsOnly) {
        carts = carts.filter(c => c.validation.length>0)
      }

      if(this.filters.status) {
        carts = carts.filter(c => c.status === this.filters.status)
      }

      return carts.sort((a, b) => a.bewohner.zimmer.localeCompare(b.bewohner.zimmer, 'de', { numeric: true }) || a.bewohner.name.localeCompare(b.bewohner.name, 'de') || a.bewohner.vorname.localeCompare(b.bewohner.vorname, 'de'))
    },

    activeCarts()  {
      return activeCards(this.carts, this.showType)
    },

    activeBewohnerIds() {
      return [...new Set(this.activeCarts.map(item => item.bewohner_id))]
    },

    wohneinheitenName() {
      if (!this.carts || this.carts.length <= 0) {
        return ''
      }

      return this.carts[0].bewohner.wohneinheitenName
    },
  },

  methods: {
    toggleWhCards() {
      if(this.allSelected) {
        this.$store.state['direktabrechnung'].selectedBewohner = _.union(this.selectedBewohner, this.activeBewohnerIds)
      } else {
        this.$store.state['direktabrechnung'].selectedBewohner = this.selectedBewohner.filter(x => !this.activeBewohnerIds.includes(x))
      }
    }
  },

  watch: {
    selectedBewohner() {
      this.allSelected = !!(this.selectedBewohner.filter(x => this.activeBewohnerIds.includes(x)).length === this.activeBewohnerIds.length)
    }
  }
}
</script>